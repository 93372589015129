import React, { useState, useEffect } from 'react';
import NavBar from '../../../NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMapMarkerAlt, faBook, faTag, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../../../../index.css';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import ProfileJoyrideTour from '../../.././Profile/ProfileJoyrideTour';
import { BsThreeDots } from 'react-icons/bs';
import { Card, Button } from 'flowbite-react';
import { useAuth } from '../../../../contexts/AuthContext';

const AdStudentProfile = ({ student }) => {
    const [currentUser, setCurrentUser] = useState(student);
    const [schedule, setSchedule] = useState([]);
    const [totCoursesCount, setTotCoursesCount] = useState(0);
    const [planCoursesCount, setPlanCoursesCount] = useState(0);
    const [photoURL, setPhotoURL] = useState([]);
    const [loading, setLoading] = useState(true);
    const { fetchedCourseData, fetchedMajors, fetchedSurveyData, currentUser: loggedInUser } = useAuth();
    const [takenCoursesCount, setTakenCoursesCount] = useState(0);
    const [milestoneData, setMilestoneData] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [completedMilestones, setCompletedMilestones] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        setCurrentUser(student);
        console.log('Triggered', currentUser, student);
    }, [student]);

    const yearMapping = {
        "First Year": "year1",
        "Second Year": "year2",
        "Third Year": "year3",
        "Fourth Year": "year4",
        "Fifth Year": "year5"
    };

    const getStudentInfo = async () => {
        try {
            if (currentUser) {
                const userDocRef = doc(db, 'users', currentUser.id);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const primaryTab = userData?.primaryTab;
                    const finalizedSchedule = userData?.schedules[primaryTab];
                    const completedSemesters = userData.completedSemesters || {};
                    const photoUrl = userData.photoUrl;
                    setPhotoURL(photoUrl);

                    let courseArray = [];
                    for (let i = 1; i <= 4; i++) {
                        let year = `year${i}`;
                        for (let j = 1; j <= 2; j++) {
                            let semester = `S${j}`;
                            if (finalizedSchedule && finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                                courseArray.push(...finalizedSchedule[year][semester]['courses']);
                            }
                        }
                    }

                    const seeYr = userData.surveyAnswers?.Year || 'defaultYear';
                    let courseArray1 = [];
                    for (let j = 1; j <= 2; j++) {
                        let semester = `S${j}`;
                        if (finalizedSchedule && finalizedSchedule[yearMapping[seeYr]] && finalizedSchedule[yearMapping[seeYr]][semester]) {
                            courseArray1.push(...finalizedSchedule[yearMapping[seeYr]][semester]['courses']);
                        }
                    }

                    const studentCourses = fetchedCourseData ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber)) : [];
                    const major = userData.surveyAnswers?.Majors[0] || 'N/A';

                    const majorCourses = Object.values(fetchedMajors[major]);

                    const totalCourses = majorCourses ? majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone').length : 0;

                    // Calculate taken courses
                    const takenCoursesSet = new Set();
                    Object.keys(completedSemesters).forEach(year => {
                        Object.keys(completedSemesters[year]).forEach(semesterKey => {
                            if (completedSemesters[year][semesterKey]) {
                                const completedCourses = finalizedSchedule[year][semesterKey]?.courses || [];
                                completedCourses.forEach(course => {
                                    takenCoursesSet.add(course);
                                });
                            }
                        });
                    });
                    const takenCoursesArray = Array.from(takenCoursesSet);

                    const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));

                    function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
                        courses.sort((a, b) => {
                            if (a.courseInfo_courseNumber < b.courseInfo_courseNumber) return -1;
                            if (a.courseInfo_courseNumber > b.courseInfo_courseNumber) return 1;
                            return 0;
                        });
                        const usedCoursesForRequirements = [];
                        let plannedRequirementCoursesArray = [];
                        let largestValidArray = [];

                        function backtrack(requirementIndex) {
                            if (requirementIndex === majorCourses.length) {
                                if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...plannedRequirementCoursesArray];
                                }
                                return;
                            }

                            const majorCourse = majorCourses[requirementIndex];

                            if ((majorCourse.courseType === 'Major Course' && majorCourse.Requirements) || (majorCourse.courseType === 'Requirement')) {
                                const possibleCourses = majorCourse.Possibilities.split(',').map(course => course.trim());

                                let foundValidCourse = false;

                                for (let i = 0; i < possibleCourses.length; i++) {
                                    const courseNumber = possibleCourses[i];

                                    const matchingCourseIndex = courses.findIndex(course =>
                                        course.courseInfo_course_number === courseNumber &&
                                        !nonUsableCourses.includes(courseNumber) &&
                                        !usedCoursesForRequirements.includes(courseNumber)
                                    );

                                    if (matchingCourseIndex !== -1) {
                                        plannedRequirementCoursesArray.push(courseNumber);
                                        usedCoursesForRequirements.push(courseNumber);
                                        foundValidCourse = true;

                                        if (backtrack(requirementIndex + 1)) {
                                            return;
                                        }

                                        plannedRequirementCoursesArray.pop();
                                        usedCoursesForRequirements.pop();
                                    }
                                }

                                if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...plannedRequirementCoursesArray];
                                }

                                if (!foundValidCourse) {
                                    backtrack(requirementIndex + 1);
                                }
                            } else {
                                backtrack(requirementIndex + 1);
                            }
                        }

                        backtrack(0);
                        return largestValidArray;
                    }

                    function findElectiveCourses(majorCourses, courses) {
                        courses.sort((a, b) => {
                            if (a.courseInfo_course_number < b.courseInfo_course_number) return -1;
                            if (a.courseInfo_course_number > b.courseInfo_course_number) return 1;
                            return 0;
                        });
                        const usedCoursesForElectives = [];
                        let takenElectiveCoursesArray = [];
                        let largestValidArray = [];

                        function backtrack(electiveIndex) {
                            if (electiveIndex === majorCourses.length) {
                                if (takenElectiveCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...takenElectiveCoursesArray];
                                }
                                return;
                            }

                            const majorCourse = majorCourses[electiveIndex];

                            if (majorCourse.courseType === 'Elective') {
                                const electiveName = majorCourse.Possibilities;

                                let foundValidCourse = false;

                                for (let i = 0; i < courses.length; i++) {
                                    const studentCourse = courses[i];

                                    if (
                                        studentCourse.Elective_fulfillment.includes(electiveName) &&
                                        !usedCoursesForElectives.includes(studentCourse.courseInfo_course_number)
                                    ) {
                                        takenElectiveCoursesArray.push(studentCourse.courseInfo_course_number);
                                        usedCoursesForElectives.push(studentCourse.courseInfo_course_number);
                                        foundValidCourse = true;
                                        break;
                                    }
                                }

                                if (takenElectiveCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...takenElectiveCoursesArray];
                                }

                                backtrack(electiveIndex + 1);
                            } else {
                                backtrack(electiveIndex + 1);
                            }
                        }

                        backtrack(0);
                        return largestValidArray;
                    }

                    const takenMajorCoursesArray = takenCourses.filter(course => {
                        const isInMajorCourses = majorCourses.some(majorCourse => majorCourse.courseInfo_course_number === course.courseInfo_course_number);
                        return isInMajorCourses;
                    });

                    const takenElectiveCoursesArray = findElectiveCourses(
                        majorCourses.filter(course => course.courseType === 'Elective'),
                        takenCourses
                    );

                    const takenRequirementCoursesArray = findRequirementCourses(
                        majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || (course.courseType === 'Requirement')),
                        takenCourses,
                        takenElectiveCoursesArray
                    );

                    const allMajorCoursesArray = studentCourses.filter(course => {
                        const isInMajorCourses = majorCourses.some(majorCourse => majorCourse.courseInfo_course_number === course.courseInfo_course_number);
                        return isInMajorCourses;
                    });

                    const allElectiveCoursesArray = findElectiveCourses(
                        majorCourses.filter(course => course.courseType === 'Elective'),
                        studentCourses
                    );

                    const allRequirementCoursesArray = findRequirementCourses(
                        majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || (course.courseType === 'Requirement')),
                        studentCourses,
                        allElectiveCoursesArray
                    );

                    const takenCoursesLengths = takenMajorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length;

                    const allCoursesLengths = allMajorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths;

                    setTakenCoursesCount(takenCoursesLengths);

                    setPlanCoursesCount(allCoursesLengths);

                    setTotCoursesCount(totalCourses);

                    await updateDoc(userDocRef, { majorMakeup: { [major]: allMajorCoursesArray.length } });

                    const year = userData.surveyAnswers?.Year || 'N/A';
                    await fetchScheduleData(year, userData.schedules[primaryTab]);

                    const currentYearCourses = studentCourses.filter(course => courseArray1.includes(course.courseInfo_course_number));
                    const totalCredits = currentYearCourses.reduce((acc, course) => {
                        const credits = parseInt(course.Credits, 10);
                        return acc + (isNaN(credits) ? 0 : credits);
                    }, 0);

                    setTotalCredits(totalCredits);
                    console.log("Student info fetched successfully");
                }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchScheduleData = (year, finalizedSchedule) => {
        if (year && finalizedSchedule) {
            const mappedYear = yearMapping[year];
            if (mappedYear) {
                const fallCourses = [];
                const springCourses = [];
                if (finalizedSchedule[mappedYear]?.S1) {
                    fallCourses.push(...finalizedSchedule[mappedYear].S1.courses);
                }
                if (finalizedSchedule[mappedYear]?.S2) {
                    springCourses.push(...finalizedSchedule[mappedYear].S2.courses);
                }
                setSchedule({
                    Fall: fallCourses,
                    Spring: springCourses,
                });
            }
        }
    };

    useEffect(() => {
        const fetchMilestoneData = async () => {
            if (currentUser) {
                const userDocRef = doc(db, 'users', currentUser.id);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setMilestoneData(data);

                    if (data.finalizedMilestones) {
                        const primaryTab = data.primaryTab || 'defaultTab';
                        const milestonesInprimaryTab = data.finalizedMilestones[primaryTab] || {};

                        const milestoneList = Object.values(milestonesInprimaryTab).reduce((acc, yearData) => {
                            Object.values(yearData).forEach(semData => {
                                if (semData.milestones) {
                                    acc = acc.concat(semData.milestones);
                                }
                            });
                            return acc;
                        }, []);

                        setMilestones(milestoneList);
                        const completed = milestoneList.filter(milestone => milestone.completed).length;
                        setCompletedMilestones(completed);
                    }
                }
            }
        };
        fetchMilestoneData();
    }, [currentUser]);

    useEffect(() => {
        if (currentUser && fetchedCourseData) {
            getStudentInfo();
        }
    }, [currentUser, fetchedCourseData, fetchedSurveyData]);

    const handleNameClick = () => {
        setClickCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 4) {
                navigate('/roles');
            }
            return newCount;
        });
    };

    if (loading) {
        return (
            <div role="status">
                <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.0830 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                    />
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }

    const totalMilestones = milestones.length;
    const milestoneProgress = totalMilestones > 0 ? Math.round((completedMilestones / totalMilestones) * 100) : 0;
    const progressTaken = totCoursesCount > 0 ? Math.round((planCoursesCount / totCoursesCount) * 100) : 0;
    const progressDone = totCoursesCount > 0 ? Math.round((takenCoursesCount / totCoursesCount) * 100) : 0;
    const remainingCourses = totCoursesCount - takenCoursesCount - planCoursesCount;

    const userFullName = () => {
        if (currentUser && currentUser.firstName && currentUser.lastName) {
            return `${currentUser.firstName} ${currentUser.lastName}`;
        }
        return 'User';
    };

    const TagList = ({ tags }) => {
        const [showAll, setShowAll] = useState(false);

        if (!Array.isArray(tags) || tags.length === 0) {
            return (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                    No Tags Selected
                </span>
            );
        }

        const handleToggle = () => setShowAll(!showAll);

        return (
            <div className="relative">
                <div
                    className={`flex flex-wrap items-center ${showAll ? 'max-h-none' : 'max-h-12 overflow-hidden'} transition-all`}
                >
                    {tags.map((tag, index) => (
                        <div
                            key={index}
                            className={`flex items-center mr-2 mb-1 ${!showAll && index >= 3 ? 'hidden' : ''}`}
                            style={{ lineHeight: '1.2' }}
                        >
                            <FontAwesomeIcon
                                icon={faTag}
                                className="mr-1"
                                style={{ verticalAlign: 'middle', lineHeight: 'inherit' }}
                            />
                            <span className="text-sm leading-none">{tag}</span>
                        </div>
                    ))}
                    {showAll && (
                        <button
                            onClick={handleToggle}
                            className="rounded bg-gray-100 px-1 py-1 flex items-center justify-center hover:text-blue-500 hover:underline"
                            aria-label="Hide"
                        >
                            <FontAwesomeIcon icon={faEyeSlash} size="sm" />
                        </button>
                    )}
                </div>
                {tags.length > 3 && !showAll && (
                    <button
                        onClick={handleToggle}
                        className="absolute top-0 rounded right-0 bg-gray-100 px-1 py-.5 flex items-center justify-center hover:text-blue-500 hover:underline"
                        aria-label="Toggle"
                        style={{ right: '-18px' }}
                    >
                        <BsThreeDots size={15} />
                    </button>
                )}
            </div>
        );
    };

    return (
        <div>
            <ProfileJoyrideTour/>
            <Card className="w-full max-w-6xl px-4 bg-white-100 shadow-md relative mt-20">
                <div className="flex justify-between">
                    <div className="flex items-center mb-1">
                        <img className="w-[160px] h-[160px] mb-4 mr-5 rounded-full" src={photoURL || '/default.jpg'} alt="Profile Picture" />
                        <div className="flex-grow max-w-[calc(100%-100px)]">
                            <h1 className="text-2xl font-bold mb-1" onClick={handleNameClick}>{userFullName()}</h1>
                            {currentUser?.email && <p className="text-sm mb-1">{currentUser.email}</p>}
                            {(currentUser?.surveyAnswers?.Year || 'N/A') && (currentUser?.surveyAnswers?.Faculties?.length > 0 || 'N/A') && (
                                <p className="text-sm mb-1">
                                    {currentUser?.surveyAnswers?.Year || 'N/A'} - {currentUser?.surveyAnswers?.Faculties?.join(', ') || 'N/A'}
                                </p>
                            )}
                            {currentUser?.surveyAnswers?.Majors?.length > 0 && (
                                <p className="text-sm">
                                    <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                                    {currentUser?.surveyAnswers?.Majors?.length === 1 ? 'Major in' : 'Majors in'} {currentUser?.surveyAnswers?.Majors?.join(', ')}
                                </p>
                            )}
                            {currentUser?.surveyAnswers?.Minors?.length > 0 && (
                                <p className="text-sm mb-1">
                                    <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                                    {currentUser.surveyAnswers.Minors.length === 1 ? 'Minor in' : 'Minors in'} {currentUser.surveyAnswers.Minors.join(', ')}
                                </p>
                            )}
                            {currentUser?.surveyAnswers?.Campus && (
                                <p className="text-sm mb-1">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} /> {currentUser.surveyAnswers.Campus}
                                </p>
                            )}
                            {(() => {
                                const startMonth = currentUser?.surveyAnswers?.SchoolStart?.month;
                                const startYear = currentUser?.surveyAnswers?.SchoolStart?.year;
                                const endMonth = currentUser?.surveyAnswers?.SchoolEnd?.month;
                                const endYear = currentUser?.surveyAnswers?.SchoolEnd?.year;

                                const startDate = startMonth && startYear ? `${startMonth} ${startYear}` : 'N/A';
                                const endDate = endMonth && endYear ? `${endMonth} ${endYear}` : 'N/A';

                                if (startDate !== 'N/A' || endDate !== 'N/A') {
                                    return (
                                    <p className="text-sm mb-1">
                                        <FontAwesomeIcon icon={faGraduationCap} /> {startDate} - {endDate}
                                    </p>
                                    );
                                }

                                return null;
                            })()}
                            {(currentUser?.surveyAnswers?.Pronouns || (Array.isArray(currentUser?.surveyAnswers?.Tags) && currentUser.surveyAnswers.Tags.length > 0)) && (
                                <div className="flex items-center text-sm mb-1">
                                    <FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                                    {currentUser?.surveyAnswers?.Pronouns && (
                                        <span className="mr-2 mb-1">Pronouns: {currentUser.surveyAnswers.Pronouns}</span>
                                    )}
                                    <TagList tags={currentUser?.surveyAnswers?.Tags} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="text-right">
                        <p className="text-sm text-gray-700">Advisor:</p>
                        <p className="text-lg font-semibold">{loggedInUser?.firstName} {loggedInUser?.lastName}</p>
                    </div>
                </div>
                <div className="flex justify-between mt-2">
                    <div className="mb-1">
                        <h4 className="mb-2">Progress Towards {currentUser.major[0]} Pathway</h4>
                        <div className="relative w-full max-w-[25vw] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div
                                className="absolute bg-green-500 h-2.5"
                                style={{
                                    width: `${progressDone}%`,
                                    left: '0',
                                    top: 0,
                                    borderTopLeftRadius: '9999px',
                                    borderBottomLeftRadius: '9999px',
                                    borderTopRightRadius: progressTaken > 0 ? '0' : '9999px',
                                    borderBottomRightRadius: progressTaken > 0 ? '0' : '9999px'
                                }}
                            ></div>
                            <div
                                className="absolute bg-yellow-300 h-2.5"
                                style={{
                                    width: `${progressTaken}%`,
                                    left: `${progressDone}%`,
                                    top: 0,
                                    borderTopRightRadius: '9999px',
                                    borderBottomRightRadius: '9999px',
                                    borderTopLeftRadius: progressDone > 0 ? '0' : '9999px',
                                    borderBottomLeftRadius: progressDone > 0 ? '0' : '9999px'
                                }}
                            ></div>
                        </div>
                        <div className="flex justify-between mt-2 space-x-10 w-full max-w-[25vw] items-center">
                            <div className="rounded bg-green-100 px-2 py-0.3">
                                <span className="text-green-600 font-semibold">{takenCoursesCount} Taken</span>
                            </div>
                            <div className="rounded bg-yellow-100 px-2 py-0.3 ml-3">
                                <span className="text-yellow-500 font-semibold">{planCoursesCount || 0} Planned</span>
                            </div>
                            <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                                <span className="text-gray-600 font-semibold">{remainingCourses || 0} Unplanned</span>
                            </div>
                        </div>

                    </div>
                    <div className="milestone-progress">
                        <h4 className="mb-2">Milestone Progress</h4>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${milestoneProgress || 0}%` }}></div>
                        </div>
                        <div className="flex justify-between mt-2">
                            <div className="rounded bg-blue-100 px-2 py-0.3">
                                <span className="text-blue-600 font-semibold">{completedMilestones || 0} Completed</span>
                            </div>
                            <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                                <span className="text-gray-500 font-semibold">{(totalMilestones || 0) - (completedMilestones || 0)} Remaining</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Added back the cards for Grades, Interests, and Schedule */}
                <div className="grid grid-cols-3 gap-4 mt-2">
                    <div className="bg-gray-100 p-3 rounded-lg shadow-sm">
                        <h2 className="text-gray-500 font-semibold mb-2">Resources</h2>
                        {/* Add your resources content here */}
                        <p>Here are some helpful resources for you.</p>
                    </div>
                    <div className="bg-gray-100 p-3 rounded-lg shadow-sm">
                        <h2 className="text-gray-500 font-semibold mb-2">Grades</h2>
                        <p className="text-gray-700 font-semibold" style={{ overflowWrap: 'anywhere' }}>
                            Current GPA: {currentUser?.surveyAnswers?.GPA || 'N/A'}
                        </p>
                    </div>
                    <div className="bg-gray-100 p-3 rounded-lg shadow-sm h-auto max-h-full">
                        <h2 className="text-gray-500 font-semibold mb-2">Interests</h2>
                        <div className="overflow-y-auto max-h-60">
                            <div className="flex flex-wrap">
                                {Array.isArray(currentUser?.surveyAnswers?.RecInterests) && currentUser.surveyAnswers.RecInterests.length > 0 ? (
                                    currentUser.surveyAnswers.RecInterests.map((interest, index) => (
                                        <span key={index} className="bg-gray-200 text-gray-700 rounded-lg px-1.5 py-0.5 mr-2 mb-2">{interest}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">No Interests</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Student's Schedule */}
                <div className="bg-gray-100 p-3 mt-3 rounded-lg shadow-sm">
                    <h2 className="text-gray-500 font-semibold">
                        Current Schedule ({currentUser?.surveyAnswers?.Year || 'N/A'}) - Total Credits: {totalCredits}
                    </h2>
                    {Object.keys(schedule).length > 0 ? (
                        <div className="flex justify-between">
                            <div className="w-1/2 pr-1" style={{ maxWidth: 'calc(50%)' }}>
                                <h3 className="text-lg font-semibold mt-2">Fall Semester</h3>
                                <ul>
                                    {schedule.Fall.length > 0 ? (
                                        schedule.Fall.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-gray-200 p-1.5 mb-2 rounded-lg inline-block mr-2"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Fall Semester
                                        </span>
                                    )}
                                </ul>
                            </div>

                            <div className="w-1/2 pl-1" style={{ maxWidth: 'calc(50%)' }}>
                                <h3 className="text-lg font-semibold mt-2">Spring Semester</h3>
                                <ul>
                                    {schedule.Spring.length > 0 ? (
                                        schedule.Spring.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-gray-200 p-1.5 mb-2 rounded-lg inline-block mr-2"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Spring Semester
                                        </span>
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                            No Planned Courses for Current Year
                        </span>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default AdStudentProfile;
