import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  HiChartPie,
  HiMenu,
  HiX
} from 'react-icons/hi';
import { PiStudent } from 'react-icons/pi';
import { BsMap, BsGraphUp, BsPencilSquare, BsPersonCircle, BsSignpost2 } from 'react-icons/bs';
import { FaUserGroup } from 'react-icons/fa6';
import ProfileDrawer from './ProfileDrawer';

export default function CustomSidebar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDrawerOpen);
  };

  return (
    <>
      {/* Sidebar Toggle Button (Visible on small screens) */}
      <button
        onClick={toggleSidebar}
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="logo-sidebar"
        aria-expanded={isSidebarOpen}
      >
        <span className="sr-only">Open sidebar</span>
        {isSidebarOpen ? (
          <HiX className="w-6 h-6" aria-hidden="true" />
        ) : (
          <HiMenu className="w-6 h-6" aria-hidden="true" />
        )}
      </button>

      {/* Sidebar */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-50 w-64 h-screen transition-transform bg-gray-50 dark:bg-gray-800 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto">
          {/* Logo */}
          <Link to="/Profile" className="flex items-center ps-2 mb-3">
            <img
              src={`${process.env.PUBLIC_URL}/edviseicon.png`}
              className="h-12 me-2 sm:h-14"
              alt="Edvise Logo"
            />
            <span className="self-center text-2xl sm:text-3xl font-semibold whitespace-nowrap dark:text-white">
              Edvise
            </span>
          </Link>

          {/* Navigation Items */}
          <ul className="space-y-2 font-small">
            <li>
              <Link
                to="/dashboard"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <HiChartPie className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to="/students"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <PiStudent className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Students</span>
              </Link>
            </li>
            <li>
              <Link
                to="/add-pathways"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <BsMap className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Pathways</span>
              </Link>
            </li>
            <li>
              <Link
                to="/courses"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <BsPencilSquare className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Courses</span>
              </Link>
            </li>
            <li>
              <Link
                to="/analytics"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <BsGraphUp className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Analytics</span>
              </Link>
            </li>
            <li>
              <Link
                to="/resources"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <BsSignpost2 className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Resources</span>
              </Link>
            </li>
            <li>
              <Link
                to="/staff"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <FaUserGroup className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Staff</span>
              </Link>
            </li>
            <li>
              <button
                onClick={toggleProfileDrawer}
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group w-full"
              >
                <BsPersonCircle className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                <span className="ms-3">Profile</span>
              </button>
            </li>
            {/* Add more navigation items here as needed */}
          </ul>
        </div>
      </aside>

      {/* Overlay for Sidebar on Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black opacity-50 sm:hidden"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}
      {/* Profile Drawer */}
      <ProfileDrawer isOpen={isProfileDrawerOpen} onClose={toggleProfileDrawer} />
    </>
  );
}