import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import NavBar from '../NavBar';
import CollapsibleClassSearch from './CollapsibleClassSearch';
import { useNavigate, useLocation } from 'react-router-dom';
import { useClassContext } from '../ClassContext';
import ClassCart from './ClassCart';
import { db } from '../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import MultiSelect from './MultiSelect';

const ClassSearchOverlay = () => {
  const { fetchedCourseData, fetchedMajorRecs } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const yearNumber = parseInt(searchParams.get('yearNumber'));
  const semester = searchParams.get('semester');

  const { currentUser } = useAuth();
  const history = useNavigate();
  const sentinelRef = useRef(null);

  const { selectedClasses, setSelectedClasses } = useClassContext();
  const [classesInCart, setClassesInCart] = useState([]);

  const [displayedData, setDisplayedData] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [displayedCourseCount, setDisplayedCourseCount] = useState(0);

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Multi-select filter states
  const [levelFilter, setLevelFilter] = useState([]);
  const [majorFilter, setMajorFilter] = useState([]);
  const [creditsFilter, setCreditsFilter] = useState([]);

  const [userRecsData, setUserRecsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [finalizedCourseIds, setFinalizedCourseIds] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [showRecommendedCourses, setShowRecommendedCourses] = useState(false);

  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);

  // Debounce delay in milliseconds
  const DEBOUNCE_DELAY = 1;

  // Transform fetchedMajorRecs
  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedData);
    }
  }, [fetchedMajorRecs]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = currentUser;

        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || '';
            const courseIds = userData.schedules[activeTab] || [];
            const backendSurveyAnswers = userData.surveyAnswers || [];
            const backendUserRecData = userData.recommendationRatings || {};
            setFinalizedCourseIds(courseIds);
            setSurveyAnswers(backendSurveyAnswers);
            setUserRecsData(backendUserRecData);

            const allCourseIds = [];

            for (const yearKey in courseIds) {
              const yearObj = courseIds[yearKey];

              // Iterate over each semester in the year
              for (const semesterKey in yearObj) {
                const semesterIds = yearObj[semesterKey].courses;

                // Append semesterIds to allCourseIds
                allCourseIds.push(...semesterIds);
              }
            }
            const uniqueCourseIds = [...new Set(allCourseIds)];

            setSelectedClasses(uniqueCourseIds);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser, setSelectedClasses]);

  // Extract unique filter options using useMemo for performance
  const uniqueLevels = useMemo(() => {
    if (!fetchedCourseData) return [];
    const levels = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.courseYear) levels.add(item.courseYear);
    });
    return Array.from(levels).sort((a, b) => parseInt(a) - parseInt(b));
  }, [fetchedCourseData]);

  const uniqueMajors = useMemo(() => {
    if (!fetchedCourseData) return [];
    const majors = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Major) {
        item.Major.split(',').forEach((major) => {
          majors.add(major.trim());
        });
      }
    });
    return Array.from(majors).sort();
  }, [fetchedCourseData]);

  const uniqueCredits = useMemo(() => {
    if (!fetchedCourseData) return [];
    const credits = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Credits) credits.add(item.Credits);
    });
    return Array.from(credits).sort((a, b) => parseFloat(a) - parseFloat(b));
  }, [fetchedCourseData]);

  // Define suggestions for MultiSelect components
  const levelSuggestions = useMemo(
    () => uniqueLevels.map((level) => `${level}`),
    [uniqueLevels]
  );

  const majorSuggestions = useMemo(
    () => uniqueMajors,
    [uniqueMajors]
  );

  const creditsSuggestions = useMemo(
    () => uniqueCredits.map((credit) => `${credit} Credit(s)`),
    [uniqueCredits]
  );

  // Debounced filter function
  const debouncedFilter = useCallback(
    debounce(() => {
      filterDataAndSetDisplay();
    }, DEBOUNCE_DELAY),
    [
      fetchedCourseData,
      selectedClasses,
      searchQuery,
      levelFilter,
      majorFilter,
      creditsFilter,
      showRecommendedCourses,
      surveyAnswers,
      userRecsData,
      transformedMajorRecData,
    ]
  );

  // Filter data whenever dependencies change, using debounce
  useEffect(() => {
    debouncedFilter();

    // Cleanup debounce on unmount
    return debouncedFilter.cancel;
  }, [
    fetchedCourseData,
    selectedClasses,
    searchQuery,
    levelFilter,
    majorFilter,
    creditsFilter,
    showRecommendedCourses,
    surveyAnswers,
    userRecsData,
    transformedMajorRecData,
    debouncedFilter,
  ]);

  // Filtering logic
  const filterDataAndSetDisplay = () => {
    if (fetchedCourseData) {
      const filtered = Object.values(fetchedCourseData).filter((item) => {
        const majors = item.Major.split(',').map((major) => major.trim());

        // Check if class is already selected
        if (
          selectedClasses.some(
            (selected) =>
              selected.courseInfo_courseName === item.courseInfo_courseName
          )
        ) {
          return false;
        }

        // Level Filter
        if (
          levelFilter.length > 0 &&
          !levelFilter.some(
            (filter) => parseInt(filter) === parseInt(item.courseYear)
          )
        ) {
          return false;
        }

        // Major Filter
        if (
          majorFilter.length > 0 &&
          !majorFilter.some((filter) => majors.includes(filter))
        ) {
          return false;
        }

        // Credits Filter
        if (
          creditsFilter.length > 0 &&
          !creditsFilter.some(
            (filter) =>
              parseFloat(filter.replace(' Credit(s)', '')) === parseFloat(item.Credits)
          )
        ) {
          return false;
        }

        // Search Query
        const nameMatch = item.courseInfo_courseName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const numberMatch = item.courseInfo_courseNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        return nameMatch || numberMatch;
      });

      let sortedData;
      if (showRecommendedCourses) {
        const calculateRecScore = require('../CourseRecommendation');
        sortedData = filtered
          .map((course) => {
            const recScore = calculateRecScore(
              transformedMajorRecData[course.Major],
              userRecsData,
              course,
              surveyAnswers
            );
            return { ...course, recScore };
          })
          .sort((a, b) => b.recScore - a.recScore);
      } else {
        sortedData = filtered.sort((a, b) =>
          a.courseInfo_courseNumber.localeCompare(b.courseInfo_courseNumber)
        );
      }

      setFilteredData(sortedData);
      setRecommendedCourses(sortedData);
      setDisplayedData(sortedData.slice(0, 100));
      setCurrentBatch(1);
      setDisplayedCourseCount(sortedData.length);
    }
  };

  // Infinite scroll: Load more data when sentinel is intersecting
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          displayedData.length < filteredData.length
        ) {
          loadMoreData();
        }
      },
      {
        threshold: 0,
        root: sentinelRef.current
          ? sentinelRef.current.parentElement
          : null, // Ensure the root is the scrollable container
        rootMargin: '100px',
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [displayedData, filteredData]);

  const loadMoreData = () => {
    const nextBatch = currentBatch + 1;
    const newData = filteredData.slice(0, nextBatch * 100);
    setDisplayedData(newData);
    setCurrentBatch(nextBatch);
  };

  // Handle resetting all filters
  const handleResetFilters = () => {
    setLevelFilter([]);
    setMajorFilter([]);
    setCreditsFilter([]);
    setShowRecommendedCourses(false);
    setSearchQuery('');
  };

  // Toggle recommendations
  const handleShowRecommendations = () => {
    setShowRecommendedCourses((prev) => !prev);
  };

  // Handle adding a class to the cart
  const handleAddClass = (classData) => {
    const isClassAlreadyAdded = selectedClasses.some(selected => selected.courseInfo_courseNumber === classData.courseInfo_courseNumber) || classesInCart.some(cartItem => cartItem.courseInfo_courseNumber === classData.courseInfo_courseNumber);

    if (!isClassAlreadyAdded) {
      setClassesInCart(prevClasses => [...prevClasses, classData]);
    } else {
      toast.error(`Class with course number ${classData.courseInfo_courseNumber} is already in your cart or schedule.`);
    }
  };

  // Handle removing a class from the cart
  const handleRemoveClass = (classToRemove) => {
    const updatedClassesInCart = classesInCart.filter(
      (classData) => classData !== classToRemove
    );
    setClassesInCart(updatedClassesInCart);
  };

  // Helper function to capitalize year keys (e.g., 'year1' -> 'Year 1')
  const capitalize = (str) => {
    // Insert a space before the first digit
    const spacedStr = str.replace(/(\D+)(\d+)/, '$1 $2');
    // Capitalize the first letter
    return spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);
  };

  // Helper function to convert semester codes to readable format
  const getReadableSemester = (sem) => (sem === "S1" ? "Semester 1" : "Semester 2");

  // Helper function to find the earliest incomplete semester
  const findEarliestIncompleteSemester = (updateObj, completedSemesters, numYears, courseNumber) => {
    for (let year = 1; year <= numYears; year++) {
      const yearKey = `year${year}`;
      for (const sem of ["S1", "S2"]) {
        // Initialize year and semester if not present
        if (!updateObj[yearKey]) {
          updateObj[yearKey] = {};
        }
        if (!updateObj[yearKey][sem]) {
          updateObj[yearKey][sem] = { courses: [] };
        }

        // Check if the semester is completed
        if (completedSemesters[yearKey] && completedSemesters[yearKey][sem]) {
          continue; // Skip completed semesters
        }

        // Check if the course is already in this semester
        if (!updateObj[yearKey][sem].courses.includes(courseNumber)) {
          return { targetYear: yearKey, targetSemester: sem };
        }
      }
    }
    // If all semesters are completed or full, return null
    return null;
  };

  // Helper function to find the latest incomplete semester
  const findLatestIncompleteSemester = (updateObj, completedSemesters, numYears, courseNumber) => {
    for (let year = numYears; year >= 1; year--) {
      const yearKey = `year${year}`;
      for (const sem of ["S2", "S1"]) { // Start from Semester 2
        // Initialize year and semester if not present
        if (!updateObj[yearKey]) {
          updateObj[yearKey] = {};
        }
        if (!updateObj[yearKey][sem]) {
          updateObj[yearKey][sem] = { courses: [] };
        }

        // Check if the semester is completed
        if (completedSemesters[yearKey] && completedSemesters[yearKey][sem]) {
          continue; // Skip completed semesters
        }

        // Check if the course is already in this semester
        if (!updateObj[yearKey][sem].courses.includes(courseNumber)) {
          return { targetYear: yearKey, targetSemester: sem };
        }
      }
    }
    // If all semesters are completed or full, return null
    return null;
  };

  // Helper function to find the semester where the course is already present
  const findCourseSemester = (updateObj, numYears, courseNumber) => {
    for (let year = 1; year <= numYears; year++) {
      const yearKey = `year${year}`;
      for (const sem of ["S1", "S2"]) {
        if (
          updateObj[yearKey] &&
          updateObj[yearKey][sem] &&
          updateObj[yearKey][sem].courses.includes(courseNumber)
        ) {
          return { yearKey, sem };
        }
      }
    }
    return null;
  };

  // Helper function to find the next available semester that doesn't include the course
  const findNextAvailableSemester = (updateObj, completedSemesters, numYears, courseNumber) => {
    for (let year = 1; year <= numYears; year++) {
      const yearKey = `year${year}`;
      for (const sem of ["S1", "S2"]) { // Traverse semesters in order
        // Initialize year and semester if not present
        if (!updateObj[yearKey]) {
          updateObj[yearKey] = {};
        }
        if (!updateObj[yearKey][sem]) {
          updateObj[yearKey][sem] = { courses: [] };
        }

        // Skip completed semesters
        if (completedSemesters[yearKey] && completedSemesters[yearKey][sem]) {
          continue;
        }

        // Check if the semester already has the course
        if (!updateObj[yearKey][sem].courses.includes(courseNumber)) {
          return { targetYear: yearKey, targetSemester: sem };
        }
      }
    }
    // If no available semester is found, return null
    return null;
  };

  const handleAddCourses = async () => {
    const userId = currentUser.uid;

    try {
      const userDocRef = db.collection('users').doc(userId);
      const userDoc = await userDocRef.get();
      const userData = userDoc.data();
      const activeTab = userData.activeTab;
      const previousFinalizedSchedule = userData.schedules[activeTab] || {};
      const completedSemesters = userData.completedSemesters || {};
      const numYears = previousFinalizedSchedule?.numYears || 4; // Default to 4 years if no finalizedSchedule
      const lastYear = `year${numYears}`;
      const lastSemester = "S2"; // Spring semester

      const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};

      for (const selectedClass of classesInCart) {
        const courseNumber = selectedClass.courseInfo_courseNumber;
        let courseSemester = selectedClass.CourseSem;
        let courseYear = selectedClass.courseYear;

        // Handle courses without a target semester
        if (!courseSemester) {
          // Assume default semester if not provided, e.g., "S1"
          courseSemester = "S1"; // or derive based on your logic
        }

        // Determine the target year and semester
        let targetYear = `year${courseYear}`;
        let targetSemester = courseSemester;

        // **Check if the target year exists in the schedule**
        if (!updateObj[targetYear]) {
          // Redirect to the latest semester
          targetYear = lastYear;
          targetSemester = lastSemester;

          // Initialize latest year and semester if not present
          if (!updateObj[targetYear]) {
            updateObj[targetYear] = {};
          }
          if (!updateObj[targetYear][targetSemester]) {
            updateObj[targetYear][targetSemester] = { courses: [] };
          }

          // Display error toast notification for invalid year
          toast.error(
            `Year ${courseYear} is not part of your current plan. "${courseNumber}" has been added to the latest semester (${getReadableSemester(targetSemester)} of ${capitalize(targetYear)}).`,
            { position: "top-right", autoClose: 5000 }
          );
        }

        // Initialize year and semester if not present
        if (!updateObj[targetYear][targetSemester]) {
          updateObj[targetYear][targetSemester] = { courses: [] };
        }

        // **Scenario 1:** If the target semester is marked as complete
        if (completedSemesters[targetYear] && completedSemesters[targetYear][targetSemester]) {
          const earliestSemester = findEarliestIncompleteSemester(updateObj, completedSemesters, numYears, courseNumber);
          if (earliestSemester) {
            targetYear = earliestSemester.targetYear;
            targetSemester = earliestSemester.targetSemester;

            // Display info toast notification for completed semester
            toast.info(
              `${getReadableSemester(courseSemester)} of ${capitalize(targetYear)} is marked as complete. "${courseNumber}" has been added to ${getReadableSemester(targetSemester)} of ${capitalize(targetYear)}.`,
              { position: "top-right", autoClose: 5000 }
            );
          } else {
            // No available semesters to add the course
            toast.error(`No available semesters to add "${courseNumber}". Please review your schedule.`, { position: "top-right", autoClose: 5000 });
            continue; // Skip adding this course
          }
        }
        // **Scenario 2:** If the course is already in the target semester
        else if (updateObj[targetYear][targetSemester].courses.includes(courseNumber)) {
          // **Find where the course is currently placed**
          const existingSemester = findCourseSemester(updateObj, numYears, courseNumber);
          if (existingSemester) {
            // **Find the next available semester that doesn't have the course**
            const nextAvailableSemester = findNextAvailableSemester(updateObj, completedSemesters, numYears, courseNumber);
            if (nextAvailableSemester) {
              targetYear = nextAvailableSemester.targetYear;
              targetSemester = nextAvailableSemester.targetSemester;

              // Display info toast notification for duplicate course in semester
              toast.info(
                `The course "${courseNumber}" is already in ${getReadableSemester(existingSemester.sem)} of ${capitalize(existingSemester.yearKey)}. It has been moved to ${getReadableSemester(targetSemester)} of ${capitalize(targetYear)}.`,
                { position: "top-right", autoClose: 5000 }
              );
            } else {
              // No available semesters to add the course
              toast.error(`No available semesters to add "${courseNumber}". Please review your schedule.`, { position: "top-right", autoClose: 5000 });
              continue; // Skip adding this course
            }
          } else {
            // Course is marked as present in the target semester but not found elsewhere
            // This should not happen, but handle gracefully
            toast.error(`The course "${courseNumber}" is marked as present in ${getReadableSemester(targetSemester)} of ${capitalize(targetYear)}, but its current location couldn't be determined. Please review your schedule.`, { position: "top-right", autoClose: 5000 });
            continue; // Skip adding this course
          }
        }

        // Ensure that the target semester is not completed
        if (completedSemesters[targetYear] && completedSemesters[targetYear][targetSemester]) {
          toast.error(`Cannot add "${courseNumber}" to a completed semester (${getReadableSemester(targetSemester)} of ${capitalize(targetYear)}).`, { position: "top-right", autoClose: 5000 });
          continue; // Skip adding this course
        }

        // Add the course to the determined semester
        if (!updateObj[targetYear][targetSemester].courses) {
          updateObj[targetYear][targetSemester].courses = [];
        }
        if (!updateObj[targetYear][targetSemester].courses.includes(courseNumber)) {
          updateObj[targetYear][targetSemester].courses.push(courseNumber);
          console.log(`Course ${courseNumber} added to ${targetYear} ${targetSemester} successfully.`);
        } else {
          console.log(`Course ${courseNumber} is already present in ${targetYear} ${targetSemester}.`);
        }
      }

      await userDocRef.update({ [`schedules.${activeTab}`]: updateObj });

      setClassesInCart([]);
      history('/Plan'); // Navigate immediately; ToastContainer is global
    } catch (error) {
      console.error('Error adding courses to finalizedSchedule:', error);
      toast.error('An error occurred while adding courses. Please try again.', { position: "top-right", autoClose: 5000 });
    }
  };

  // Display loading spinner while fetching data
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  // Toggle info tooltip
  const toggleInfo = () => {
    setShowInfo((prev) => !prev);
  };

  return (
    <div>
      <NavBar />
      <div className="mt-20 grid grid-cols-10 gap-3">
        {/* Filters Sidebar */}
        <div className="col-span-2">
          <div className="p-4 bg-gray-100 rounded-lg">
            <h5 className="text-lg text-center font-bold mb-4">Filters</h5>

            {/* Search Filter */}
            <div className="mb-2">
              <label
                htmlFor="searchQuery"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Search:
              </label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>

            {/* Level Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Level:
              </label>
              <MultiSelect
                suggestions={levelSuggestions}
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
                placeholder="Select Level(s)"
                required={false}
                unselectable={[]} // Add any unselectable items if needed
              />
            </div>

            {/* Major Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Major:
              </label>
              <MultiSelect
                suggestions={majorSuggestions}
                value={majorFilter}
                onChange={(e) => setMajorFilter(e.target.value)}
                placeholder="Select Major(s)"
                required={false}
                unselectable={[]} // Add any unselectable items if needed
              />
            </div>

            {/* Credits Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Credits:
              </label>
              <MultiSelect
                suggestions={creditsSuggestions}
                value={creditsFilter}
                onChange={(e) => setCreditsFilter(e.target.value)}
                placeholder="Select Credit(s)"
                required={false}
                unselectable={[]} // Add any unselectable items if needed
              />
            </div>

            {/* Selected Filters Display */}
            {(levelFilter.length > 0 ||
              majorFilter.length > 0 ||
              creditsFilter.length > 0) && (
              <div className="mb-4">
                <h6 className="text-sm font-medium text-gray-700 mb-1">
                  Selected Filters:
                </h6>
                <div className="flex flex-wrap gap-1">
                  {/* Level Filters */}
                  {levelFilter.map((filter, index) => (
                    <span
                      key={`level-${index}`}
                      id={`badge-dismiss-level-${index}`}
                      className="inline-flex items-center px-2 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300"
                    >
                      {filter}
                      <button
                        type="button"
                        className="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
                        onClick={() => {
                          setLevelFilter(levelFilter.filter((_, i) => i !== index));
                        }}
                        aria-label={`Remove Level ${filter}`}
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Remove badge</span>
                      </button>
                    </span>
                  ))}

                  {/* Major Filters */}
                  {majorFilter.map((filter, index) => (
                    <span
                      key={`major-${index}`}
                      id={`badge-dismiss-major-${index}`}
                      className="inline-flex items-center px-2 py-1 text-sm font-medium text-green-800 bg-green-100 rounded dark:bg-green-900 dark:text-green-300"
                    >
                      {filter}
                      <button
                        type="button"
                        className="inline-flex items-center p-1 ms-2 text-sm text-green-400 bg-transparent rounded-sm hover:bg-green-200 hover:text-green-900 dark:hover:bg-green-800 dark:hover:text-green-300"
                        onClick={() => {
                          setMajorFilter(majorFilter.filter((_, i) => i !== index));
                        }}
                        aria-label={`Remove Major ${filter}`}
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Remove badge</span>
                      </button>
                    </span>
                  ))}

                  {/* Credits Filters */}
                  {creditsFilter.map((filter, index) => (
                    <span
                      key={`credits-${index}`}
                      id={`badge-dismiss-credits-${index}`}
                      className="inline-flex items-center px-2 py-1 text-sm font-medium text-red-800 bg-red-100 rounded dark:bg-red-900 dark:text-red-300"
                    >
                      {filter}
                      <button
                        type="button"
                        className="inline-flex items-center p-1 ms-2 text-sm text-red-400 bg-transparent rounded-sm hover:bg-red-200 hover:text-red-900 dark:hover:bg-red-800 dark:hover:text-red-300"
                        onClick={() => {
                          setCreditsFilter(creditsFilter.filter((_, i) => i !== index));
                        }}
                        aria-label={`Remove Credits ${filter}`}
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Remove badge</span>
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            )}

            {/* Reset and Show Recommendations Buttons */}
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mb-2"
              onClick={handleResetFilters}
            >
              Reset Filters
            </button>
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded"
              onClick={handleShowRecommendations}
            >
              {showRecommendedCourses
                ? 'Hide Recommendations'
                : 'Show Recommendations'}
            </button>

            {/* Courses Display Count */}
            <div className="flex justify-center mt-4">
              <span className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                {displayedCourseCount} Courses Displayed
              </span>
            </div>
          </div>
        </div>

        {/* Courses Display */}
        <div className="col-span-6">
          <div className="p-4 bg-gray-100 rounded-lg flex flex-col h-full">
            {/* Header with Centered Title and Info Icon */}
            <div className="flex items-center justify-center mb-4 relative">
              <h5 className="text-lg font-bold text-center">
                {showRecommendedCourses
                  ? 'Recommended Courses'
                  : 'Courses'}
              </h5>
              {showRecommendedCourses && (
                <button
                  className="absolute right-0 p-1 rounded hover:bg-gray-200"
                  onClick={toggleInfo}
                  aria-label="Show Recommendation Information"
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              )}
            </div>

            {/* Info Tooltip */}
            {showInfo && (
              <div
                id="alert-additional-content-1"
                className="fixed top-4 right-4 z-50 p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
                role="alert"
                style={{ width: '25rem' }}
              >
                <div className="flex items-center mb-2">
                  <svg
                    className="flex-shrink-0 w-4 h-4 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="sr-only">Info</span>
                  <h3 className="text-lg font-medium">
                    Recommendation Information
                  </h3>
                </div>
                <div className="mt-2 mb-4 text-sm">
                  Recommendation scores are determined by two factors:
                  <br />
                  1. The alignment of course content with your interests,
                  skills, and personality traits, as indicated by Holland Codes.
                  <br />
                  2. The compatibility of course characteristics (e.g.,
                  project-based versus test-based) reported by past students
                  with your preferences from the survey.
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="text-blue-800 bg-transparent border border-blue-800 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center"
                    onClick={toggleInfo}
                    aria-label="Dismiss Recommendation Information"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            )}

            {/* Courses List with Infinite Scroll */}
            <div className="flex-1 overflow-y-auto" style={{ minHeight: 'calc(100vh - 13rem)', maxHeight: 'calc(100vh - 13rem)' }}>
              {showRecommendedCourses ? (
                <div>
                  {recommendedCourses
                    .slice(0, currentBatch * 100)
                    .map((classData, index) => (
                      <CollapsibleClassSearch
                        key={index}
                        classData={classData}
                        onAddClass={handleAddClass}
                        inPlan={selectedClasses.includes(
                          classData.courseInfo_courseNumber
                        )}
                        isRec={true}
                      />
                    ))}
                </div>
              ) : (
                <div>
                  {displayedData.map((classData, index) => (
                    <CollapsibleClassSearch
                      key={index}
                      classData={classData}
                      onAddClass={handleAddClass}
                      inPlan={selectedClasses.includes(
                        classData.courseInfo_courseNumber
                      )}
                      isRec={false}
                    />
                  ))}
                </div>
              )}
              <div ref={sentinelRef}></div>
            </div>
          </div>
        </div>

        {/* Class Cart */}
        <div className="col-span-2">
          <ClassCart
            classesInCart={classesInCart}
            onRemoveClass={handleRemoveClass}
            onAddCourses={handleAddCourses}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassSearchOverlay;
