// UsersList.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Import Firestore reference
import { TextInput, Badge } from 'flowbite-react'; // Import necessary Flowbite components

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterActive, setFilterActive] = useState(false); // Track if filter is active

  // Fetch users with admin privileges from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await db.collection('users').where('role', 'in', ['admin', 'super_admin']).get();
        const usersData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        setFilteredUsers(usersData); // Initialize filtered users
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Apply search filter when clicking the filter button
  const applyFilter = () => {
    if (searchTerm === '') {
      setFilteredUsers(users); // Reset to all users if search term is empty
      setFilterActive(false);
    } else {
      const filtered = users.filter(user =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
      setFilterActive(true);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      {/* Search and Filter Controls */}
      <div className="mb-4 flex justify-between items-center">
        <TextInput
          id="search"
          type="search"
          placeholder="Search by name, username, group..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-1/3"
        />
        {/* Filter Button with Updated Styling */}
        <button
          type="button"
          onClick={applyFilter}
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
        >
          {filterActive ? 'Reset Filter' : 'Apply Filter'}
        </button>
      </div>

      {/* User List Display */}
      <div className="bg-white rounded-lg shadow-md p-4">
        <h3 className="text-lg font-medium mb-4">{filteredUsers.length} Users</h3>
        <ul>
          {filteredUsers.map(user => (
            <li key={user.id} className="flex justify-between items-center py-2 border-b">
              <div className="flex items-center">
                <span className="bg-gray-300 rounded-full w-10 h-10 flex items-center justify-center mr-3 text-white">
                  {user.firstName.charAt(0)}
                  {user.lastName.charAt(0)}
                </span>
                <div>
                  <p className="font-medium">{user.firstName} {user.lastName}</p>
                  <p className="text-sm text-gray-500">{user.email}</p>
                </div>
              </div>
              <Badge color="green">Active</Badge>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UsersList;
