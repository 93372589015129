import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { getDatabase, ref, set } from 'firebase/database';
import PathwayClassSearchOverlay from './PathwayClassSearch/PathwayClassSearchOverlay';
import { useAuth } from '../../../contexts/AuthContext';
import CoursesSection from './PathwayFormContent/CoursesSection';
import ElectivesSection from './PathwayFormContent/ElectivesSection';
import MilestonesSection from './PathwayFormContent/MilestonesSection';
import RequirementsSection from './PathwayFormContent/RequirementsSection';
import ReviewSection from './PathwayFormContent/ReviewSection';

// Material-UI Imports
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

// Optional: Customize MUI Stepper styles
const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: 'transparent',
    padding: '0',
    marginBottom: '24px',
  },
  stepLabel: {
    cursor: 'pointer',
  },
}));

const encodePathwayName = (name) => {
  return name
    .replace(/\./g, '%2E')
    .replace(/#/g, '%23')
    .replace(/\$/g, '%24')
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D');
};

const PathwayFormModal = ({ onSave, selectedCourses, handleClose }) => {
  const classes = useStyles();
  const { currentUser, fetchedCourseData, faculties, majors } = useAuth(); // Extract faculties and majors from AuthContext
  const [pathwayName, setPathwayName] = useState('');
  const [courses, setCourses] = useState([]);
  const [electives, setElectives] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [requirements, setRequirements] = useState([]);
  const [upperDivisionCredits, setUpperDivisionCredits] = useState(45);
  const [overallCredits, setOverallCredits] = useState(120);
  const [missingFields, setMissingFields] = useState([]);
  const [showClassSearch, setShowClassSearch] = useState({
    open: false,
    section: '',
    index: null,
  });

  // New State Variables for Additional Questions
  const [isBasedOnAnotherMajor, setIsBasedOnAnotherMajor] = useState(false);
  const [basedOnMajor, setBasedOnMajor] = useState('');
  const [faculty, setFaculty] = useState('');
  const [numberOfYears, setNumberOfYears] = useState(1);

  // Define your steps
  const steps = [
    'Details',
    'Courses',
    'Electives',
    'Requirements',
    'Milestones',
    'Review',
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  // Initialize courses from selectedCourses prop
  useEffect(() => {
    setCourses(selectedCourses || []);
  }, [selectedCourses]);

  const totalSteps = () => steps.length;

  const isStepComplete = (step) => completed[step] === true;

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    // Enhanced Validation Before Moving to the Next Step
    if (activeStep === 0) {
      const fields = [];
      if (!pathwayName.trim()) fields.push('Pathway Name');
      if (!faculty) fields.push('Faculty');
      if (!numberOfYears || numberOfYears < 1) fields.push('Number of Years');
      if (isBasedOnAnotherMajor && !basedOnMajor) fields.push('Based On Major');
      if (fields.length > 0) {
        setMissingFields(fields);
        return;
      }
    }
    setMissingFields([]);
    const newActiveStep =
      activeStep === totalSteps() - 1 ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setMissingFields([]);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = { ...completed };
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  /**
   * Adds selected classes to the specified section.
   * @param {Array} selectedClasses - The classes selected from the overlay.
   * @param {string} section - The section to which classes should be added.
   * @param {number|null} index - The index of the section item (for possibilities).
   */
  const handleAddClassToSection = (selectedClasses, section, index = null) => {
    if (!Array.isArray(selectedClasses) || selectedClasses.length === 0) {
      console.error('Invalid or empty selected classes:', selectedClasses);
      return;
    }

    switch (section) {
      case 'electives':
        setElectives([...electives, ...selectedClasses]);
        break;
      case 'courses':
        // Initialize Possibilities for newly added courses
        const newCourses = selectedClasses.map((cls) => ({
          ...cls,
          Possibilities: [], // Ensure Possibilities is initialized
        }));
        setCourses([...courses, ...newCourses]);
        break;
      case 'milestones':
        setMilestones([...milestones, ...selectedClasses]);
        break;
      case 'requirements':
        // Initialize Possibilities for newly added requirements
        const newRequirements = selectedClasses.map((cls) => ({
          ...cls,
          Possibilities: [], // Ensure Possibilities is initialized
        }));
        setRequirements([...requirements, ...newRequirements]);
        break;
      case 'possibilities':
        if (index !== null) {
          // For Courses
          if (courses[index]) {
            const updatedCourses = [...courses];
            const newPossibilities = selectedClasses.map((cls) => ({
              courseInfo_courseNumber: cls.courseInfo_courseNumber,
              Description: cls.courseInfo_courseNumber, // **Set to course number**
            }));
            updatedCourses[index].Possibilities = [
              ...(updatedCourses[index].Possibilities || []),
              ...newPossibilities.filter(
                (possibility) =>
                  !updatedCourses[index].Possibilities.some(
                    (existing) =>
                      existing.courseInfo_courseNumber ===
                      possibility.courseInfo_courseNumber
                  )
              ),
            ];
            setCourses(updatedCourses);
          }

          // For Requirements
          if (requirements[index]) {
            const updatedRequirements = [...requirements];
            const newPossibilities = selectedClasses.map((cls) => ({
              courseInfo_courseNumber: cls.courseInfo_courseNumber,
              Description: cls.courseInfo_courseNumber, // **Set to course number**
            }));
            updatedRequirements[index].Possibilities = [
              ...(updatedRequirements[index].Possibilities || []),
              ...newPossibilities.filter(
                (possibility) =>
                  !updatedRequirements[index].Possibilities.some(
                    (existing) =>
                      existing.courseInfo_course_number ===
                      possibility.courseInfo_course_number
                  )
              ),
            ];
            setRequirements(updatedRequirements);
          }
        } else {
          console.error('Invalid index for possibilities assignment.');
        }
        break;
      default:
        console.error('Unknown section:', section);
    }
  };

  /**
   * Opens the class search overlay for a specific section.
   * @param {string} section - The section to add classes to.
   * @param {number|null} index - The index within the section (if applicable).
   */
  const handleShowClassSearch = (section, index) => {
    setShowClassSearch({
      open: true,
      section,
      index,
    });
  };

  /**
   * Validates and saves the pathway to Firebase.
   */
  const handleSavePathway = async () => {
    // Enhanced Validation Before Saving
    const fields = [];
    if (!pathwayName.trim()) fields.push('Pathway Name');
    if (!faculty) fields.push('Faculty');
    if (!numberOfYears || numberOfYears < 1) fields.push('Number of Years');
    if (isBasedOnAnotherMajor && !basedOnMajor) fields.push('Based On Major');
    if (courses.length === 0) fields.push('At least One Course');

    if (fields.length > 0) {
      console.log("Missing Fields:", fields); // Log missing fields
      setMissingFields(fields);
      setActiveStep(0); // Redirect to first step if validation fails
      return;
    }

    const db = getDatabase();
    const encodedPathwayName = encodePathwayName(pathwayName);

    try {
      // Save Pathway Grouping Data to Parent Sheet
      const pathwayGroupingRef = ref(
        db,
        `${process.env.REACT_APP_PARENT_SHEET}/Pathway_Groupings/${encodedPathwayName}`
      );

      const pathwayGroupingData = {
        Faculty: faculty,
        OverallCR: overallCredits,
        UDCR: upperDivisionCredits,
        pathwayName: pathwayName,
        pathwayType: 'Major',
        programName: isBasedOnAnotherMajor ? basedOnMajor : pathwayName,
        numberOfYears: numberOfYears,
      };

      console.log("Saving pathway grouping data to:", `${process.env.REACT_APP_PARENT_SHEET}/Pathway_Groupings/${encodedPathwayName}`);
      await set(pathwayGroupingRef, pathwayGroupingData);
      console.log("Pathway grouping data saved successfully.");

      // Save Pathway Courses to Majors Sheet
      const pathwayRef = ref(
        db,
        `${process.env.REACT_APP_MAJORS_SHEET}/${encodedPathwayName}`
      );

      // Assign unique identifiers to Electives and Milestones
      let electiveCount = 1;
      let milestoneCount = 1;

      // Ensure each entry has the correct courseType and courseInfo_courseNumber
      const formatCourseData = (course) => ({
        CourseSem: course.CourseSem,
        Description: course.Description || "",
        Possibilities: course.Possibilities ? course.Possibilities.map(p => p.courseInfo_courseNumber).join(", ") : "",
        Requirements: course.Requirements || "",
        courseInfo_course_number: course.courseInfo_courseNumber,
        courseType: "Major Course",
        courseYear: course.courseYear,
      });

      const formatElectiveData = (elective) => ({
        CourseSem: elective.CourseSem,
        Description: elective.Description || "",
        Possibilities: elective.Possibilities ? elective.Possibilities.map(p => p.courseInfo_course_number).join(", ") : "",
        Requirements: elective.category, // Use the elective category as Requirements
        courseInfo_course_number: `ELEC ${electiveCount++}`, // Assign "ELEC 1", "ELEC 2", ...
        courseType: "Elective",
        courseYear: elective.courseYear,
      });

      const formatRequirementData = (requirement) => ({
        CourseSem: requirement.CourseSem,
        Description: requirement.RequirementsDesc || "",
        Possibilities: requirement.Possibilities ? requirement.Possibilities.map(p => p.courseInfo_course_number).join(", ") : "",
        Requirements: requirement.RequirementsName || "",
        courseInfo_course_number: requirement.RequirementsName, // Use RequirementsName as the identifier
        courseType: "Requirement",
        courseYear: requirement.courseYear,
      });

      const formatMilestoneData = (milestone) => ({
        CourseSem: milestone.CourseSem,
        Description: milestone.Description || "",
        Possibilities: milestone.Possibilities ? milestone.Possibilities.map(p => p.courseInfo_course_number).join(", ") : "",
        Requirements: milestone.title || "",
        courseInfo_course_number: `MLSTN ${milestoneCount++}`, // Assign "MLSTN 1", "MLSTN 2", ...
        courseType: "Milestone",
        courseYear: milestone.courseYear,
      });

      // Format and structure data before saving
      const formattedCourses = courses.map(formatCourseData);
      const formattedElectives = electives.map(formatElectiveData);
      const formattedRequirements = requirements.map(formatRequirementData);
      const formattedMilestones = milestones.map(formatMilestoneData);

      console.log("Formatted Courses:", formattedCourses);
      console.log("Formatted Electives:", formattedElectives);
      console.log("Formatted Requirements:", formattedRequirements);
      console.log("Formatted Milestones:", formattedMilestones);

      // Combine all formatted entries into one array
      const allFormattedEntries = [
        ...formattedCourses,
        ...formattedElectives,
        ...formattedRequirements,
        ...formattedMilestones,
      ].filter(entry => entry.courseInfo_course_number && entry.courseInfo_course_number !== "");

      console.log("Validated Entries for Saving:", allFormattedEntries);

      // Save pathway metadata to Majors Sheet
      const pathwayData = {
        fullSheetUpdate: true,
      };

      await set(pathwayRef, pathwayData);
      console.log("Pathway metadata saved successfully to Majors Sheet.");

      // Loop through each validated entry and save to Firestore
      for (const entry of allFormattedEntries) {
        const entryRef = ref(
          db,
          `/${process.env.REACT_APP_MAJORS_SHEET}/${encodedPathwayName}/${entry.courseInfo_course_number}`
        );
        console.log(`Saving entry: ${entry.courseInfo_course_number} to pathway: ${encodedPathwayName}`, entry);

        await set(entryRef, entry);
        console.log(`Entry ${entry.courseInfo_course_number} saved successfully.`);
      }

      console.log("All entries saved successfully.");
      onSave(); // Final callback after save
      console.log("Pathway save process complete.");
    } catch (error) {
      console.error('Error saving pathway:', error);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl mx-auto">
      {/* MUI Stepper */}
      <Box sx={{ width: '100%' }}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          className={classes.stepper}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={isStepComplete(index)}>
              <StepButton
                onClick={handleStep(index)}
                className={classes.stepLabel}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Form Steps */}
      {showClassSearch.open ? (
        <PathwayClassSearchOverlay
          onClose={() =>
            setShowClassSearch({ open: false, section: '', index: null })
          }
          onAddClass={(selectedClasses) => {
            handleAddClassToSection(
              selectedClasses,
              showClassSearch.section,
              showClassSearch.index
            );
          }}
          currentPathway={{
            courses,
            electives,
            milestones,
            requirements,
          }}
        />
      ) : (
        <>
          {activeStep === 0 && (
            <Form className="grid grid-cols-1 gap-6">
              {/* Pathway Name */}
              <Form.Group controlId="pathwayName">
                <Form.Label className="font-semibold">Pathway Name</Form.Label>
                <Form.Control
                  type="text"
                  value={pathwayName}
                  onChange={(e) => setPathwayName(e.target.value)}
                  placeholder="Enter pathway name"
                  isInvalid={missingFields.includes('Pathway Name')}
                />
                <Form.Control.Feedback type="invalid">
                  Pathway Name is required.
                </Form.Control.Feedback>
              </Form.Group>

              {/* Upper Division Credits */}
              <Form.Group controlId="upperDivisionCredits">
                <Form.Label className="font-semibold">
                  Upper Division Credit Requirement
                </Form.Label>
                <Form.Control
                  type="number"
                  value={upperDivisionCredits}
                  onChange={(e) => setUpperDivisionCredits(Number(e.target.value))}
                  placeholder="Enter upper division credits"
                />
              </Form.Group>

              {/* Overall Credits */}
              <Form.Group controlId="overallCredits">
                <Form.Label className="font-semibold">
                  Overall Credit Requirement
                </Form.Label>
                <Form.Control
                  type="number"
                  value={overallCredits}
                  onChange={(e) => setOverallCredits(Number(e.target.value))}
                  placeholder="Enter overall credits"
                />
              </Form.Group>

              {/* New: Is Pathway Based on Another Major */}
              <Form.Group controlId="isBasedOnAnotherMajor">
                <Form.Check
                  type="checkbox"
                  label="Is this pathway based on another major?"
                  checked={isBasedOnAnotherMajor}
                  onChange={(e) => setIsBasedOnAnotherMajor(e.target.checked)}
                />
              </Form.Group>

              {/* New: Based On Major (Conditional) */}
              {isBasedOnAnotherMajor && (
                <Form.Group controlId="basedOnMajor">
                  <Form.Label className="font-semibold">Based On Major</Form.Label>
                  <Form.Control
                    as="select"
                    value={basedOnMajor}
                    onChange={(e) => setBasedOnMajor(e.target.value)}
                    isInvalid={missingFields.includes('Based On Major')}
                  >
                    <option value="">Select a major</option>
                    {majors && majors.length > 0 ? (
                      majors.map((major, index) => (
                        <option key={index} value={major.Major}>
                          {major.Major}
                        </option>
                      ))
                    ) : (
                      <option disabled>No majors available</option>
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a major.
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {/* New: Faculty */}
              <Form.Group controlId="faculty">
                <Form.Label className="font-semibold">Faculty</Form.Label>
                <Form.Control
                  as="select"
                  value={faculty}
                  onChange={(e) => setFaculty(e.target.value)}
                  isInvalid={missingFields.includes('Faculty')}
                >
                  <option value="">Select a faculty</option>
                  {faculties && faculties.length > 0 ? (
                    faculties.map((f, index) => (
                      <option key={index} value={f.Schools}>
                        {f.Schools}
                      </option>
                    ))
                  ) : (
                    <option disabled>No faculties available</option>
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a faculty.
                </Form.Control.Feedback>
              </Form.Group>

              {/* New: Number of Years */}
              <Form.Group controlId="numberOfYears">
                <Form.Label className="font-semibold">Number of Years</Form.Label>
                <Form.Control
                  type="number"
                  value={numberOfYears}
                  onChange={(e) => setNumberOfYears(Number(e.target.value))}
                  min="1"
                  isInvalid={missingFields.includes('Number of Years')}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter the number of years.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          )}

          {activeStep === 1 && (
            <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
              <h3 className="text-lg font-semibold mb-2">Courses</h3>
              <CoursesSection
                courses={courses}
                setCourses={setCourses}
                setShowClassSearch={handleShowClassSearch}
                allCourses={Object.values(fetchedCourseData || {})} // Pass all courses as a prop
              />
            </div>
          )}

          {activeStep === 2 && (
            <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
              <h3 className="text-lg font-semibold mb-2">Electives</h3>
              <ElectivesSection
                electives={electives}
                setElectives={setElectives}
                setShowClassSearch={handleShowClassSearch}
              />
            </div>
          )}

          {activeStep === 3 && (
            <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
              <h3 className="text-lg font-semibold mb-2">Requirements</h3>
              <RequirementsSection
                requirements={requirements}
                setRequirements={setRequirements}
                setShowClassSearch={handleShowClassSearch}
              />
            </div>
          )}

          {activeStep === 4 && (
            <div className="bg-gray-50 p-4 rounded-lg shadow overflow-y-auto max-h-96 dark:bg-gray-800">
              <h3 className="text-lg font-semibold mb-2">Milestones</h3>
              <MilestonesSection
                milestones={milestones}
                setMilestones={setMilestones}
                setShowClassSearch={handleShowClassSearch}
              />
            </div>
          )}

          {activeStep === 5 && (
            <ReviewSection
              pathwayName={pathwayName}
              upperDivisionCredits={upperDivisionCredits}
              overallCredits={overallCredits}
              courses={courses}
              electives={electives}
              requirements={requirements}
              milestones={milestones}
              isBasedOnAnotherMajor={isBasedOnAnotherMajor} // New Prop
              basedOnMajor={basedOnMajor}                   // New Prop
              faculty={faculty}                             // New Prop
              numberOfYears={numberOfYears}                 // New Prop
            />
          )}

          {/* Navigation Buttons */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              pt: 2,
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="secondary"
              onClick={handleBack}
              disabled={activeStep === 0}
              className="bg-gray-600 hover:bg-gray-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {activeStep < steps.length - 1 && (
              <Button
                variant="primary"
                onClick={handleNext}
                className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
              >
                Next
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <Button
                variant="success"
                onClick={handleSavePathway}
                className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
              >
                Save Pathway
              </Button>
            )}
          </Box>
        </>
      )}
    </div>
  );
};

export default PathwayFormModal;
