import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { Button, TextInput, Modal, Spinner } from 'flowbite-react';
import CustomSidebar from '../Sidebar/Sidebar';
import debounce from 'lodash.debounce';
import { FaSearch } from 'react-icons/fa'; // Import the search icon

const ResourcePage = () => {
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newResource, setNewResource] = useState({ name: '', description: '', image: '', department: '' });
  const [loading, setLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch resources from Firestore
  useEffect(() => {
    const fetchResources = async () => {
      try {
        const snapshot = await db.collection('resources').get();
        const resourcesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResources(resourcesData);
        setFilteredResources(resourcesData);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };

    fetchResources();
  }, []);

  // Debounced search handler to optimize performance
  const handleSearch = debounce((term) => {
    if (term) {
      const filtered = resources.filter((res) =>
        res.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredResources(filtered);
    } else {
      setFilteredResources(resources);
    }
  }, 300);

  // Handle input change for search
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    handleSearch(term);
  };

  // Toggle create form modal
  const handleToggleCreateForm = () => setShowCreateForm(!showCreateForm);

  // Handle input change for new resource
  const handleInputChange = (e) => {
    setNewResource({
      ...newResource,
      [e.target.name]: e.target.value,
    });
  };

  // Create a new resource group in Firestore
  const handleCreateResource = async () => {
    if (!newResource.name || !newResource.description || !newResource.department) {
      alert('Please fill out all required fields!');
      return;
    }
    setLoading(true);
    try {
      const docRef = await db.collection('resources').add({
        ...newResource,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      const createdResource = { id: docRef.id, ...newResource };
      setResources((prev) => [...prev, createdResource]);
      setFilteredResources((prev) => [...prev, createdResource]);
      setShowCreateForm(false);
      setNewResource({ name: '', description: '', image: '', department: '' });
    } catch (error) {
      console.error('Error creating resource:', error);
    } finally {
      setLoading(false);
    }
  };

  // Open assign users modal for the selected resource
  const handleRecommend = (resource) => {
    setSelectedResource(resource);
    setShowAssignModal(true);
  };

  return (
    <div className="flex min-h-screen">
      {/* Sidebar with fixed position */}
      <CustomSidebar className="w-64" />

      {/* Main content with flex layout */}
      <div className="flex-1 ml-64 p-4 flex flex-col">
        {/* Header Section */}
        <div className="text-center mb-4">
          <h1 className="text-3xl font-bold mb-2">Resources</h1>
          <p className="text-base text-gray-600 mb-2">
            Welcome to the University Resources Page, your go-to hub for accessing all available services on campus.
            Whether you’re seeking academic support, career guidance, or mental health resources, this page brings
            everything together in one place.
          </p>
        </div>

        {/* Search and Create Buttons */}
        <div className="mb-2 flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4">
          {/* Search Input */}
          <TextInput
            type="text"
            placeholder="Search resources..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full sm:w-1/3"
            icon={FaSearch} // Use the React component here
          />

          {/* Create New Resource Button */}
          <Button color="gray" onClick={handleToggleCreateForm}>
            {showCreateForm ? 'Cancel' : 'Create New Resource'}
          </Button>
        </div>

        {/* Resource Cards Display */}
        <div className="flex-1 overflow-y-auto">
          <div className="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4">
            {filteredResources.length > 0 ? (
              filteredResources.map((resource) => (
                <div
                  key={resource.id}
                  className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col h-full"
                >
                  <a href="#">
                    <img
                      className="rounded-t-lg h-40 w-full object-cover"
                      src={resource.image || 'https://via.placeholder.com/300x200'}
                      alt={resource.name}
                    />
                  </a>
                  <div className="p-4 flex flex-col flex-grow">
                    <a href="#">
                      <h5 className="mb-1 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                        {resource.name}
                      </h5>
                    </a>
                    <p className="mb-2 font-normal text-gray-700 dark:text-gray-400 flex-grow">
                      {resource.description}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                      Department: {resource.department}
                    </p>
                    <div className="mt-auto">
                      <Button
                        color="gray"
                        onClick={() => handleRecommend(resource)}
                        className="w-full"
                      >
                        Recommend to a Student
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-full text-center text-gray-500">
                {resources.length === 0 ? (
                  <Spinner aria-label="Loading" />
                ) : (
                  'No resources found.'
                )}
              </div>
            )}
          </div>
        </div>

        {/* Create Resource Form Modal */}
        <Modal show={showCreateForm} onClose={handleToggleCreateForm}>
          <Modal.Header>Create New Resource</Modal.Header>
          <Modal.Body>
            <div className="space-y-4">
              <TextInput
                name="name"
                label="Resource Name"
                placeholder="Enter resource name"
                value={newResource.name}
                onChange={handleInputChange}
                required
              />
              <TextInput
                name="description"
                label="Description"
                placeholder="Enter description"
                value={newResource.description}
                onChange={handleInputChange}
                required
              />
              <TextInput
                name="image"
                label="Image URL"
                placeholder="Enter image URL"
                value={newResource.image}
                onChange={handleInputChange}
              />
              <TextInput
                name="department"
                label="Department/School"
                placeholder="Enter department or school"
                value={newResource.department}
                onChange={handleInputChange}
                required
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCreateResource} disabled={loading} gradientMonochrome="success">
              {loading ? (
                <>
                  <Spinner size="sm" light={true} className="mr-2" />
                  Creating...
                </>
              ) : (
                'Create Resource'
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Assign Users Modal (Assuming it's implemented elsewhere) */}
        {showAssignModal && selectedResource && (
          <Modal show={showAssignModal} onClose={() => setShowAssignModal(false)}>
            <Modal.Header>Assign Users to {selectedResource.name}</Modal.Header>
            <Modal.Body>
              {/* Modal content for assigning users goes here */}
              <p>Feature under development.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setShowAssignModal(false)}>Close</Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ResourcePage;
