// Staff.js
import React, { useState } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import UsersList from './UsersList';
import GroupsList from './GroupsList';

const Staff = () => {
  const [activeTab, setActiveTab] = useState('users'); // Manage active tab state

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64">
        {/* Tab Navigation */}
        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" role="tablist">
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'users' ? 'text-blue-700 border-blue-700' : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}`}
                onClick={() => setActiveTab('users')}
                type="button"
                role="tab"
              >
                Users
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg ${activeTab === 'groups' ? 'text-blue-700 border-blue-700' : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}`}
                onClick={() => setActiveTab('groups')}
                type="button"
                role="tab"
              >
                Groups
              </button>
            </li>
          </ul>
        </div>

        {/* Tab Content */}
        <div className="mt-6 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
          {activeTab === 'users' && (
            <div role="tabpanel">
              <UsersList /> {/* Display Users List */}
            </div>
          )}
          {activeTab === 'groups' && (
            <div role="tabpanel">
              <GroupsList /> {/* Display Groups List */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Staff;
