import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMapMarkerAlt, faBook, faTag, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../../index.css';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import ProfileJoyrideTour from './ProfileJoyrideTour';
import { BsThreeDots } from 'react-icons/bs';
import { Card, Button } from 'flowbite-react';


const StudentProfile = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [schedule, setSchedule] = useState([]);
    const [totCoursesCount, setTotCoursesCount] = useState(0);
    const [planCoursesCount, setPlanCoursesCount] = useState(0);
    const [photoURL, setPhotoURL] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser: authUser, fetchedCourseData, fetchedMajors, fetchedSurveyData } = useAuth();
    const [takenCoursesCount, setTakenCoursesCount] = useState(0);
    const [milestoneData, setMilestoneData] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [completedMilestones, setCompletedMilestones] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const navigate = useNavigate();

    const yearMapping = {
        "First Year": "year1",
        "Second Year": "year2",
        "Third Year": "year3",
        "Fourth Year": "year4",
        "Fifth Year": "year5"
    };

    const getStudentInfo = async () => {
        try {
            if (authUser) {
                setCurrentUser(authUser);
                const userDocRef = doc(db, 'users', authUser.uid);
                const userDoc = await getDoc(userDocRef);
    
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    console.log('userData:', userData); // Debugging
    
                    // Validate and retrieve primaryTab
                    const primaryTab = userData?.primaryTab;
                    console.log('primaryTab:', primaryTab); // Debugging
    
                    if (!primaryTab) {
                        console.error('primaryTab is undefined');
                        // Optionally, you can set default values or handle this case as needed
                        return;
                    }
    
                    // Validate and retrieve schedules
                    const schedules = userData?.schedules;
                    if (!schedules || !schedules[primaryTab]) {
                        console.error(`No schedules found for primaryTab: ${primaryTab}`);
                        // Optionally, set default schedule or handle the absence of schedules
                        return;
                    }
    
                    const finalizedSchedule = schedules[primaryTab];
                    console.log('finalizedSchedule:', finalizedSchedule); // Debugging
    
                    // Retrieve completedSemesters and photoURL with defaults
                    const completedSemesters = userData.completedSemesters || {};
                    const photoUrl = userData.photoUrl || '/default.jpg';
                    setPhotoURL(photoUrl);
    
                    // Aggregate courses from all years and semesters
                    let courseArray = [];
                    for (let i = 1; i <= 5; i++) { // Adjusted to handle up to fifth year if needed
                        let year = `year${i}`;
                        for (let j = 1; j <= 2; j++) {
                            let semester = `S${j}`;
                            if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                                courseArray.push(...finalizedSchedule[year][semester]['courses']);
                            }
                        }
                    }
    
                    // Retrieve the academic year from surveyAnswers with a default
                    const seeYr = userData.surveyAnswers?.Year || 'defaultYear';
                    const mappedSeeYr = yearMapping[seeYr];
                    if (!mappedSeeYr) {
                        console.warn(`Year mapping for '${seeYr}' not found.`);
                    }
    
                    // Aggregate courses for the specific academic year
                    let courseArray1 = [];
                    for (let j = 1; j <= 2; j++) {
                        let semester = `S${j}`;
                        if (finalizedSchedule[mappedSeeYr] && finalizedSchedule[mappedSeeYr][semester]) {
                            courseArray1.push(...finalizedSchedule[mappedSeeYr][semester]['courses']);
                        }
                    }
    
                    // Filter student courses based on aggregated course numbers
                    const studentCourses = fetchedCourseData
                        ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
                        : [];
                    
                    // Retrieve the major with validation
                    const major = userData.surveyAnswers?.Majors?.[0] || 'N/A';
                    if (!fetchedMajors || !fetchedMajors[major]) {
                        console.error(`Major '${major}' not found in fetchedMajors`);
                        setTotCoursesCount(0);
                        setTakenCoursesCount(0);
                        setPlanCoursesCount(0);
                        return;
                    }
    
                    const majorCourses = Object.values(fetchedMajors[major]);
                    const totalCourses = majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone').length;
    
                    // Calculate all course arrays
                    // Taken Courses
                    const takenCoursesSet = new Set();
                    Object.keys(completedSemesters).forEach(year => {
                        Object.keys(completedSemesters[year]).forEach(semesterKey => {
                            if (completedSemesters[year][semesterKey]) {
                                const completedCourses = finalizedSchedule[year]?.[semesterKey]?.courses || [];
                                completedCourses.forEach(course => {
                                    takenCoursesSet.add(course);
                                });
                            }
                        });
                    });
                    const takenCoursesArray = Array.from(takenCoursesSet);
                    const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));
    
                    // Helper Functions (ensure these are defined outside if reused elsewhere)
                    const findRequirementCourses = (majorCourses, courses, nonUsableCourses) => {
                        courses.sort((a, b) => a.courseInfo_courseNumber.localeCompare(b.courseInfo_courseNumber));
                        const usedCoursesForRequirements = [];
                        let plannedRequirementCoursesArray = [];
                        let largestValidArray = [];
    
                        const backtrack = (requirementIndex) => {
                            if (requirementIndex === majorCourses.length) {
                                if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...plannedRequirementCoursesArray];
                                }
                                return;
                            }
    
                            const majorCourse = majorCourses[requirementIndex];
    
                            if ((majorCourse.courseType === 'Major Course' && majorCourse.Requirements) || majorCourse.courseType === 'Requirement') {
                                const possibilities = majorCourse.Possibilities || '';
                                if (!possibilities) {
                                    console.warn(`Course '${majorCourse.courseInfo_courseNumber}' has no possibilities defined.`);
                                    backtrack(requirementIndex + 1);
                                    return;
                                }
    
                                const possibleCourses = possibilities.split(',').map(course => course.trim());
    
                                let foundValidCourse = false;
    
                                for (let courseNumber of possibleCourses) {
                                    const matchingCourse = courses.find(course =>
                                        course.courseInfo_courseNumber === courseNumber &&
                                        !nonUsableCourses.includes(courseNumber) &&
                                        !usedCoursesForRequirements.includes(courseNumber)
                                    );
    
                                    if (matchingCourse) {
                                        plannedRequirementCoursesArray.push(courseNumber);
                                        usedCoursesForRequirements.push(courseNumber);
                                        foundValidCourse = true;
    
                                        backtrack(requirementIndex + 1);
    
                                        // Backtrack
                                        plannedRequirementCoursesArray.pop();
                                        usedCoursesForRequirements.pop();
                                    }
                                }
    
                                if (!foundValidCourse) {
                                    backtrack(requirementIndex + 1);
                                }
    
                                if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...plannedRequirementCoursesArray];
                                }
                            } else {
                                backtrack(requirementIndex + 1);
                            }
                        };
    
                        backtrack(0);
                        return largestValidArray;
                    };
    
                    const findElectiveCourses = (majorCourses, courses) => {
                        courses.sort((a, b) => a.courseInfo_courseNumber.localeCompare(b.courseInfo_courseNumber));
                        const usedCoursesForElectives = [];
                        let takenElectiveCoursesArray = [];
                        let largestValidArray = [];
    
                        const backtrack = (electiveIndex) => {
                            if (electiveIndex === majorCourses.length) {
                                if (takenElectiveCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...takenElectiveCoursesArray];
                                }
                                return;
                            }
    
                            const majorCourse = majorCourses[electiveIndex];
    
                            if (majorCourse.courseType === 'Elective') {
                                const electiveName = majorCourse.Possibilities || '';
                                if (!electiveName) {
                                    console.warn(`Elective course '${majorCourse.courseInfo_courseNumber}' has no possibilities defined.`);
                                    backtrack(electiveIndex + 1);
                                    return;
                                }
    
                                const electiveNames = electiveName.split(',').map(name => name.trim());
                                let foundValidCourse = false;
    
                                for (let elective of electiveNames) {
                                    const matchingCourse = courses.find(course =>
                                        course.Elective_fulfillment?.includes(elective) &&
                                        !usedCoursesForElectives.includes(course.courseInfo_courseNumber)
                                    );
    
                                    if (matchingCourse) {
                                        takenElectiveCoursesArray.push(matchingCourse.courseInfo_courseNumber);
                                        usedCoursesForElectives.push(matchingCourse.courseInfo_courseNumber);
                                        foundValidCourse = true;
                                        break; // Stop after finding the first valid course
                                    }
                                }
    
                                if (takenElectiveCoursesArray.length > largestValidArray.length) {
                                    largestValidArray = [...takenElectiveCoursesArray];
                                }
    
                                backtrack(electiveIndex + 1);
                            } else {
                                backtrack(electiveIndex + 1);
                            }
                        };
    
                        backtrack(0);
                        return largestValidArray;
                    };

                    const majorRequiredSpecificCourses = majorCourses.filter((majorCourse) => ((majorCourse.courseType == 'Major Course') && (majorCourse.Possibilities == "")))
    
                    // Identify taken major courses
                    const takenMajorCoursesArray = takenCourses.filter(course => {
                        return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
                    });
    
                    // Identify taken elective courses
                    const takenElectiveCoursesArray = findElectiveCourses(
                        majorCourses.filter(course => course.courseType === 'Elective'),
                        takenCourses
                    );
    
                    // Identify taken requirement courses
                    const takenRequirementCoursesArray = findRequirementCourses(
                        majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || course.courseType === 'Requirement'),
                        takenCourses,
                        takenElectiveCoursesArray
                    );
    
                    console.log(studentCourses, 'hiasdf', majorRequiredSpecificCourses)
                    // Calculate all major, elective, and requirement courses
                    const allMajorCoursesArray = studentCourses.filter(course => {
                        console.log(course.courseInfo_courseNumber, )
                        return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
                    });
    
                    const allElectiveCoursesArray = findElectiveCourses(
                        majorCourses.filter(course => course.courseType === 'Elective'),
                        studentCourses
                    );
    
                    const allRequirementCoursesArray = findRequirementCourses(
                        majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || course.courseType === 'Requirement'),
                        studentCourses,
                        allElectiveCoursesArray
                    );
    
                    // Calculate counts
                    const takenCoursesLengths = takenMajorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length;
                    const allCoursesLengths = allMajorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths;
    
                    console.log(majorCourses, allMajorCoursesArray, allElectiveCoursesArray, allRequirementCoursesArray, 'hiasdf')
                    setTakenCoursesCount(takenCoursesLengths);
                    setPlanCoursesCount(allCoursesLengths);
                    setTotCoursesCount(totalCourses);
    
                    // Update major makeup in Firestore
                    await updateDoc(userDocRef, { majorMakeup: { [major]: allMajorCoursesArray.length } });
    
                    // Fetch and set schedule data
                    const year = userData.surveyAnswers?.Year || 'N/A';
                    await fetchScheduleData(year, schedules[primaryTab]);
    
                    // Calculate total credits for the current year
                    const currentYearCourses = studentCourses.filter(course => courseArray1.includes(course.courseInfo_courseNumber));
                    const totalCreditsCalculated = currentYearCourses.reduce((acc, course) => {
                        const credits = parseInt(course.Credits, 10);
                        return acc + (isNaN(credits) ? 0 : credits);
                    }, 0);
    
                    setTotalCredits(totalCreditsCalculated);
                    console.log("Student info fetched successfully");
                } else {
                    console.error('User document does not exist');
                }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };    
    
    const fetchScheduleData = (year, finalizedSchedule) => {
        if (year && finalizedSchedule) {
            const mappedYear = yearMapping[year];
            if (mappedYear) {
                const fallCourses = [];
                const springCourses = [];
                // Check if Fall semester exists and push courses to fallCourses array
                if (finalizedSchedule[mappedYear]?.S1) {
                    fallCourses.push(...finalizedSchedule[mappedYear].S1.courses);
                }
                // Check if Spring semester exists and push courses to springCourses array
                if (finalizedSchedule[mappedYear]?.S2) {
                    springCourses.push(...finalizedSchedule[mappedYear].S2.courses);
                }
                // Set the organized schedule state
                setSchedule({
                    Fall: fallCourses,
                    Spring: springCourses,
                });
            }
        }
    };    

    useEffect(() => {
        const fetchMilestoneData = async () => {
            if (authUser) {
                const userDocRef = doc(db, 'users', authUser.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setMilestoneData(data);
    
                    if (data.finalizedMilestones) {
                        const primaryTab = data.primaryTab || 'defaultTab'; // Get the active tab
                        const milestonesInprimaryTab = data.finalizedMilestones[primaryTab] || {};
    
                        const milestoneList = Object.values(milestonesInprimaryTab).reduce((acc, yearData) => {
                            Object.values(yearData).forEach(semData => {
                                if (semData.milestones) {
                                    acc = acc.concat(semData.milestones);
                                }
                            });
                            return acc;
                        }, []);
    
                        setMilestones(milestoneList);
                        const completed = milestoneList.filter(milestone => milestone.completed).length;
                        setCompletedMilestones(completed);
                    }
                }
            }
        };
        fetchMilestoneData();
    }, [authUser]);

    useEffect(() => {
        if (authUser && fetchedCourseData) {
            getStudentInfo();
        }
    }, [authUser, fetchedCourseData, fetchedSurveyData]);

    const handleNameClick = () => {
        setClickCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 4) {
                navigate('/roles');
            }
            return newCount;
        });
    };

    if (loading) {
        return (
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        );
      }

    const totalMilestones = milestones.length;
    const milestoneProgress = totalMilestones > 0 ? Math.round((completedMilestones / totalMilestones) * 100) : 0;
    // const takenCourses = 0; // Since completedCourses is currently zero
    const progressTaken = totCoursesCount > 0 ? Math.round((planCoursesCount / totCoursesCount) * 100) : 0;
    const progressDone = totCoursesCount > 0 ? Math.round((takenCoursesCount / totCoursesCount) * 100) : 0;
    const remainingCourses = totCoursesCount - takenCoursesCount - planCoursesCount;

    const userFullName = () => {
        if (authUser && authUser.firstName && authUser.lastName) {
            return `${authUser.firstName} ${authUser.lastName}`;
        }
        return 'User';
    };

    const TagList = ({ tags }) => {
        const [showAll, setShowAll] = useState(false);
    
        if (!Array.isArray(tags) || tags.length === 0) {
            return (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                    No Tags Selected
                </span>
            );
        }
    
        const handleToggle = () => setShowAll(!showAll);
    
        return (
            <div className="relative">
                <div
                    className={`flex flex-wrap items-center ${showAll ? 'max-h-none' : 'max-h-12 overflow-hidden'} transition-all`}
                >
                    {tags.map((tag, index) => (
                        <div
                            key={index}
                            className={`flex items-center mr-2 mb-1 ${!showAll && index >= 3 ? 'hidden' : ''}`}
                            style={{ lineHeight: '1.2' }} // Ensures better alignment of text and icon
                        >
                            <FontAwesomeIcon
                                icon={faTag}
                                className="mr-1"
                                style={{ verticalAlign: 'middle', lineHeight: 'inherit' }} // Vertically align the icon
                            />
                            <span className="text-sm leading-none">{tag}</span>
                        </div>
                    ))}
                    {showAll && (
                        <button
                            onClick={handleToggle}
                            className="rounded bg-gray-100 px-1 py-1 flex items-center justify-center hover:text-blue-500 hover:underline"
                            aria-label="Hide"
                        >
                            <FontAwesomeIcon icon={faEyeSlash} size="sm" />
                        </button>
                    )}
                </div>
                {tags.length > 3 && !showAll && (
                    <button
                        onClick={handleToggle}
                        className="absolute top-0 rounded right-0 bg-gray-100 px-1 py-.5 flex items-center justify-center hover:text-blue-500 hover:underline"
                        aria-label="Toggle"
                        style={{ right: '-18px' }} // Move button further right
                    >
                        <BsThreeDots size={15} />
                    </button>
                )}
            </div>
        );
    };
    

    return (
        <div>
        <NavBar />
        <ProfileJoyrideTour/>
        <Card className="w-full max-w-6xl px-4 bg-white-100 shadow-md relative mt-20"> {/* Widen the card */}
            <div className="flex items-center mb-1">
                <img className="w-[160px] h-[160px] mb-4 mr-5 rounded-full" src={photoURL || '/default.jpg'} alt="Profile Picture" />
                <div className="flex-grow max-w-[calc(100%-100px)]">
                    <h1 className="text-2xl font-bold mb-1" onClick={handleNameClick}>{userFullName()}</h1>
                    {authUser?.email && <p className="text-sm mb-1">{authUser.email}</p>}
                    {(authUser?.surveyAnswers?.Year || 'N/A') && (authUser?.surveyAnswers?.Faculties?.length > 0 || 'N/A') && (
                        <p className="text-sm mb-1">
                            {authUser?.surveyAnswers?.Year || 'N/A'} - {authUser?.surveyAnswers?.Faculties?.join(', ') || 'N/A'}
                        </p>
                    )}
                    {authUser?.surveyAnswers?.Majors?.length > 0 && (
                        <p className="text-sm">
                            <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                            {authUser?.surveyAnswers?.Majors?.length === 1 ? 'Major in' : 'Majors in'} {authUser?.surveyAnswers?.Majors?.join(', ')}
                        </p>
                    )}
                    {authUser?.surveyAnswers?.Minors?.length > 0 && (
                        <p className="text-sm mb-1">
                            <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                            {authUser.surveyAnswers.Minors.length === 1 ? 'Minor in' : 'Minors in'} {authUser.surveyAnswers.Minors.join(', ')}
                        </p>
                    )}
                    {authUser?.surveyAnswers?.Campus && (
                        <p className="text-sm mb-1">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> {authUser.surveyAnswers.Campus}
                        </p>
                    )}
                    {(() => {
                        const startMonth = authUser?.surveyAnswers?.SchoolStart?.month;
                        const startYear = authUser?.surveyAnswers?.SchoolStart?.year;
                        const endMonth = authUser?.surveyAnswers?.SchoolEnd?.month;
                        const endYear = authUser?.surveyAnswers?.SchoolEnd?.year;

                        const startDate = startMonth && startYear ? `${startMonth} ${startYear}` : 'N/A';
                        const endDate = endMonth && endYear ? `${endMonth} ${endYear}` : 'N/A';

                        if (startDate !== 'N/A' || endDate !== 'N/A') {
                            return (
                                <p className="text-sm mb-1">
                                    <FontAwesomeIcon icon={faGraduationCap} /> {startDate} - {endDate}
                                </p>
                            );
                        }

                        return null;
                    })()}
                    {/* Flex container for Pronouns and GPA */}
                    {(authUser?.surveyAnswers?.Pronouns || (Array.isArray(authUser?.surveyAnswers?.Tags) && authUser.surveyAnswers.Tags.length > 0)) && (
                        <div className="flex items-center text-sm">
                            <FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                            {authUser?.surveyAnswers?.Pronouns && (
                                <span className="mr-2 mb-1">Pronouns: {authUser.surveyAnswers.Pronouns}</span>
                            )}
                            {/*<FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                            {authUser?.surveyAnswers?.GPA && (
                                <span className="mr-2 mb-1">GPA: {authUser.surveyAnswers.GPA}</span>
                            )}*/}
                            <TagList tags={authUser?.surveyAnswers?.Tags} />
                        </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-between ">
                <div className="mb-0">
                    <h4 className="mb-2">Progress Towards {authUser.surveyAnswers.Majors[0]} Pathway</h4>
                    <div className="relative w-full max-w-[25vw] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                            className="absolute bg-green-500 h-2.5"
                            style={{
                                width: `${progressDone}%`,
                                left: '0',
                                top: 0,
                                borderTopLeftRadius: '9999px',
                                borderBottomLeftRadius: '9999px',
                                borderTopRightRadius: progressTaken > 0 ? '0' : '9999px',
                                borderBottomRightRadius: progressTaken > 0 ? '0' : '9999px'
                            }}
                        ></div>
                        <div
                            className="absolute bg-yellow-300 h-2.5"
                            style={{
                                width: `${progressTaken}%`,
                                left: `${progressDone}%`,
                                top: 0,
                                borderTopRightRadius: '9999px',
                                borderBottomRightRadius: '9999px',
                                borderTopLeftRadius: progressDone > 0 ? '0' : '9999px',
                                borderBottomLeftRadius: progressDone > 0 ? '0' : '9999px'
                            }}
                        ></div>
                    </div>
                    <div className="flex justify-between mt-2 space-x-10 w-full max-w-[25vw] items-center">
                        <div className="rounded bg-green-100 px-2 py-0.3">
                            <span className="text-green-600 font-semibold">{takenCoursesCount} Taken</span>
                        </div>
                        <div className="rounded bg-yellow-100 px-2 py-0.3 ml-3">
                            <span className="text-yellow-500 font-semibold">{planCoursesCount || 0} Planned</span>
                        </div>
                        <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                            <span className="text-gray-600 font-semibold">{remainingCourses || 0} Unplanned</span>
                        </div>
                    </div>

                    </div>
                    <div className="milestone-progress">
                        <h4 className="mb-2">Milestone Progress</h4>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${milestoneProgress || 0}%` }}></div>
                        </div>
                        <div className="flex justify-between mt-2">
                            <div className="rounded bg-blue-100 px-2 py-0.3">
                                <span className="text-blue-600 font-semibold">{completedMilestones || 0} Completed</span>
                            </div>
                            <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                                <span className="text-gray-500 font-semibold">{(totalMilestones || 0) - (completedMilestones || 0)} Remaining</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-2 mt-1"> {/* Updated to 4 columns */}
                    {/* School Portals Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                        <h2 className="text-gray-700 font-semibold mb-2">School Portals</h2>
                        <ul className="my-4 space-y-3">
                            <li>
                                <a href={process.env.REACT_APP_GENERAL_PORTAL} target="_blank" className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                    <svg aria-hidden="true" className="h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    <span className="flex-1 ms-3 whitespace-nowrap">General Portal</span>
                                </a>
                            </li>
                            <li>
                                <a href={process.env.REACT_APP_ADVISOR_PORTAL} target="_blank" className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                    <svg aria-hidden="true" className="h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h4l3-3 4 8 3-3h4"></path>
                                    </svg>
                                    <span className="flex-1 ms-3 whitespace-nowrap">Advisor Portal</span>
                                </a>
                            </li>
                            <li>
                                <a href={process.env.REACT_APP_COURSE_SEARCH} target="_blank" className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                    <svg aria-hidden="true" className="h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01"></path>
                                    </svg>
                                    <span className="flex-1 ms-3 whitespace-nowrap">Course Search</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Combined Personality Traits and Skills Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                        <h2 className="text-gray-500 font-semibold mb-2">Personality Traits and Skills</h2>
                        <div className="overflow-y-auto max-h-60">
                            <div className="flex flex-wrap">
                                {/* Personality Traits Section */}
                                {Array.isArray(authUser?.surveyAnswers?.RecPersonality) && authUser.surveyAnswers.RecPersonality.length > 0 ? (
                                    authUser.surveyAnswers.RecPersonality.map((trait, index) => (
                                        <span key={index} className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400 mb-2">{trait}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400">No Personality Traits</span>
                                )}

                                {/* Skills Section */}
                                {Array.isArray(authUser?.surveyAnswers?.RecSkills) && authUser.surveyAnswers.RecSkills.length > 0 ? (
                                    authUser.surveyAnswers.RecSkills.map((skill, index) => (
                                        <span key={index} className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400 mb-2">{skill}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400">No Skills</span>
                                )}
                            </div>
                        </div>
                    </div>


                    {/* Interests Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                        <h2 className="text-gray-500 font-semibold mb-2">Interests</h2>
                        <div className="overflow-y-auto max-h-60">
                            <div className="flex flex-wrap">
                                {Array.isArray(authUser?.surveyAnswers?.RecInterests) && authUser.surveyAnswers.RecInterests.length > 0 ? (
                                    authUser.surveyAnswers.RecInterests.map((interest, index) => (
                                        <span key={index} className="bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2">{interest}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400">No Interests</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                    <h2 className="text-gray-500 font-semibold">
                        Current Schedule ({authUser?.surveyAnswers?.Year || 'N/A'}) - Total Credits: {totalCredits}
                    </h2>
                    {Object.keys(schedule).length > 0 ? (
                        <div className="flex justify-between">
                            <div className="w-1/2 pr-1" style={{ maxWidth: 'calc(50%)' }}> {/* Reduced gap */}
                                <h3 className="text-lg font-semibold mt-2">Fall Semester</h3>
                                <ul>
                                    {schedule.Fall.length > 0 ? (
                                        schedule.Fall.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Fall Semester
                                        </span>
                                    )}
                                </ul>
                            </div>

                            <div className="w-1/2 pl-1" style={{ maxWidth: 'calc(50%)' }}> {/* Reduced gap */}
                                <h3 className="text-lg font-semibold mt-2">Spring Semester</h3>
                                <ul>
                                    {schedule.Spring.length > 0 ? (
                                        schedule.Spring.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-gray-200 text-gray-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Spring Semester
                                        </span>
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                            No Planned Courses for Current Year
                        </span>
                    )}
                </div>
            </Card>
        </div>
    );    
};

export default StudentProfile;
