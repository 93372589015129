import React, { useState, useEffect, useCallback, useRef } from 'react';
import FilterCard from './FilterCard';
import CourseSearch from './CourseSearch';
import { useAuth } from '../../../contexts/AuthContext';
import CustomSidebar from '../Sidebar/Sidebar';
import { toast } from 'react-toastify'; // Ensure you have react-toastify installed

const EditCourseData = () => {
  const [initialSelectedCourse, setInitialSelectedCourse] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [displayedCourses, setDisplayedCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  // Initialize filter states as arrays
  const [levelFilter, setLevelFilter] = useState([]);
  const [majorFilter, setMajorFilter] = useState([]);
  const [creditsFilter, setCreditsFilter] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 50;

  const { fetchedCourseData } = useAuth();

  // Refs to keep track of the latest filtered and displayed courses length
  const filteredCoursesLengthRef = useRef(filteredCourses.length);
  const displayedCoursesLengthRef = useRef(displayedCourses.length);

  // Update refs whenever filteredCourses or displayedCourses change
  useEffect(() => {
    filteredCoursesLengthRef.current = filteredCourses.length;
  }, [filteredCourses]);

  useEffect(() => {
    displayedCoursesLengthRef.current = displayedCourses.length;
  }, [displayedCourses]);

  // Handle course data fetching
  useEffect(() => {
    if (fetchedCourseData) {
      setLoading(false);
    }
  }, [fetchedCourseData]);

  // Filtering logic
  useEffect(() => {
    if (fetchedCourseData) {
      const results = Object.values(fetchedCourseData).filter(course => {
        const courseName = course.courseInfo_courseName.toLowerCase();
        const courseNumber = course.courseInfo_courseNumber.toLowerCase();
        const query = searchQuery.toLowerCase();

        const matchesSearchQuery =
          courseName.includes(query) || courseNumber.includes(query);

        // Update filtering logic to handle arrays
        const matchesLevelFilter =
          levelFilter.length > 0 ? levelFilter.includes(course.courseYear.toString()) : true;

        const matchesMajorFilter =
          majorFilter.length > 0
            ? majorFilter.some(major => course.Major.split(',').map(m => m.trim()).includes(major))
            : true;

        const matchesCreditsFilter =
          creditsFilter.length > 0
            ? creditsFilter.includes(course.Credits.toString())
            : true;

        return matchesSearchQuery && matchesLevelFilter && matchesMajorFilter && matchesCreditsFilter;
      });

      setFilteredCourses(results);
      setDisplayedCourses(results.slice(0, coursesPerPage * currentPage));
    }
  }, [searchQuery, levelFilter, majorFilter, creditsFilter, fetchedCourseData, currentPage]);

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setInitialSelectedCourse(course);
    setSearchQuery(`${course.courseInfo_courseNumber} - ${course.courseInfo_courseName}`);
    setFilteredCourses([]);
    setIsEditMode(true);
  };

  const handleResetFilters = () => {
    setSearchQuery('');
    setLevelFilter([]);
    setMajorFilter([]);
    setCreditsFilter([]);
    setCurrentPage(1); // Reset to first page
  };

  // Memoize loadMoreCourses to prevent unnecessary re-creations
  const loadMoreCourses = useCallback(() => {
    if (displayedCoursesLengthRef.current < filteredCoursesLengthRef.current) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }, []);

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="w-full p-4 ml-64">
        <div
          className="fixed top-0 left-0 w-full bg-white dark:bg-gray-800 z-10 flex justify-center items-center border-b border-gray-200"
          style={{ height: '6.3rem' }}
        >
          <div className="ml-64">
            <h1 className="text-lg font-medium text-blue-600">Edit Courses</h1>
          </div>
        </div>
        <div className="mt-28 mb-8 grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <FilterCard
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              levelFilter={levelFilter}
              setLevelFilter={setLevelFilter}
              majorFilter={majorFilter}
              setMajorFilter={setMajorFilter}
              creditsFilter={creditsFilter}
              setCreditsFilter={setCreditsFilter}
              handleResetFilters={handleResetFilters}
              displayedCourseCount={filteredCourses.length}
            />
          </div>
          <div className="col-span-2">
            <CourseSearch
              loading={loading}
              filteredData={displayedCourses}
              handleAddClass={handleCourseClick}
              onLoadMore={loadMoreCourses} // Pass the loadMoreCourses function
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCourseData;
