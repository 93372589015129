import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TextInput, Badge } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash.debounce';
import { useAuth } from '../../../contexts/AuthContext';

const normalizeKeys = (data) => {
  return data.map((item) => ({
    ...item,
    DegreeType: item['Degree Type'] || '',
    GPARequirement: item['GPA Requirement'] || '',
    OfficeLocation: item['Office Location'] || '',
    DegreeLevel: item['Degree Level'] || '',
    Department: item['Department'] || '',
    Faculty: item['Faculty'] || '',
    Completeness: item['Completeness'] || '',
    Major: item['Major'] || '',
    CreditsRequired: item['Credits Required'] || '',
    Duration: item['Duration'] || '',
    'Courses Link': item['Courses Link'] || '',
  }));
};

const ITEMS_PER_PAGE = 20; // Number of items to load per scroll

const PathwayList = () => {
  const { pathwayData, generalDataLoading, error } = useAuth();
  const [pathways, setPathways] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterFaculty, setFilterFaculty] = useState('');
  const [filterDepartment, setFilterDepartment] = useState('');
  const [filterDegreeType, setFilterDegreeType] = useState('');
  const [filterCreditsRange, setFilterCreditsRange] = useState('');
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [degreeTypes, setDegreeTypes] = useState([]);

  // State for Infinite Scroll
  const [displayedPathways, setDisplayedPathways] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (pathwayData) {
      const normalizedData = normalizeKeys(pathwayData);
      setPathways(normalizedData);

      // Extract unique faculties, departments, and degree types
      const uniqueFaculties = [
        ...new Set(normalizedData.map((item) => item.Faculty).filter(Boolean)),
      ];
      const uniqueDepartments = [
        ...new Set(normalizedData.map((item) => item.Department).filter(Boolean)),
      ];
      const uniqueDegreeTypes = [
        ...new Set(normalizedData.map((item) => item.DegreeType).filter(Boolean)),
      ];

      setFaculties(uniqueFaculties);
      setDepartments(uniqueDepartments);
      setDegreeTypes(uniqueDegreeTypes);
    }
  }, [pathwayData]);

  // Function to parse range strings like "30-60"
  const parseRange = (range) => {
    const [min, max] = range.split('-').map(Number);
    return { min, max };
  };

  // Debounced Search Handler to improve performance
  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchQuery(value);
    }, 300),
    []
  );

  const handleSearch = (event) => {
    debouncedSearch(event.target.value);
  };

  const handleFilterFaculty = (event) => {
    setFilterFaculty(event.target.value);
  };

  const handleFilterDepartment = (event) => {
    setFilterDepartment(event.target.value);
  };

  const handleFilterDegreeType = (event) => {
    setFilterDegreeType(event.target.value);
  };

  const handleFilterCreditsRange = (event) => {
    setFilterCreditsRange(event.target.value);
  };

  // Memoize filteredPathways to prevent unnecessary recalculations
  const filteredPathways = useMemo(() => {
    return pathways.filter((pathway) => {
      const major = pathway?.Major?.toLowerCase() || '';
      const faculty = pathway?.Faculty?.toLowerCase() || '';
      const department = pathway?.Department?.toLowerCase() || '';
      const degreeType = pathway?.DegreeType?.toLowerCase() || '';
      const gpaRequirement = parseFloat(pathway?.GPARequirement) || 0;
      const creditsRequired = parseInt(pathway?.CreditsRequired, 10) || 0;

      const matchesSearch = major.includes(searchQuery.toLowerCase());

      const matchesFaculty = filterFaculty
        ? faculty === filterFaculty.toLowerCase()
        : true;

      const matchesDepartment = filterDepartment
        ? department === filterDepartment.toLowerCase()
        : true;

      const matchesDegreeType = filterDegreeType
        ? degreeType === filterDegreeType.toLowerCase()
        : true;

      let matchesCreditsRequired = true;
      if (filterCreditsRange) {
        const { min, max } = parseRange(filterCreditsRange);
        matchesCreditsRequired = creditsRequired >= min && creditsRequired <= max;
      }

      return (
        matchesSearch &&
        matchesFaculty &&
        matchesDepartment &&
        matchesDegreeType &&
        matchesCreditsRequired
      );
    });
  }, [
    pathways,
    searchQuery,
    filterFaculty,
    filterDepartment,
    filterDegreeType,
    filterCreditsRange,
  ]);

  // Initialize displayedPathways when filters or pathways change
  useEffect(() => {
    setDisplayedPathways(filteredPathways.slice(0, ITEMS_PER_PAGE));
    setHasMore(filteredPathways.length > ITEMS_PER_PAGE);
  }, [filteredPathways]);

  // Function to load more pathways on scroll
  const fetchMoreData = useCallback(() => {
    if (displayedPathways.length >= filteredPathways.length) {
      setHasMore(false);
      return;
    }

    const nextItems = filteredPathways.slice(
      displayedPathways.length,
      displayedPathways.length + ITEMS_PER_PAGE
    );
    setDisplayedPathways((prev) => [...prev, ...nextItems]);
  }, [displayedPathways.length, filteredPathways]);

  return (
    <div className="flex-1 p-6 mx-auto">
      <div className="w-full max-w-6xl bg-white p-4 shadow-md flex flex-col space-y-4 z-20 sticky top-0">
        {/* Search Input */}
        <div className="relative w-full">
          <TextInput
            id="search-pathway"
            type="text"
            placeholder="Search pathways..."
            required
            icon={HiSearch}
            onChange={handleSearch}
          />
        </div>

        {/* Filters */}
        <div className="search-filter-container flex flex-wrap gap-4 w-full">
          {/* Faculty Filter */}
          <select
            value={filterFaculty}
            onChange={handleFilterFaculty}
            className="p-2 border border-gray-300 rounded w-full sm:w-auto flex-1 min-w-[150px]"
          >
            <option value="">All Faculties</option>
            {faculties.map((faculty) => (
              <option key={faculty} value={faculty}>
                {faculty}
              </option>
            ))}
          </select>

          {/* Department Filter */}
          <select
            value={filterDepartment}
            onChange={handleFilterDepartment}
            className="p-2 border border-gray-300 rounded w-full sm:w-auto flex-1 min-w-[150px]"
          >
            <option value="">All Departments</option>
            {departments.map((department) => (
              <option key={department} value={department}>
                {department}
              </option>
            ))}
          </select>

          {/* Degree Type Filter */}
          <select
            value={filterDegreeType}
            onChange={handleFilterDegreeType}
            className="p-2 border border-gray-300 rounded w-full sm:w-auto flex-1 min-w-[150px]"
          >
            <option value="">All Degree Types</option>
            {degreeTypes.map((degreeType) => (
              <option key={degreeType} value={degreeType}>
                {degreeType}
              </option>
            ))}
          </select>

          {/* Credits Range Filter */}
          <select
            value={filterCreditsRange}
            onChange={handleFilterCreditsRange}
            className="p-2 border border-gray-300 rounded w-full sm:w-auto flex-1 min-w-[150px]"
          >
            <option value="">All Credits Required</option>
            <option value="30-60">30 - 60</option>
            <option value="60-90">60 - 90</option>
            <option value="90-120">90 - 120</option>
          </select>
        </div>
      </div>

      {/* Pathway List with Infinite Scroll */}
      <div className="pathway-list mt-4">
        {generalDataLoading ? (
          <div className="flex justify-center items-center min-h-screen">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : error ? (
          <p className="text-center text-red-500 mt-4">
            An error occurred while fetching pathways. Please try again later.
          </p>
        ) : filteredPathways.length > 0 ? (
          <InfiniteScroll
            className="flex flex-col gap-4"
            dataLength={displayedPathways.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className="flex justify-center items-center mt-4">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="ml-2">Loading more pathways...</span>
              </div>
            }
            endMessage={
              <p className="text-center mt-4 text-gray-500">No more pathways to show.</p>
            }
          >
            {displayedPathways.map((pathway) => (
              <div
                key={pathway.id || `${pathway.Major}-${pathway.Department}-${pathway.DegreeType}`}
                className="collapsible-pathway p-4 border border-gray-300 rounded shadow-md flex flex-col space-y-2"
              >
                {/* Pathway Header */}
                <div className="pathway-header flex flex-col md:flex-row md:justify-between w-full">
                  <div>
                    <h3 className="font-semibold text-lg">{pathway.Major || 'N/A'}</h3>
                    <p className="text-sm text-gray-600">{pathway.Faculty || 'N/A'}</p>
                  </div>
                  <div className="badges flex flex-wrap gap-2 mt-2 md:mt-0">
                    <Badge color="purple" className="text-sm py-0.5 px-2">
                      {pathway.Department || 'N/A'}
                    </Badge>
                    <Badge color="indigo" className="text-sm py-0.5 px-2">
                      {pathway.DegreeLevel || 'N/A'}
                    </Badge>
                    <Badge color="blue" className="text-sm py-0.5 px-2">
                      {pathway.DegreeType || 'N/A'}
                    </Badge>
                    <Badge color="yellow" className="text-sm py-0.5 px-2">
                      {pathway.Completeness || 'N/A'}
                    </Badge>
                  </div>
                </div>

                {/* Pathway Details */}
                <div className="pathway-details flex flex-wrap gap-2">
                  <Badge color="gray" className="text-sm py-0.5 px-2">
                    <span className="font-medium">Degree Type:</span> {pathway.DegreeType || 'N/A'}
                  </Badge>
                  <Badge color="gray" className="text-sm py-0.5 px-2">
                    <span className="font-medium">Credits:</span> {pathway.CreditsRequired || 'N/A'}
                  </Badge>
                  <Badge color="gray" className="text-sm py-0.5 px-2">
                    <span className="font-medium">Duration:</span> {pathway.Duration || 'N/A'}
                  </Badge>
                  <Badge color="gray" className="text-sm py-0.5 px-2">
                    <span className="font-medium">Location:</span> {pathway.OfficeLocation || 'N/A'}
                  </Badge>
                  <Badge color="gray" className="text-sm py-0.5 px-2">
                    <span className="font-medium">Courses:</span>{' '}
                    {pathway['Courses Link'] ? (
                      <a
                        href={pathway['Courses Link']}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        View Courses
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </Badge>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          !generalDataLoading && (
            <p className="text-center text-gray-500">No pathways match your criteria.</p>
          )
        )}
      </div>
    </div>
  );
};

export default PathwayList;
