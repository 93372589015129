import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDatabase, ref, get, set } from 'firebase/database';
import CustomSidebar from '../Sidebar/Sidebar';

const CourseDetails = () => {
  const navigate = useNavigate(); // Initialize the navigate hook
  const location = useLocation();
  const [initialSelectedCourse, setInitialSelectedCourse] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (location.state && location.state.course) {
      setInitialSelectedCourse(location.state.course);
      setSelectedCourse(location.state.course);
    }
  }, [location.state]);

  useEffect(() => {
    setIsDirty(JSON.stringify(selectedCourse) !== JSON.stringify(initialSelectedCourse));
  }, [selectedCourse, initialSelectedCourse]);

  const handleInputChange = (e, field) => {
    setSelectedCourse({ ...selectedCourse, [field]: e.target.value });
  };

  const handleIndexInputChange = (e, field) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && Number.isInteger(value) && value >= -5 && value <= 5) {
      setSelectedCourse({ ...selectedCourse, [field]: value });
    } else {
      alert("Input must be an integer between -5 and 5");
    }
  };

  const saveChanges = async () => {
    const db = getDatabase();
    const courseRef = ref(db, `/${process.env.REACT_APP_PARENT_SHEET}/${process.env.REACT_APP_COURSES_SHEET}/${selectedCourse.courseInfo_courseNumber}`);
    console.log('Course ref: ', courseRef);
    console.log('Payload:', selectedCourse);
    try {
      await set(courseRef, selectedCourse);
      console.log('Payload:', selectedCourse);
      setInitialSelectedCourse(selectedCourse);
      setIsDirty(false);
      setIsEditMode(false);
      console.log('Changes saved successfully');
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const revertChanges = () => {
    setSelectedCourse(initialSelectedCourse);
    setIsEditMode(false);
    setIsDirty(false);
  };

  return (
    <div className="flex">
      <CustomSidebar />
      <div className="flex-1 ml-64 p-6">
        <div className="fixed top-3.5 right-0 w-full bg-white dark:bg-gray-800 z-10" style={{ width: 'calc(100% - 16rem)' }}>
          <div className="flex justify-center">
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-full mx-auto">
            {selectedCourse && (
              <div className="mt-2">
                <h3 className="text-lg font-semibold mb-2 text-center">{isEditMode ? 'Edit Course Details' : 'Course Details'}</h3>
                <div className="flex justify-start">
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mb-4"
                  onClick={() => navigate(-1)} // Go back to the previous page
                >
                  Back
                </button>
              </div>
                {isEditMode ? (
                  <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div>
                        <div className="text-xs font-bold">Course Number</div>
                        <p className="p-2 border border-white-300 rounded bg-gray-300">{selectedCourse.courseInfo_courseNumber}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Course Name</div>
                        <p className="p-2 border border-white-300 rounded bg-gray-300">{selectedCourse.courseInfo_courseName}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Credits</div>
                        <input
                          type="number"
                          placeholder="Credits"
                          value={selectedCourse.Credits}
                          onChange={(e) => handleInputChange(e, 'Credits')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">School Name</div>
                        <input
                          type="text"
                          placeholder="School Name"
                          value={selectedCourse.sch_name}
                          onChange={(e) => handleInputChange(e, 'sch_name')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Course Year</div>
                        <input
                          type="text"
                          placeholder="Course Year"
                          value={selectedCourse.courseYear}
                          onChange={(e) => handleInputChange(e, 'courseYear')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Course Semester</div>
                        <input
                          type="text"
                          placeholder="Course Semester"
                          value={selectedCourse.CourseSem}
                          onChange={(e) => handleInputChange(e, 'CourseSem')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Major</div>
                        <input
                          type="text"
                          placeholder="Major"
                          value={selectedCourse.Major}
                          onChange={(e) => handleInputChange(e, 'Major')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Mode</div>
                        <input
                          type="text"
                          placeholder="Mode"
                          value={selectedCourse.Mode}
                          onChange={(e) => handleInputChange(e, 'Mode')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div className='col-span-2'>
                        <div className="text-xs font-bold">Notes</div>
                        <textarea
                          placeholder="Notes"
                          value={selectedCourse.Notes}
                          onChange={(e) => handleInputChange(e, 'Notes')}
                          className="p-2 border border-gray-300 rounded w-full"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Instructor</div>
                        <input
                          type="text"
                          placeholder="Instructor"
                          value={selectedCourse.Instructor}
                          onChange={(e) => handleInputChange(e, 'Instructor')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Offering</div>
                        <input
                          type="text"
                          placeholder="Offering"
                          value={selectedCourse.Offering}
                          onChange={(e) => handleInputChange(e, 'Offering')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">STEM/HSS Index</div>
                        <input
                          type="number"
                          placeholder="STEM/HSS Index"
                          value={selectedCourse["RecSTEM-HSS"]}
                          onChange={(e) => handleIndexInputChange(e, 'RecSTEM-HSS')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Project/Test Index</div>
                        <input
                          type="number"
                          placeholder="Project/Test Index"
                          value={selectedCourse["RecProject-Test"]}
                          onChange={(e) => handleIndexInputChange(e, 'RecProject-Test')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">In-Person/Online Index</div>
                        <input
                          type="number"
                          placeholder="In-Person/Online Index"
                          value={selectedCourse["RecInPerson-Online"]}
                          onChange={(e) => handleIndexInputChange(e, 'RecInPerson-Online')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Structured/Flexible Index</div>
                        <input
                          type="number"
                          placeholder="Structured/Flexible Index"
                          value={selectedCourse["RecStructured-Flexible"]}
                          onChange={(e) => handleIndexInputChange(e, 'RecStructured-Flexible')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Small/Large Class Index</div>
                        <input
                          type="number"
                          placeholder="Small/Large Class Index"
                          value={selectedCourse["RecSmall-LargeClass"]}
                          onChange={(e) => handleIndexInputChange(e, 'RecSmall-LargeClass')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Applied/Theoretical Index</div>
                        <input
                          type="number"
                          placeholder="Applied/Theoretical Index"
                          value={selectedCourse["RecApplied-Theoretical"]}
                          onChange={(e) => handleIndexInputChange(e, 'RecApplied-Theoretical')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <div className="text-xs font-bold">Collaborative/Individual Index</div>
                        <input
                          type="number"
                          placeholder="Collaborative/Individual Index"
                          value={selectedCourse["RecCollaborative-Individual"]}
                          onChange={(e) => handleIndexInputChange(e, 'RecCollaborative-Individual')}
                          className="p-2 border border-gray-300 rounded"
                        />
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 mt-2 text-white font-bold py-2 px-4 rounded"
                        onClick={saveChanges}
                        disabled={!isDirty}
                      >
                        Save
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-700 mt-2 text-white font-bold py-2 px-4 rounded ml-2"
                        onClick={revertChanges}
                      >
                        Revert Changes
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <div className="grid grid-cols-4 gap-4">
                      <div>
                        <div className="text-xs font-bold">Course Number</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.courseInfo_courseNumber}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Course Name</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.courseInfo_courseName}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Credits</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.Credits}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">School Name</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.sch_name}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Course Year</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.courseYear}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Course Semester</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.CourseSem}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Major</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.Major}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Mode</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.Mode}</p>
                      </div>
                      <div className='col-span-2'>
                        <div className="text-xs font-bold">Notes</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300 h-20 overflow-y-auto">
                          {selectedCourse.Notes}
                        </p>                     
                      </div>
                      <div>
                        <div className="text-xs font-bold">Instructor</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.Instructor}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Offering</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse.Offering}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">STEM/HSS Index</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse["RecSTEM-HSS"]}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Project/Test Index</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse["RecProject-Test"]}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">In-Person/Online Index</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse["RecInPerson-Online"]}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Structured/Flexible Index</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse["RecStructured-Flexible"]}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Small/Large Class Index</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse["RecSmall-LargeClass"]}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Applied/Theoretical Index</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse["RecApplied-Theoretical"]}</p>
                      </div>
                      <div>
                        <div className="text-xs font-bold">Collaborative/Individual Index</div>
                        <p className="p-2 border border-gray-300 rounded bg-gray-300">{selectedCourse["RecCollaborative-Individual"]}</p>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 mt-2 text-white font-bold py-2 px-4 rounded"
                        onClick={() => setIsEditMode(true)}
                      >
                        Edit Course
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
