import React, { useState, useEffect, useRef, useCallback } from 'react';
import './FinalizedCoursesNew.css';
import { useAuth } from '../../contexts/AuthContext';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';

// Function to calculate background gradient based on score
const getBackgroundGradient = (score) => {
  const green = [144, 238, 144]; // RGB for light green
  const yellow = [255, 255, 224]; // RGB for light yellow
  const red = [255, 182, 193]; // RGB for light red

  let startColor, endColor;
  let percentage;

  if (score >= 50) {
    startColor = yellow;
    endColor = green;
    percentage = (score - 50) / 50;
  } else {
    startColor = red;
    endColor = yellow;
    percentage = score / 50;
  }

  const interpolateColor = (start, end, factor) =>
    start.map((startComponent, index) =>
      Math.round(startComponent + factor * (end[index] - startComponent))
    );

  const [r, g, b] = interpolateColor(startColor, endColor, percentage);

  return `rgb(${r}, ${g}, ${b})`;
};

// Component to display prerequisites if not met
const PrerequisiteDisplay = React.memo(({ prerequisites, isPrereqMet, position, selectedCourse }) => {
  if (isPrereqMet || !position) return null;

  const dynamicStyles = {
    position: 'fixed',
    top: position.top - 10, // Adjust to position above the course
    left: position.left + position.width / 2, // Center horizontally
    transform: 'translate(-50%, -100%)', // Center and move above
    zIndex: 50,
  };

  return (
    <div
      style={{
        ...dynamicStyles,
        boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)', // Adds a subtle blur effect
      }}
      className="prerequisite-display bg-gray-100 p-2 border border-gray-200 rounded-lg shadow-xxl"
    >
      <h3 className="text-sm font-semibold mb-2 text-center">
        Warning: Requisites for {selectedCourse.courseInfo_courseNumber} are not met.
      </h3>
      {prerequisites.map((prerequisiteGroup, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <div className="flex flex-row items-center justify-center gap-2">
            {prerequisiteGroup.map((prereq, index) => (
              <React.Fragment key={index}>
                <div className="relative bg-red-50 border border-red-200 rounded-md p-1.5 shadow-sm flex flex-col items-start text-xs">
                  <div className="flex justify-between w-full mb-1">
                    <span className="font-bold text-xs text-gray-700 mt-0.5">{prereq}</span>
                    <span className="bg-red-200 text-gray-700 font-semibold text-xxs ml-1 px-1 py-0.5 rounded">C-</span>
                  </div>
                  <span className="text-xxs text-gray-600">Prerequisite</span>
                </div>
                {index < prerequisiteGroup.length - 1 && (
                  <span className="text-xs font-semibold text-gray-500 mx-1">OR</span>
                )}
              </React.Fragment>
            ))}
          </div>
          {groupIndex < prerequisites.length - 1 && (
            <div className="flex justify-center my-2">
              <span className="text-xs font-semibold text-gray-500">AND</span>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
  
  
});

// Main Component
const FinalizedCoursesNew = ({
  finalizedCourses,
  onRemove,
  onUpdateCourse,
  semester,
  isCompleted,
  onEvaluate,
  viewMode,
  isDragging,
}) => {
  const { fetchedPrereqData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [prerequisites, setPrerequisites] = useState([]);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]); // State for the last clicked course's prerequisites
  const [selectedPosition, setSelectedPosition] = useState(null); // State for the position of the clicked course
  const [selectedCourse, setSelectedCourse] = useState(null); // New state for the selected course


  useEffect(() => {
    const fetchPrerequisites = async () => {
      try {
        const fetchedPrerequisites = fetchedPrereqData;
        setPrerequisites(fetchedPrerequisites || []);
      } catch (error) {
        console.error('Error fetching prerequisites:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrerequisites();
  }, [fetchedPrereqData]);

  // Handler to set prerequisites, position, and selected course
  const handlePrereqClick = useCallback((course, prereqs, position) => {
    // If the same course is clicked again, toggle the display
    if (
      JSON.stringify(prereqs) === JSON.stringify(selectedPrerequisites) &&
      JSON.stringify(position) === JSON.stringify(selectedPosition) &&
      selectedCourse === course
    ) {
      setSelectedPrerequisites([]);
      setSelectedPosition(null);
      setSelectedCourse(null);
    } else {
      setSelectedPrerequisites(prereqs);
      setSelectedPosition(position);
      setSelectedCourse(course);
    }
  }, [selectedPrerequisites, selectedPosition, selectedCourse]);

  // Close the PrerequisiteDisplay when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.prerequisite-display') &&
        !event.target.closest('.remove-button')
      ) {
        // Only update state if it's currently set
        if (selectedPrerequisites.length > 0 || selectedPosition || selectedCourse) {
          setSelectedPrerequisites([]);
          setSelectedPosition(null);
          setSelectedCourse(null);
        }
      }
    };

    if (selectedPrerequisites.length > 0) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [selectedPrerequisites, selectedPosition, selectedCourse]);

  // Inner Component for each course
  const FinalizedCourse = React.memo(({
    course,
    onRemove,
    isAutoFill,
    isCompleted,
    onEvaluate,
    semester,
    viewMode,
    isDragging,
    onPrereqClick, // New prop
  }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showAutofillMessage, setShowAutofillMessage] = useState(false);
    const courseRef = useRef(null); // Ref to the course card

    const handleHover = (value) => {
      if (!isDragging) {
        setIsHovered(value);
      }
    };

    const handleIconClick = (e) => {
      e.stopPropagation(); // Prevent the click from bubbling up to the window
      if (courseRef.current) {
        const rect = courseRef.current.getBoundingClientRect();
        const position = {
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
        };
        onPrereqClick(course, course.Prerequisites, position); // Pass the course object
      }
    };

    const handleIconHover = (e) => setShowTooltip(e);

    const handleAutofillIconClick = (e) => {
      e.stopPropagation(); // Prevent the click from bubbling up to the window
      setShowAutofillMessage((prevState) => !prevState);
    };

    const VerticalView = () => (
      <div
        className="flex flex-col justify-between"
        style={{ padding: '2px 6px', height: 'auto' }}
      >
        <div className="flex items-center" style={{ marginBottom: '2px' }}>
          <p className="text-xs font-bold">{course.courseInfo_courseNumber}</p>
          <span className="credits ml-auto flex justify-end bg-blue-100 text-blue-800 text-[9px] font-medium px-1 py-0.25 rounded-full dark:bg-blue-900 dark:text-blue-300">
            {course.Credits} Credits
          </span>
        </div>
        <div style={{ marginTop: '2px' }}>
          <p className="text-xs text-left">{course.courseInfo_courseName}</p>
        </div>
      </div>
    );

    const HorizontalView = () => (
      <div className="course-info">
        <div className="course-column">
          <p className="text-md font-bold">{course.courseInfo_courseNumber}</p>
          <p>
            <span className="bg-blue-100 text-blue-800 text-[10px] font-medium px-1.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
              {course.Credits} credits
            </span>
          </p>
        </div>
        <div className="course-column mt-2">
          <p className={isAutoFill ? 'mt-2' : ''}>
            {course.courseInfo_courseName}
          </p>
        </div>
      </div>
    );

    return (
      <div
        ref={courseRef} // Attach ref to the course card
        className="relative p-2 border rounded shadow-sm overflow-hidden course-card w-full text-xs"
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        style={{ backgroundColor: '#F9FAFB' }}
      >
        {/* Icons and messages */}
        {viewMode === 'vertical' &&
          (!course.Offering.includes(semester) || !course.isPrereqMet) && (
            <button
              onClick={handleIconClick}
              className={`remove-button absolute ${
                course.isAutofill ? 'right-6' : 'right-1.5'
              } ${
                course.isAutofill ? 'bottom-2' : 'bottom-1.5'
              } text-orange-500 hover:text-orange-700 cursor-pointer bg-transparent border-none p-0`}
            >
              <FaExclamationTriangle />
            </button>
          )}

        {viewMode === 'horizontal' &&
          (!course.Offering.includes(semester) || !course.isPrereqMet) && (
            <button
              className="remove-button absolute top-1 right-1 text-orange-500 hover:text-orange-700 cursor-pointer bg-transparent border-none p-0"
              onClick={handleIconClick}
            >
              <FaExclamationTriangle />
            </button>
          )}

        {viewMode === 'horizontal' && isAutoFill && (
          <span
            className="absolute top-1.5 right-0 text-xs font-medium me-1 px-2.5 py-0.5 rounded-full"
            style={{
              backgroundColor: getBackgroundGradient(course.recScore),
              color: 'black',
            }}
          >
            {course.autofillType === 'Elective'
              ? course.electiveFullName
              : course.autofillType === 'Requirement'
              ? course.requirementName
              : ''}
            {course.autofillType}: Rec Score {course.recScore}
          </span>
        )}

        {viewMode === 'vertical' && isAutoFill && (
          <button
            onClick={handleAutofillIconClick}
            className="remove-button absolute bottom-1.5 right-1.5 text-purple-500 hover:text-purple-800 cursor-pointer bg-transparent border-none p-0"
          >
            <FontAwesomeIcon icon={faWandMagicSparkles} />
          </button>
        )}

        {viewMode === 'vertical' && showAutofillMessage && (
          <span
            className="absolute top-1.5 right-0 text-xs font-medium me-1 px-2.5 py-0.5 rounded-full"
            style={{
              backgroundColor: getBackgroundGradient(course.recScore),
              color: 'black',
            }}
          >
            {course.autofillType === 'Elective'
              ? course.electiveFullName
              : course.autofillType === 'Requirement'
              ? course.requirementName
              : ''}
            {course.autofillType}: {course.recScore}/100
          </span>
        )}

        {viewMode === 'vertical' ? <VerticalView /> : <HorizontalView />}

        {viewMode === 'horizontal' && isHovered && !isDragging && (
          <div className="absolute bottom-1 right-2 flex space-x-2">
            {isCompleted ? (
              <button
                className="remove-button text-xs text-blue-500 hover:text-black"
                onClick={() => onEvaluate(course)}
              >
                Evaluate
              </button>
            ) : (
              <button
                className="remove-button text-xs text-red-500 hover:text-black"
                onClick={() => onRemove(course)}
              >
                Remove
              </button>
            )}
          </div>
        )}

        {viewMode === 'vertical' && isHovered && !isDragging && (
          <div className="absolute top-0.5 right-0.5 flex space-x-2">
            {isCompleted ? (
              <div className="relative inline-block">
                <button
                  className="remove-button text-xs text-blue-500 hover:text-black flex items-center justify-center p-0.5 rounded-full hover:bg-gray-200 relative"
                  onClick={() => onEvaluate(course)}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </div>
            ) : (
              <div className="relative inline-block">
                <button
                  className="remove-button text-xs text-red-500 hover:text-black flex items-center justify-center p-0.5 rounded-full hover:bg-gray-200 relative"
                  onClick={() => onRemove(course)}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    ); // Correctly close the FinalizedCourse component's return statement
  }); // Close the FinalizedCourse component

  if (loading) {
    // return <p>Loading courses...</p>;
  }

  if (!finalizedCourses || finalizedCourses.length === 0) {
    return <p>No finalized courses available</p>;
  }

  return (
    <div
      style={{ textAlign: 'center', marginTop: 'auto', position: 'relative' }}
    >
      {finalizedCourses.map((course, index) => (
        <FinalizedCourse
          key={course.id || index} // Prefer a unique identifier if available
          course={course}
          onRemove={onRemove}
          onUpdateCourse={onUpdateCourse}
          isAutoFill={course.isAutofill}
          isPrereqMet={course.isPrereqMet}
          isCompleted={isCompleted}
          onEvaluate={onEvaluate}
          semester={semester}
          viewMode={viewMode}
          isDragging={isDragging}
          onPrereqClick={handlePrereqClick} // Pass the handler
        />
      ))}

      {/* Render PrerequisiteDisplay if any course has selectedPrerequisites */}
      <PrerequisiteDisplay
        prerequisites={selectedPrerequisites}
        isPrereqMet={false} // Assuming at least one course's prerequisites are not met
        position={selectedPosition} // Pass the position
        selectedCourse={selectedCourse} // Pass the selected course
      />
    </div>
  );
}; // Close the FinalizedCoursesNew component

export default FinalizedCoursesNew;
