import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import MultiSelect from './MultiSelect';
import { toast } from 'react-toastify';

const FilterCard = ({
  searchQuery,
  setSearchQuery,
  levelFilter,
  setLevelFilter,
  majorFilter,
  setMajorFilter,
  creditsFilter,
  setCreditsFilter,
  handleResetFilters,
  displayedCourseCount,
}) => {
  const { fetchedCourseData } = useAuth();

  // Extract unique filter options using useMemo for performance
  const uniqueLevels = useMemo(() => {
    if (!fetchedCourseData) return [];
    const levels = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.courseYear) levels.add(item.courseYear);
    });
    return Array.from(levels).sort((a, b) => parseInt(a) - parseInt(b));
  }, [fetchedCourseData]);

  const uniqueMajors = useMemo(() => {
    if (!fetchedCourseData) return [];
    const majors = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Major) {
        item.Major.split(',').forEach((major) => {
          majors.add(major.trim());
        });
      }
    });
    return Array.from(majors).sort();
  }, [fetchedCourseData]);

  const uniqueCredits = useMemo(() => {
    if (!fetchedCourseData) return [];
    const credits = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Credits) credits.add(item.Credits);
    });
    return Array.from(credits).sort((a, b) => parseFloat(a) - parseFloat(b));
  }, [fetchedCourseData]);

  // Define suggestions for MultiSelect components
  const levelSuggestions = useMemo(
    () => uniqueLevels.map((level) => `${level}`),
    [uniqueLevels]
  );

  const majorSuggestions = useMemo(
    () => uniqueMajors,
    [uniqueMajors]
  );

  const creditsSuggestions = useMemo(
    () => uniqueCredits.map((credit) => `${credit} Credit(s)`),
    [uniqueCredits]
  );

  // Handle Reset Filters with feedback
  const handleReset = () => {
    handleResetFilters();
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <h5 className="text-lg text-center font-bold mb-4">Filters</h5>

      {/* Search Filter */}
      <div className="mb-4">
        <label
          htmlFor="searchQuery"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Search:
        </label>
        <input
          type="text"
          id="searchQuery"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          placeholder="Search by course name or number"
        />
      </div>

      {/* Level Filter */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Level:
        </label>
        {/* Debugging Log */}
        {console.log("Current levelFilter value:", levelFilter)}
        <MultiSelect
          suggestions={levelSuggestions}
          value={Array.isArray(levelFilter) ? levelFilter : []} // Ensure value is always an array
          onChange={(selected) => setLevelFilter(Array.isArray(selected) ? selected : [])}
          placeholder="Select Level(s)"
          required={false}
          unselectable={[]} // Add any unselectable items if needed
        />
      </div>

      {/* Major Filter */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Major:
        </label>
        {/* Debugging Log */}
        {console.log("Current majorFilter value:", majorFilter)}
        <MultiSelect
          suggestions={majorSuggestions}
          value={Array.isArray(majorFilter) ? majorFilter : []} // Ensure value is always an array
          onChange={(selected) => setMajorFilter(Array.isArray(selected) ? selected : [])}
          placeholder="Select Major(s)"
          required={false}
          unselectable={[]} // Add any unselectable items if needed
        />
      </div>

      {/* Credits Filter */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Credits:
        </label>
        {/* Debugging Log */}
        {console.log("Current creditsFilter value:", creditsFilter)}
        <MultiSelect
          suggestions={creditsSuggestions}
          value={Array.isArray(creditsFilter) ? creditsFilter : []} // Ensure value is always an array
          onChange={(selected) => setCreditsFilter(Array.isArray(selected) ? selected : [])}
          placeholder="Select Credit(s)"
          required={false}
          unselectable={[]} // Add any unselectable items if needed
        />
      </div>

      {/* Selected Filters Display */}
      {(Array.isArray(levelFilter) && levelFilter.length > 0 ||
        Array.isArray(majorFilter) && majorFilter.length > 0 ||
        Array.isArray(creditsFilter) && creditsFilter.length > 0) && (
        <div className="mb-4">
          <h6 className="text-sm font-medium text-gray-700 mb-1">
            Selected Filters:
          </h6>
          <div className="flex flex-wrap gap-1">
            {/* Level Filters */}
            {Array.isArray(levelFilter) && levelFilter.map((filter, index) => (
              <span
                key={`level-${index}`}
                id={`badge-dismiss-level-${index}`}
                className="inline-flex items-center px-2 py-1 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300"
              >
                {filter}
                <button
                  type="button"
                  className="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
                  onClick={() => {
                    setLevelFilter(levelFilter.filter((_, i) => i !== index));
                  }}
                  aria-label={`Remove Level ${filter}`}
                >
                  <svg
                    className="w-2 h-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Remove badge</span>
                </button>
              </span>
            ))}

            {/* Major Filters */}
            {Array.isArray(majorFilter) && majorFilter.map((filter, index) => (
              <span
                key={`major-${index}`}
                id={`badge-dismiss-major-${index}`}
                className="inline-flex items-center px-2 py-1 text-sm font-medium text-green-800 bg-green-100 rounded dark:bg-green-900 dark:text-green-300"
              >
                {filter}
                <button
                  type="button"
                  className="inline-flex items-center p-1 ms-2 text-sm text-green-400 bg-transparent rounded-sm hover:bg-green-200 hover:text-green-900 dark:hover:bg-green-800 dark:hover:text-green-300"
                  onClick={() => {
                    setMajorFilter(majorFilter.filter((_, i) => i !== index));
                  }}
                  aria-label={`Remove Major ${filter}`}
                >
                  <svg
                    className="w-2 h-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Remove badge</span>
                </button>
              </span>
            ))}

            {/* Credits Filters */}
            {Array.isArray(creditsFilter) && creditsFilter.map((filter, index) => (
              <span
                key={`credits-${index}`}
                id={`badge-dismiss-credits-${index}`}
                className="inline-flex items-center px-2 py-1 text-sm font-medium text-red-800 bg-red-100 rounded dark:bg-red-900 dark:text-red-300"
              >
                {filter}
                <button
                  type="button"
                  className="inline-flex items-center p-1 ms-2 text-sm text-red-400 bg-transparent rounded-sm hover:bg-red-200 hover:text-red-900 dark:hover:bg-red-800 dark:hover:text-red-300"
                  onClick={() => {
                    setCreditsFilter(creditsFilter.filter((_, i) => i !== index));
                  }}
                  aria-label={`Remove Credits ${filter}`}
                >
                  <svg
                    className="w-2 h-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Remove badge</span>
                </button>
              </span>
            ))}
          </div>
        </div>
      )}

      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mb-2"
        onClick={handleReset}
      >
        Reset Filters
      </button>

      {/* Courses Display Count */}
      <div className="flex justify-center mt-4">
        <span className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          {displayedCourseCount} Courses Displayed
        </span>
      </div>
    </div>
  );
};

export default FilterCard;
