// GroupsList.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { TextInput, Modal } from 'flowbite-react';
import AssignUsersModal from './AssignUsersModal';

const GroupsList = () => {
  const [staffGroups, setStaffGroups] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [faculties, setFaculties] = useState([]); // State to store faculty options
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);

  // Load faculties from local storage
  useEffect(() => {
    const storedFaculties = JSON.parse(localStorage.getItem('faculties'));
    if (storedFaculties && Array.isArray(storedFaculties)) {
      setFaculties(storedFaculties);
    } else {
      // Fallback list of faculties
      setFaculties([
        { Schools: 'Beedie School of Business' },
        { Schools: 'Faculty of Applied Sciences' },
        { Schools: 'Faculty of Arts and Social Sciences' },
        { Schools: 'Faculty of Communication, Art and Technology' },
        { Schools: 'Faculty of Education' },
        { Schools: 'Faculty of Environment' },
        { Schools: 'Faculty of Health Sciences' },
        { Schools: 'Faculty of Science' },
      ]);
    }
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const snapshot = await db.collection('groups').get();
        const groupsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStaffGroups(groupsData);
        setFilteredGroups(groupsData);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);

  const handleToggleCreateForm = () => setShowCreateForm(!showCreateForm);

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    if (!groupName || !department) return alert('Group Name and Department are required!');

    try {
      const newGroup = {
        name: groupName,
        description,
        department,
        members: [],
        createdAt: new Date(),
      };

      const docRef = await db.collection('groups').add(newGroup);
      const createdGroup = { id: docRef.id, ...newGroup };
      setStaffGroups([...staffGroups, createdGroup]);
      setFilteredGroups([...filteredGroups, createdGroup]);
      setGroupName('');
      setDescription('');
      setDepartment('');
      setShowCreateForm(false);
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const handleAssignUsers = (group) => {
    setSelectedGroup(group);
    setShowAssignModal(true);
  };

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredGroups(staffGroups);
    } else {
      setFilteredGroups(
        staffGroups.filter((group) =>
          group.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, staffGroups]);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="mb-4 flex justify-between items-center">
        {/* Search Input */}
        <TextInput
          id="search"
          type="search"
          placeholder="Search groups..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/3"
        />
        {/* Create New Group Button */}
        <button
          onClick={handleToggleCreateForm}
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
        >
          {showCreateForm ? 'Cancel' : 'Create New Group'}
        </button>
      </div>

      {/* Group Count Display */}
      <div className="bg-white rounded-lg shadow-md p-4 mb-4">
        <h3 className="text-lg font-medium">Total Groups: {filteredGroups.length}</h3>
      </div>

      {/* Create Group Form Modal */}
      {showCreateForm && (
        <Modal show={showCreateForm} onClose={handleToggleCreateForm}>
          <Modal.Header>Create New Group</Modal.Header>
          <Modal.Body>
            <form onSubmit={handleCreateGroup}>
              <div className="mb-4">
                <label htmlFor="groupName" className="block mb-2">Group Name:</label>
                <TextInput
                  type="text"
                  id="groupName"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="department" className="block mb-2">Department/School:</label>
                <select
                  id="department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg"
                >
                  <option value="">Select Department/School</option>
                  {faculties.map((faculty, index) => (
                    <option key={index} value={faculty.Schools}>
                      {faculty.Schools}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="description" className="block mb-2">Description:</label>
                <TextInput
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter group description"
                />
              </div>
              <button
                type="submit"
                className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
              >
                Create Group
              </button>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {/* Display Existing Groups */}
      <div className="grid grid-cols-1 gap-4 mt-6">
        {filteredGroups.map((group) => (
          <div key={group.id} className="p-4 border rounded-md shadow-sm bg-gray-50 dark:bg-gray-800">
            <h3 className="text-lg font-semibold mb-2">{group.name}</h3>
            <p className="text-gray-700 dark:text-gray-300">Department: {group.department}</p>
            <p className="text-gray-700 dark:text-gray-300">Description: {group.description || 'No description provided.'}</p>
            <p className="text-gray-700 dark:text-gray-300">Members: {group.members.length}</p>
            <button
              onClick={() => handleAssignUsers(group)}
              className="mt-4 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              Assign Users
            </button>
          </div>
        ))}
      </div>

      {/* Assign Users Modal */}
      {selectedGroup && (
        <AssignUsersModal
          show={showAssignModal}
          onClose={() => setShowAssignModal(false)}
          groupId={selectedGroup.id}
          existingMembers={selectedGroup.members}
        />
      )}
    </div>
  );
};

export default GroupsList;
