import React, { useState, useEffect, useRef, useCallback } from 'react';
import NavBar from '../NavBar';
import FinalizedCoursesTwo from './FinalizedCoursesTwo';
import { db, auth } from '../firebase';
import { getDatabase, ref, get } from 'firebase/database';
import '../Plan/SemesterCard.css';
import ElectiveList from './ElectiveList';
import Dropdown from 'react-bootstrap/Dropdown';
import ElectiveSidebar from './ElectiveSidebar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuth } from '../../contexts/AuthContext';
import './MajorPage.css';
import CourseRendering from './CourseRendering';
import MajorPageJoyrideTour from './MajorPageJoyrideTour';
import { FaGraduationCap } from 'react-icons/fa';
import { FaBookOpen, FaTimes } from 'react-icons/fa';
import { FaBookBookmark, FaBook, FaDownload } from 'react-icons/fa6';
import { toast } from 'react-toastify';

const MajorPage = () => {
  const [finalizedCourses, setFinalizedCourses] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [numYears, setNumYears] = useState(4);
  const [currentPathwayData, setCurrentPathwayData] = useState([]);
  const [selectedDegreeCourses, setSelectedDegreeCourses] = useState([]);
  const [middleschoolClasses, setMiddleschoolClasses] = useState([]);
  const [selectedMinorCourses, setSelectedMinorCourses] = useState([]);
  const [selectedMinor, setSelectedMinor] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDegreeModal, setShowDegreeModal] = useState(false);
  const [selectedDegree, setSelectedDegree] = useState('');
  const [selectedElCat, setSelectedElCat] = useState(null);
  const [faculties, setFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [transferPlansOnly, setTransferPlansOnly] = useState(false);
  const {
    fetchedCourseData,
    fetchedPrereqData,
    fetchedElective,
    pathwayData,
    tasks,
    fetchedMinors,
    fetchedMajors,
    fetchedMajorRecs,
    pathwayGroupingsData,
  } = useAuth();
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedMilestones, setCheckedMilestones] = useState({});
  const [shouldFetch, setShouldFetch] = useState(true);
  const [selectAllFall, setSelectAllFall] = useState(false);
  const [selectAllSpring, setSelectAllSpring] = useState(false);

  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);
  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedMajorRecData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedMajorRecData);
    }
  }, [fetchedMajorRecs]);

  const handleFallHeaderClick = () => {
    setSelectAllFall((prev) => !prev);
  };

  const handleSpringHeaderClick = () => {
    setSelectAllSpring((prev) => !prev);
  };

  const saveCourseDataToLocalStorage = (major, courseData) => {
    try {
      const courseDataJSON = JSON.stringify(courseData);
      localStorage.setItem(`courseData-${major}`, courseDataJSON);
    } catch (error) {
      console.error('Error saving course data to local storage:', error);
    }
  };

  const getCourseDataFromLocalStorage = (major) => {
    try {
      const courseDataJSON = localStorage.getItem(`courseData-${major}`);
      return courseDataJSON ? JSON.parse(courseDataJSON) : null;
    } catch (error) {
      console.error('Error retrieving course data from local storage:', error);
      return null;
    }
  };

  const fetchDegreesFromDatabase = async () => {
    try {
      const storedData = JSON.parse(localStorage.getItem('degreesAndFaculties'));
      if (storedData) {
        console.log('Data retrieved from local storage');
        return storedData;
      }

      const courseIds = [];
      const uniqueFaculties = new Set();

      const fetchCoursesFromSheet = async (projectAndCollectionId) => {
        const dbRef = ref(getDatabase(), projectAndCollectionId);
        const coursesSnapshot = await get(dbRef);
        coursesSnapshot.forEach((courseSnapshot) => {
          const courseData = courseSnapshot.val();
          courseIds.push({
            Major: courseData.pathwayName,
            Faculty: courseData.Faculty,
          });
          const faculty = courseData.Faculty.split('||');
          faculty.forEach((fac) => uniqueFaculties.add(fac));
        });
      };

      await fetchCoursesFromSheet(
        '16zznokkTgjsqu1rYYo5rD8dES40-9dq1guLOAYA0cqI/Pathway_Groupings'
      );

      const degreesAndFaculties = {
        courseIds,
        uniqueFaculties: Array.from(uniqueFaculties),
      };
      localStorage.setItem('degreesAndFaculties', JSON.stringify(degreesAndFaculties));

      return degreesAndFaculties;
    } catch (error) {
      console.error('Error fetching degrees and faculties from the Realtime Database:', error);
      return { courseIds: [], uniqueFaculties: [] };
    }
  };

  const [degs, setDegs] = useState([]);

  useEffect(() => {
    const fetchDegreeData = async () => {
      try {
        const { courseIds, uniqueFaculties } = await fetchDegreesFromDatabase();
        setDegs(courseIds);
        setFaculties(uniqueFaculties);
      } catch (error) {
        console.error('Degrees and faculties not found');
      }
    };
    fetchDegreeData();
  }, []);

  const handleFacultyFilterChange = (faculty) => {
    setSelectedFaculty(faculty);
    filterDegrees(searchQuery, faculty);
  };

  const [tabs, setTabs] = useState(() => {
    const storedTabs = localStorage.getItem('tabs');
    return storedTabs ? JSON.parse(storedTabs) : [];
  });

  const [selectedMajor1, setSelectedMajor] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabCourses, setActiveTabCourses] = useState([]);
  const [electiveData, setElectiveData] = useState([]);
  const [selectedElectiveCategory, setSelectedElectiveCategory] = useState(null);
  const [selectedSecondMajor, setSelectedSecondMajor] = useState([]);

  useEffect(() => {
    localStorage.setItem('tabs', JSON.stringify(tabs));
  }, [tabs]);

  const [filteredFetchedMajors, setFilteredFetchedMajors] = useState({});

  useEffect(() => {
    if (fetchedMajors) {
      setFilteredFetchedMajors(fetchedMajors);
    }
  }, [fetchedMajors]);

  const filterDegrees = (query, faculty) => {
    const filtered = Object.keys(fetchedMajors)
      .filter((major) => {
        const matchesQuery = major.toLowerCase().includes(query.toLowerCase());
        const matchesFaculty =
          !faculty || degs.some((degree) => degree.Major === major && degree.Faculty.includes(faculty));
        return matchesQuery && matchesFaculty;
      })
      .reduce((obj, key) => {
        obj[key] = fetchedMajors[key];
        return obj;
      }, {});

    setFilteredFetchedMajors(filtered);
  };

  const handleSearchQueryChange = (e) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
    filterDegrees(newQuery, selectedFaculty);
  };

  const [filteredMinors, setFilteredMinors] = useState({});
  useEffect(() => {
    if (fetchedMinors) {
      setFilteredMinors(fetchedMinors);
    }
  }, [fetchedMinors]);

  const filterMinors = (query) => {
    const filtered = Object.keys(fetchedMinors)
      .filter((major) => major.toLowerCase().includes(query.toLowerCase()))
      .reduce((obj, key) => {
        obj[key] = fetchedMinors[key];
        return obj;
      }, {});

    setFilteredMinors(filtered);
  };

  const handleDegreeSelection = async (degree) => {
    try {
      console.log('Selected Degree: ', degree.Major, degree);
      setTabs((prevTabs) => {
        const updatedTabs = [...prevTabs, degree.Major];
        setActiveTab(updatedTabs.length - 1);
        return updatedTabs;
      });
      setSelectedDegree(degree.Major);
      setShowDegreeModal(false);

      const courses = await fetchAllMajorsCourses(degree.Major);
      setActiveTabCourses(courses);
      const fetchedElectives = await fetchElectivesFromDatabase(degree.Major);
      setElectiveData(fetchedElectives);
    } catch (error) {
      console.error('Error fetching degree data:', error);
    }
  };

  const getUserKeptCourses = (finalizedSchedule) => {
    const keptCourses = [];
    for (const year in finalizedSchedule) {
      for (const sem in finalizedSchedule[year]) {
        if (finalizedSchedule[year][sem].courses) {
          keptCourses.push(...finalizedSchedule[year][sem].courses);
        }
      }
    }
    return keptCourses;
  };

  const getUserKeptMilestones = (finalizedMilestones) => {
    const keptMilestones = [];
    for (const year in finalizedMilestones) {
      for (const semester in finalizedMilestones[year]) {
        const semesterData = finalizedMilestones[year][semester];
        if (semesterData.milestones) {
          semesterData.milestones.forEach((milestone) => {
            keptMilestones.push(milestone.Description);
          });
        }
      }
    }
    return keptMilestones;
  };

  const fetchCoursesFromDatabase = async (major) => {
    try {
      const courseDataFromLocalStorage = getCourseDataFromLocalStorage(major);
      if (courseDataFromLocalStorage) {
        return courseDataFromLocalStorage;
      }

      const courseIds = [];
      const electives = {};
      const fetchCoursesFromSheet = async (projectAndCollectionId) => {
        const dbRef = ref(getDatabase(), projectAndCollectionId);
        const coursesSnapshot = await get(dbRef);
        coursesSnapshot.forEach((courseSnapshot) => {
          const courseData = courseSnapshot.val();

          const findCredits = (course) => {
            if (course) {
              const match = fetchedCourseData.find(
                (fetchedCourse) =>
                  fetchedCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
              );
              return match ? match.Credits : null;
            }
          };

          const findName = (course) => {
            if (course) {
              if (course.Name) {
                return course.Name;
              }
              const match = fetchedCourseData.find(
                (fetchedCourse) =>
                  fetchedCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
              );
              return match ? match.courseInfo_courseName : null;
            }
          };

          const findDescription = (course) => {
            if (course) {
              const match = fetchedCourseData.find(
                (fetchedCourse) =>
                  fetchedCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
              );
              return match ? match.Description : null;
            }
          };

          courseIds.push({
            CourseSem: courseData.CourseSem,
            Credits: findCredits(courseData),
            cal_name: courseData.cal_name,
            courseCat: courseData.courseCat,
            courseInfo_courseName: findName(courseData),
            courseInfo_courseNumber: courseData.courseInfo_courseNumber,
            courseYear: courseData.courseYear,
            max_capacity: courseData.max_capacity,
            sectionInfo_sectionNumber: courseData.sectionInfo_sectionNumber,
            sectionInfo_teacherDisplay: courseData.sectionInfo_teacherDisplay,
            isAutoFill: courseData.isAutoFill,
            isPrereqMet: true,
            Prerequisites: '',
            Corequisites: '',
            Instructor: courseData.Instructor,
            Major: courseData.Major,
            Possibilities: courseData.Possibilities,
            Description: findDescription(courseData),
            Requirements: courseData.Requirements,
            TransferPlan: courseData.TransferPlan,
            Name: courseData.Name,
            courseType: courseData.courseType,
            degreeName: major,
          });
        });
      };
      await fetchCoursesFromSheet(`1D2GTM7xtDQT7Uc-gZf_5hBSvfsOAErXtGeL37Tn21fQ/${major}`);

      saveCourseDataToLocalStorage(major, courseIds);
      return courseIds;
    } catch (error) {
      console.error('Error fetching course IDs from the Realtime Database:', error);
      return [];
    }
  };

  const [milestones, setMilestones] = useState([]);
  const [completedMilestones, setCompletedMilestones] = useState([]);

  const handleSecondMajorSelection = async (degree) => {
    try {
      if (degree) {
        if (selectedSecondMajor.includes(degree.Major)) {
          console.warn('This major has already been selected.');
          return;
        }

        let secondMajorCourses = await fetchCoursesFromDatabase(degree.Major);

        secondMajorCourses = secondMajorCourses.map((course) => ({
          ...course,
          degreeName: degree.Major,
        }));

        setSelectedDegreeCourses((prevCourses) => {
          const newCourses = secondMajorCourses.filter(
            (newCourse) =>
              !prevCourses.some(
                (prevCourse) => prevCourse.courseInfo_courseNumber === newCourse.courseInfo_courseNumber
              )
          );
          return [...prevCourses, ...newCourses];
        });

        setSelectedSecondMajor((prevMajors) => [...prevMajors, degree.Major]);
      } else {
        setSelectedSecondMajor([]);
      }
    } catch (error) {
      console.error('Error fetching second major courses:', error);
    }
  };

  const handleRemoveSecondMajor = (majorName) => {
    setSelectedSecondMajor((prevMajors) => prevMajors.filter((major) => major !== majorName));

    setSelectedDegreeCourses((prevCourses) =>
      prevCourses.filter((course) => course.degreeName !== majorName)
    );
  };

  const fetchElectivesFromDatabase = async (electiveMj) => {
    try {
      const allElectives = [];
      const fetchedEl = fetchedElective.filter((elective) => electiveMj.includes(elective.Major));
      allElectives.push({
        Major: fetchedEl[0].Major,
        Y1F: fetchedEl[0]['Year 1 Fall'],
        Y1S: fetchedEl[0]['Year 1 Spring'],
        Y2F: fetchedEl[0]['Year 2 Fall'],
        Y2S: fetchedEl[0]['Year 2 Spring'],
        Y3F: fetchedEl[0]['Year 3 Fall'],
        Y3S: fetchedEl[0]['Year 3 Spring'],
        Y4F: fetchedEl[0]['Year 4 Fall'],
        Y4S: fetchedEl[0]['Year 4 Spring'],
      });
      return allElectives;
    } catch (error) {
      console.error('Error fetching electives from the Realtime Database:', error);
      return [];
    }
  };

  const fetchMilestonesFromDatabase = async (major) => {
    try {
      const storedMilestones = localStorage.getItem('milestones');
      if (storedMilestones) {
        const parsedMilestones = JSON.parse(storedMilestones);
        const filteredMilestones = parsedMilestones.filter((milestone) => major.includes(milestone.Major));
        return filteredMilestones;
      }

      const projectAndCollectionId = '1U2CarXeOMX2zCAUFSDnO1ndxuE3tPDYfY3EOOqH7s_M/Milestones';
      const dbRef = ref(getDatabase(), projectAndCollectionId);
      const electivesSnapshot = await get(dbRef);
      const allMilestones = [];
      electivesSnapshot.forEach((electivesSnapshot) => {
        const elData = electivesSnapshot.val();
        allMilestones.push({
          Major: elData.Major,
          Y1F: elData['Y1F'],
          Y1S: elData['Y1S'],
          Y2F: elData['Y2F'],
          Y2S: elData['Y2S'],
          Y3F: elData['Y3F'],
          Y3S: elData['Y3S'],
          Y4F: elData['Y4F'],
          Y4S: elData['Y4S'],
        });
      });

      localStorage.setItem('milestones', JSON.stringify(allMilestones));

      const fetchedEl = allMilestones.filter((milestone) => major.includes(milestone.Major));
      return fetchedEl;
    } catch (error) {
      console.error('Error fetching milestones from the Realtime Database:', error);
      return [];
    }
  };
  const [finalizedMilestones, setFinalizedMilestones] = useState([]);
  const [milestonesByYearAndSemester, setMilestonesByYearAndSemester] = useState([]);

  const handleSaveMilestone = async (title, description, yearNum, semesterNum) => {
    if (!title || !description) return;

    const newMilestone = {
      Milestone_ID: `milestone_${Date.now()}`,
      Title: title,
      Description: description,
      DueDate: '12-12-2025',
      Milestone_Year: yearNum,
      Milestone_Sem: semesterNum === 'Fall' ? 'S1' : 'S2',
      createdAt: new Date().toISOString(),
      completed: false,
    };

    try {
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const year = `year${yearNum}`;
            const semester = semesterNum === 'Fall' ? 'S1' : 'S2';
            const updatedMilestones = [
              ...(userData.finalizedMilestones[year]?.[semester]?.milestones || []),
              newMilestone,
            ];

            const milestonesByYear = {
              ...userData.finalizedMilestones,
              [year]: {
                ...userData.finalizedMilestones[year],
                [semester]: { milestones: updatedMilestones },
              },
            };

            transaction.update(userDocRef, { finalizedMilestones: milestonesByYear });

            setFinalizedMilestones((prev) => [...prev, newMilestone]);
            setMilestonesByYearAndSemester((prev) => ({
              ...prev,
              [year]: {
                ...prev[year],
                [semester]: updatedMilestones,
              },
            }));
          }
        });
      }
    } catch (error) {
      console.error('Error saving milestone:', error);
    }
  };
  const [checkboxState, setCheckboxState] = useState(false);

  const handleCheckboxChange = async (milestoneTitle, year, semester) => {
    try {
      handleSaveMilestone(milestoneTitle, milestoneTitle, year, semester);
      setCheckboxState(false);
    } catch (error) {
      console.error('Error adding milestone to Firestore:', error);
    }
  };

  const renderMilestones = (yearNumber, semester, milestones) => {
    const semesterKey = semester === 'Fall' ? 'F' : 'S';
    const milestoneKey = `Y${yearNumber}${semesterKey}`;
    const key = `${yearNumber}-${semester}`;

    const milestonesForSemester = milestones
      .filter((milestone) => selectedMajor1.includes(milestone.Major))
      .map((milestone) => milestone[milestoneKey])
      .filter(Boolean);

    return (
      <>
        {milestonesForSemester.map((milestoneText, index) => {
          const isCompleted = completedMilestones.includes(milestoneText);

          return (
            <div
              key={`${milestoneText}-${index}`}
              className={`card ${isCompleted ? 'bg-gray-300 border-black' : ''}`}
            >
              <div className="flex items-center justify-between p-2">
                <div className="course-number mr-4">
                  <strong>Milestone</strong>
                </div>
                <div className="course-name-text text-center">
                  <span className={isCompleted ? 'text-black' : ''}>{milestoneText}</span>
                </div>
                <div className="ml-4 flex items-center">
                  <input
                    type="checkbox"
                    checked={isCompleted}
                    disabled={isCompleted}
                    onChange={() => handleCheckboxChange(milestoneText, yearNumber, semester)}
                    className="form-checkbox h-4 w-4 text-blue-600 rounded-full focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const organizePrereqCourses = () => {
    const prereqCourses = {};
    for (let i = 1; i <= 4; i++) {
      if (!prereqCourses[i]) {
        prereqCourses[i] = [];
      }
      prereqCourses[i].push(...middleschoolClasses);

      activeTabCourses.forEach((course) => {
        const year = course.courseYear || 'Uncategorized';
        if (year < i) {
          prereqCourses[i].push(course.courseInfo_courseNumber);
        }
      });
    }

    return prereqCourses;
  };

  const prereqClasses = organizePrereqCourses();

  const getYearClassName = (yearNumber, semester) => {
    if (!surveyAnswers?.SchoolStart?.year) return '';

    const startYear = Number(surveyAnswers.SchoolStart.year);

    const calculatedYear = startYear + yearNumber - 1;
    const semesterName = semester === 'Fall' ? 'Fall Semester' : 'Spring Semester';

    const displayYear = semester === 'Spring' ? calculatedYear + 1 : calculatedYear;

    return `${displayYear} ${semesterName}`;
  };

  const getTotalCreditsForSemester = (courses, yearNumber, semester) => {
    if (!courses) {
      return 0;
    }

    let totalCredits = courses.reduce((total, course) => total + course.Credits, 0);

    return totalCredits;
  };

  const handleKeepCourse = async (coursesToKeep, yr, sem) => {
    try {
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const previousFinalizedSchedule = userData.schedules[activeSchedule] || {};
            const completedSemesters = userData.completedSemesters || {}; // Fetch completed semesters data
  
            const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
  
            let yearKey = `year${yr}`;
            let semesterKey = `S${sem}`;
  
            // Helper function to find the earliest incomplete semester
            const findEarliestIncompleteSemester = () => {
              for (let year = yr; year <= Object.keys(updateObj).length; year++) {
                const currentYearKey = `year${year}`;
                for (const semester of ['S1', 'S2']) {
                  // Check if the semester is completed
                  if (
                    !completedSemesters[currentYearKey] ||
                    !completedSemesters[currentYearKey][semester]
                  ) {
                    return { targetYear: currentYearKey, targetSemester: semester };
                  }
                }
              }
              return null; // If no available semesters
            };
  
            // Function to format year and semester for better readability
            const formatSemesterName = (year, semester) => {
              const semesterName = semester === 'S1' ? 'Fall Semester' : 'Spring Semester';
              const yearName = year.replace('year', 'Year ');
              return `${semesterName} of ${yearName}`;
            };
  
            // Check if the current semester is completed
            if (completedSemesters[yearKey] && completedSemesters[yearKey][semesterKey]) {
              const earliestSemester = findEarliestIncompleteSemester();
  
              if (earliestSemester) {
                // Update year and semester to the next available
                const { targetYear, targetSemester } = earliestSemester;
  
                // Trigger a toast notification on the Major Page with improved readability
                toast.info(
                  `Cannot add course to ${formatSemesterName(
                    yearKey,
                    semesterKey
                  )} as it is marked as completed. The course has been added to ${formatSemesterName(
                    targetYear,
                    targetSemester
                  )}.`,
                  { position: 'top-right', autoClose: 5000 }
                );
  
                // Set the year and semester to the next available
                yearKey = targetYear;
                semesterKey = targetSemester;
              } else {
                // No available semesters to add the course
                toast.error(
                  `No available semesters to add the course. Please review your schedule.`,
                  { position: 'top-right', autoClose: 5000 }
                );
                return; // Exit the function
              }
            }
  
            // Prepare the courses to add, mapping according to courseType
            const coursesToAdd = coursesToKeep.map((course) => {
              if (course.courseType === 'Elective' || course.courseType === 'Requirement') {
                return course.Requirements;
              } else {
                return course.courseInfo_courseNumber;
              }
            });
  
            // Update the schedule with the courses
            const updatedFinalizedSchedule = {
              ...updateObj,
              [yearKey]: {
                ...updateObj[yearKey],
                [semesterKey]: {
                  ...(updateObj[yearKey] && updateObj[yearKey][semesterKey]
                    ? updateObj[yearKey][semesterKey]
                    : {}),
                  courses:
                    updateObj[yearKey] &&
                    updateObj[yearKey][semesterKey] &&
                    updateObj[yearKey][semesterKey].courses
                      ? updateObj[yearKey][semesterKey].courses.concat(coursesToAdd)
                      : coursesToAdd,
                },
              },
            };
  
            // Update Firestore with the modified schedule
            transaction.update(userDocRef, {
              [`schedules.${activeSchedule}`]: updatedFinalizedSchedule,
            });
  
            // Update the active tab courses state
            const updatedCourses = activeTabCourses.map((c) => {
              const courseIdentifier =
                c.courseType === 'Elective' || c.courseType === 'Requirement'
                  ? c.Requirements
                  : c.courseInfo_courseNumber;
              if (coursesToAdd.includes(courseIdentifier)) {
                return {
                  ...c,
                  isKept: true,
                };
              }
              return c;
            });
            setActiveTabCourses(updatedCourses);
          } else {
            console.error('User data not found for the given userId:', currentUser.uid);
            throw new Error('User data not found');
          }
        });
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error keeping courses:', error);
    }
  };
  

  useEffect(() => {
    if (tabs.length > 0 && tabs[activeTab]) {
      const activeTabMajors = tabs[activeTab].split(' / ').map((major) => major.trim());
      setSelectedMajor(activeTabMajors);
    }
  }, [auth.currentUser, activeTab, tabs]);

  const [selectedCourses, setSelectedCourses] = useState([]);

  const onCourseSelection = (course, isSelected) => {
    setSelectedCourses((prevSelectedCourses) => {
      if (isSelected) {
        if (!prevSelectedCourses.some((c) => c.courseInfo_courseNumber === course.courseInfo_courseNumber)) {
          return [...prevSelectedCourses, course];
        }
      } else {
        return prevSelectedCourses.filter((c) => c.courseInfo_courseNumber !== course.courseInfo_courseNumber);
      }
      return prevSelectedCourses;
    });
  };

  const fetchMinorCoursesFromDatabase = async (minorName) => {
    try {
      const minor = fetchedMinors[minorName];

      if (!minor || typeof minor !== 'object') {
        console.log('Minor not found or invalid');
        return [];
      }

      const findCredits = (course) => {
        if (course) {
          const match = fetchedCourseData.find(
            (fetchedCourse) => fetchedCourse.courseInfo_courseNumber === course
          );
          return match ? match.Credits : null;
        }
      };

      const findName = (course) => {
        if (course) {
          if (course.Name) {
            return course.Name;
          }
          const match = fetchedCourseData.find(
            (fetchedCourse) => fetchedCourse.courseInfo_courseNumber === course
          );
          return match ? match.courseInfo_courseName : null;
        }
      };

      const findDescription = (course) => {
        if (course) {
          const match = fetchedCourseData.find(
            (fetchedCourse) => fetchedCourse.courseInfo_courseNumber === course.courseInfo_courseNumber
          );

          console.log('Name match: ', match);
          return match ? match.Description : null;
        }
      };

      let arr = Object.entries(minor).map(([courseNumber, courseData]) => ({
        courseInfo_courseNumber: courseNumber,
        courseYear: courseData.courseYear || '',
        CourseSem: courseData.CourseSem || '',
        courseInfo_courseName: findName(courseNumber),
        Credits: findCredits(courseNumber),
        Requirements: courseData.Requirements,
        Possibilities: courseData.Possibilities,
        Description: findDescription(courseData),
      }));
      return arr;
    } catch (error) {
      console.error('Error fetching minor course IDs from the Realtime Database:', error);
      return [];
    }
  };

  const handleMinorSelection = async (minorName) => {
    try {
      if (minorName) {
        if (selectedMinor.includes(minorName)) {
          console.warn('This minor has already been selected.');
          return;
        }

        let minorCourses = await fetchMinorCoursesFromDatabase(minorName);

        minorCourses = minorCourses.map((course) => ({
          ...course,
          minorName: minorName,
        }));

        setSelectedMinorCourses((prevCourses) => {
          const newCourses = minorCourses.filter(
            (newCourse) =>
              !prevCourses.some(
                (prevCourse) => prevCourse.courseInfo_courseNumber === newCourse.courseInfo_courseNumber
              )
          );
          return [...prevCourses, ...newCourses];
        });

        setSelectedMinor((prevMinors) => [...(prevMinors || []), minorName]);
      } else {
        setSelectedMinorCourses([]);
        setSelectedMinor(null);
      }
    } catch (error) {
      console.error('Error fetching minor courses:', error);
    }
  };

  const handleRemoveMinor = (minorName) => {
    setSelectedMinor((prevMinors) => prevMinors.filter((minor) => minor !== minorName));

    setSelectedMinorCourses((prevCourses) =>
      prevCourses.filter((course) => course.minorName !== minorName)
    );
  };

  const SelectedMinorBox = ({ minorName, onRemove }) => (
    <div className="bg-yellow-100 bg-opacity-50 text-yellow-500 font-semibold rounded-lg text-sm px-2 py-1 mr-2 flex items-center">
      {minorName}
      <button className="ml-2 text-yellow-500 hover:text-yellow-900" onClick={onRemove}>
        <FaTimes />
      </button>
    </div>
  );

  const SelectedSecondMajorBox = ({ majorName, onRemove }) => (
    <div className="bg-green-100 bg-opacity-50 text-green-500 font-semibold rounded-lg text-sm px-2 py-1 mr-2 flex items-center">
      {majorName}
      <button className="ml-2 !text-green-500 hover:text-green-900" onClick={onRemove}>
        <FaTimes />
      </button>
    </div>
  );

  const [electiveYear, setElectiveYear] = useState([]);
  const [electiveSemester, setElectiveSemester] = useState([]);
  const [electivePossibilities, setElectivePossibilities] = useState([]);
  const [electiveRequirement, setElectiveRequirement] = useState([]);

  const handleElectiveClick = (category, year, semester, requirement) => {
    console.log('Year, semester', year, semester);
    setSelectedElectiveCategory(category);
    setElectiveYear(year);
    setElectiveSemester(semester);
    setElectiveRequirement(requirement);
    setShowSidebar(true);
  };
  const [activeSchedule, setActiveSchedule] = useState([]);

  const fetchAllMajorsCourses = async (majors) => {
    try {
      console.log('Majors: ', majors);

      const majorsArray = Array.isArray(majors) ? majors : [majors];

      const allCourses = [];

      for (const major of majorsArray) {
        const courses = await fetchCoursesFromDatabase(major);
        allCourses.push(...courses);
      }

      console.log('All Courses: ', allCourses);
      return allCourses;
    } catch (error) {
      console.log('MajorsError: ', majors);
      console.error('Error fetching courses for all majors:', error);
      return [];
    }
  };
  const [credits, setCredits] = useState(0);
  const [upperCredits, setUpperCredits] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        setCurrentUser(user);
        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeSchedule = userData.activeTab;
            setActiveSchedule(activeSchedule);
            const numYearsFromDB =
              userData?.surveyAnswers?.SchoolEnd?.year - userData?.surveyAnswers?.SchoolStart?.year ||
              4;
            setNumYears(numYearsFromDB);

            const majorPathwayData = pathwayGroupingsData.filter(
              (major) => major.pathwayType === 'Major'
            );

            // Fetch pathway data for selected majors
            const userMajorPathwayDataArray = majorPathwayData.filter((major) =>
              selectedMajor1.includes(major.pathwayName)
            );

            // Sum credits
            const totalCredits = userMajorPathwayDataArray.reduce(
              (sum, majorData) => sum + (parseInt(majorData.OverallCR, 10) || 0),
              0
            );
            const totalUpperCredits = userMajorPathwayDataArray.reduce(
              (sum, majorData) => sum + (parseInt(majorData.UDCR, 10) || 0),
              0
            );

            setCredits(totalCredits);
            setUpperCredits(totalUpperCredits);

            console.log('User pathway data: ', userMajorPathwayDataArray);
            const savedMilestones = userData.finalizedMilestones || {};
            setCheckedMilestones(savedMilestones);

            if (!userData.finalizedMilestones) {
              let defaultMilestones = {};
              for (let i = 1; i <= numYears; i++) {
                defaultMilestones[`year${i}`] = {
                  S1: { milestones: [] },
                  S2: { milestones: [] },
                };
                await userDocRef.update({ finalizedMilestones: defaultMilestones });
                userData.finalizedMilestones = defaultMilestones;
              }
            }

            const backendSurveyAnswers = userData.surveyAnswers || [];
            setSurveyAnswers(backendSurveyAnswers);

            if (tabs.length === 0) {
              const userMajors = userData.surveyAnswers.Majors;
              if (Array.isArray(userMajors)) {
                const tabName = userMajors.join(' / ');
                setTabs([tabName]);
                setSelectedMajor(userMajors);
              }
            }

            const keptCourseNumbers = getUserKeptCourses(userData.schedules[activeSchedule]);
            const keptMilestoneNumbers = getUserKeptMilestones(userData.finalizedMilestones);
            setCompletedMilestones(keptMilestoneNumbers);
            const courses = await fetchAllMajorsCourses(selectedMajor1);
            console.log('TotDat: ', courses, selectedMajor1);
            const milestones = await fetchMilestonesFromDatabase(selectedMajor1);
            setMilestones(milestones);
            const fetchedElectives = await fetchElectivesFromDatabase(selectedMajor1);
            setElectiveData(fetchedElectives);
            setFinalizedCourses([]);

            const filteredMinorCourses = selectedMinorCourses.filter(
              (minorCourse) =>
                !courses.some(
                  (course) => course.courseInfo_courseNumber === minorCourse.courseInfo_courseNumber
                )
            );
            const filteredDegreeCourses = selectedDegreeCourses.filter(
              (degreeCourse) =>
                !courses.some(
                  (course) => course.courseInfo_courseNumber === degreeCourse.courseInfo_courseNumber
                )
            );

            const allCourses = courses.concat(filteredMinorCourses);
            const allCoursesWithMajor = allCourses.concat(filteredDegreeCourses);

            const coursesWithKeptStatus = allCoursesWithMajor.map((course) => ({
              ...course,
              isKept: keptCourseNumbers.includes(course.courseInfo_courseNumber),
            }));
            console.log('Active Tab Courses: ', activeTabCourses);

            setFinalizedCourses(coursesWithKeptStatus);
            setActiveTabCourses(coursesWithKeptStatus);
            console.log('Active tab courses: ', courses, allCourses, activeTabCourses);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setLoading(false);
    };
    fetchUserData();
  }, [
    selectedMajor1,
    fetchedCourseData,
    fetchedElective,
    finalizedMilestones,
    activeTab,
    tabs,
    selectedMinor,
    selectedMinorCourses,
    selectedSecondMajor,
    pathwayGroupingsData,
  ]);

  const handleTabClick = useCallback((index) => {
    setActiveTab(index);
  }, []);  

  const handleRemoveTab = async (indexToRemove) => {
    const updatedTabs = tabs.filter((_, index) => index !== indexToRemove);

    let newActiveTab = activeTab;
    if (indexToRemove === activeTab) {
      if (indexToRemove === tabs.length - 1) {
        newActiveTab = indexToRemove - 1;
        handleTabClick(newActiveTab);
      } else {
        newActiveTab = indexToRemove;
        handleTabClick(newActiveTab);
      }
    } else if (indexToRemove < activeTab) {
      newActiveTab = activeTab - 1;
      handleTabClick(newActiveTab);
    }

    setTabs(updatedTabs);
    setSelectedMajor(updatedTabs[newActiveTab]);

    if (updatedTabs.length > 0) {
      const newCourses = await fetchCoursesFromDatabase(updatedTabs[newActiveTab]);
      setActiveTabCourses(Array.isArray(newCourses) ? newCourses : []);
      handleTabClick(newActiveTab);
    }
  };

  useEffect(() => {
    if (tabs.length > 0 && typeof tabs[activeTab] !== 'undefined') {
      const activeTabMajors = tabs[activeTab].split(' / ').map((major) => major.trim());
      setSelectedMajor(activeTabMajors);

      fetchCoursesFromDatabase(activeTabMajors)
        .then((newCourses) => {
          setActiveTabCourses(Array.isArray(newCourses) ? newCourses : []);
        })
        .catch((error) => {
          console.error('Error fetching courses:', error);
        });
    }
  }, [tabs, activeTab]);

  const [selectedElectiveCourses, setSelectedElectiveCourses] = useState([]);

  const handleKeepElectiveCourse = async (course, yr, sem) => {
    try {
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const previousFinalizedSchedule = userDoc.data().schedules[activeSchedule] || {};

            const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
            const updatedFinalizedSchedule = {
              ...updateObj,
              [`year${yr}`]: {
                ...updateObj[`year${yr}`],
                [`S${sem}`]: {
                  ...(updateObj[`year${yr}`] && updateObj[`year${yr}`][`S${sem}`]
                    ? updateObj[`year${yr}`][`S${sem}`]
                    : {}),
                  courses:
                    updateObj[`year${yr}`] &&
                    updateObj[`year${yr}`][`S${sem}`] &&
                    updateObj[`year${yr}`][`S${sem}`].courses
                      ? updateObj[`year${yr}`][`S${sem}`].courses.concat([course.courseInfo_courseNumber])
                      : [course.courseInfo_courseNumber],
                },
              },
            };

            transaction.update(userDocRef, {
              [`schedules.${activeSchedule}`]: updatedFinalizedSchedule,
            });
          } else {
            console.error('User data not found for the given userId:', currentUser.uid);
            throw new Error('User data not found');
          }
        });

        const updatedCourses = activeTabCourses.map((c) => {
          if (c.courseInfo_courseNumber === course.courseInfo_courseNumber) {
            return {
              ...c,
              isKept: true,
            };
          }
          return c;
        });
        setActiveTabCourses(updatedCourses);
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error keeping elective course:', error);
    }
  };

  const SelectedElectiveCourses = ({ selectedElectiveCourses, onKeepCourse }) => {
    return (
      <div>
        {selectedElectiveCourses.map((course) => (
          <div key={course.courseInfo_courseNumber} className="elective-course-card">
            <p>
              <strong>{course.courseInfo_courseNumber}</strong>
            </p>
            <p>{course.courseInfo_courseName}</p>
            <p>Credits: {course.Credits}</p>
            <button className="btn btn-primary m-2" onClick={() => onKeepCourse(course, 1, 1)}>
              Keep Course
            </button>
          </div>
        ))}
      </div>
    );
  };

  const closeModal = () => {
    setShowDegreeModal(false);
    setSearchQuery('');
    setSelectedFaculty(null);
    setFilteredFetchedMajors(fetchedMajors);
  };

  const closeModal1 = () => {
    setShowDegreeModal1(false);
    setSearchQuery('');
    setSelectedFaculty(null);
    setFilteredFetchedMajors(fetchedMajors);
  };

  const closeModal2 = () => {
    setShowDegreeModal2(false);
    setSearchQuery('');
    setFilteredMinors(fetchedMinors);
  };

  const [yearsToRender, setYearsToRender] = useState(4);
  const [showDegreeModal1, setShowDegreeModal1] = useState(false);
  const [showDegreeModal2, setShowDegreeModal2] = useState(false);

  const modalRef = useRef();
  const modalRef1 = useRef();
  const modalRef2 = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowDegreeModal(false);
    }
    if (modalRef1.current && !modalRef1.current.contains(event.target)) {
      setShowDegreeModal1(false);
    }
    if (modalRef2.current && !modalRef2.current.contains(event.target)) {
      setShowDegreeModal2(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  console.log('Filtered Minors: ', filteredMinors);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="semester-card-container">
        <NavBar />
        <MajorPageJoyrideTour milestones={milestones} />
        {currentUser ? (
          <div className="semester-card-main-content flex">
            {showSidebar && (
              <ElectiveSidebar
                onClose={() => setShowSidebar(false)}
                showSidebar={showSidebar}
                elective={selectedElectiveCategory}
                electiveYear={electiveYear}
                electiveSemester={electiveSemester}
                courses={fetchedCourseData}
                onKeepElectiveCourse={handleKeepElectiveCourse}
                majorRecData={transformedMajorRecData}
                requirement={electiveRequirement}
              />
            )}
            <SelectedElectiveCourses
              selectedElectiveCourses={selectedElectiveCourses}
              onKeepCourse={handleKeepElectiveCourse}
            />
            <div className="flex-1 flex flex-col space-y-2">
              {/* Tabs Section */}
              {fetchedCourseData && filteredFetchedMajors && (
                <ul className="flex flex-wrap border-b mb-4 left-10">
                  {tabs.map((tab, index) => (
                    <li key={index} className="mr-1">
                      <div className="flex items-center">
                        <button
                          className={`inline-block py-2 px-3 border-b-2 rounded-t-lg flex items-center ${
                            activeTab === index
                              ? 'border-blue-500 text-blue-500'
                              : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                          }`}
                          onClick={() => handleTabClick(index)}
                        >
                          {tab}
                          {tabs.length > 1 && (
                            <button
                              className="ml-2 text-black hover:text-red-700"
                              onClick={() => handleRemoveTab(index)}
                            >
                              &times;
                            </button>
                          )}
                        </button>
                      </div>
                    </li>
                  ))}
                  <button
                    onClick={() => setShowDegreeModal(true)}
                    className="text-black hover:text-gray-700 text-lg p-0 ml-2 flex items-center"
                  >
                    +
                  </button>
                </ul>
              )}

              {/* Credits Overview and Add Major/Minor Buttons */}
              <div className="flex items-center justify-between text-sm font-semibold px-4 mb-1">
                <div className="flex space-x-4">
                  {/* Total Credits */}
                  <div className="flex items-center bg-blue-100 bg-opacity-50 rounded px-2 py-1 text-blue-700">
                    <FaBook className="mr-2" />
                    <span>Total Credits:</span>
                    <span className="ml-1 text-blue-800">{credits || 'N/A'}</span>
                  </div>

                  {/* Upper Division Credits */}
                  <div className="flex items-center bg-green-100 bg-opacity-50 rounded px-2 py-1 text-green-700">
                    <FaBookBookmark className="mr-2" />
                    <span>Upper Division Credits:</span>
                    <span className="ml-1 text-green-800">{upperCredits || 'N/A'}</span>
                  </div>
                </div>

                {/* Add Major and Add Minor Buttons */}
                <div className="flex items-center space-x-1">
                  <button
                    type="button"
                    onClick={() => setShowDegreeModal1(true)}
                    className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                  >
                    <FaGraduationCap className="w-3 h-3 me-2" />
                    Add major(s)
                  </button>
                  {fetchedMinors && (
                    <button
                      type="button"
                      onClick={() => setShowDegreeModal2(true)}
                      className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
                    >
                      <FaBookOpen className="w-3 h-3 me-2" />
                      Add minor(s)
                    </button>
                  )}
                </div>
              </div>

              {/* Display Selected Minors and Second Majors */}
              <div className="flex flex-wrap px-4 mb-1">
                {selectedSecondMajor.map((majorName) => (
                  <SelectedSecondMajorBox
                    key={majorName}
                    majorName={majorName}
                    onRemove={() => handleRemoveSecondMajor(majorName)}
                  />
                ))}
                {selectedMinor.map((minorName) => (
                  <SelectedMinorBox
                    key={minorName}
                    minorName={minorName}
                    onRemove={() => handleRemoveMinor(minorName)}
                  />
                ))}
              </div>

              {/* Semester Cards */}
              {[1, 2, 3, 4].map((yearNumber) => (
                <div
                  key={yearNumber}
                  className="grid grid-cols-2 gap-x-2 gap-y-1 w-full"
                >
                  {/* Fall Semester Card */}
                  <div className="semester-section border rounded-lg p-3 bg-white shadow-md space-y-1">
                    <div className="flex justify-between items-center mb-0.5 ml-2">
                      <h3 className="text-lg font-medium">
                        {getYearClassName(yearNumber, 'Fall')}
                      </h3>
                      <span className="bg-purple-100 text-purple-800 text-xs font-medium px-2 py-1 rounded border border-purple-400">
                        {getTotalCreditsForSemester(
                          activeTabCourses.filter(
                            (course) =>
                              course.CourseSem === 'S1' && course.courseYear === yearNumber
                          ),
                          yearNumber,
                          'Fall'
                        )}{' '}
                        Credits
                      </span>
                    </div>
                    <FinalizedCoursesTwo
                      finalizedCourses={activeTabCourses.filter(
                        (course) => course.CourseSem === 'S1' && course.courseYear === yearNumber
                      )}
                      prereqClasses={prereqClasses}
                      semester={1}
                      yearNumber={yearNumber}
                      onCourseSelection={(courseId, isSelected) =>
                        onCourseSelection(courseId, isSelected, yearNumber, 'Fall')
                      }
                      onKeepCourse={handleKeepCourse}
                      electiveData={electiveData}
                      onElectiveClick={handleElectiveClick}
                      tabs={tabs}
                      activeTab={activeTab}
                      selectedMajor1={selectedMajor1}
                      selectedElectiveCategory={selectedElCat}
                      majorRecData={transformedMajorRecData}
                      finalizedCourses1={activeTabCourses}
                      possibilities={electivePossibilities}
                    />
                    <div className="milestones-container-fall">
                      {renderMilestones(yearNumber, 'Fall', milestones)}
                    </div>
                  </div>

                  {/* Spring Semester Card */}
                  <div className="semester-section border rounded-lg p-3 bg-white shadow-md space-y-1">
                    <div className="flex justify-between items-center mb-0.5 ml-2">
                      <h3 className="text-lg font-medium">
                        {getYearClassName(yearNumber, 'Spring')}
                      </h3>
                      <span className="bg-purple-100 text-purple-800 text-xs font-medium px-2 py-1 rounded border border-purple-400">
                        {getTotalCreditsForSemester(
                          activeTabCourses.filter(
                            (course) =>
                              course.CourseSem === 'S2' && course.courseYear === yearNumber
                          ),
                          yearNumber,
                          'Spring'
                        )}{' '}
                        Credits
                      </span>
                    </div>
                    <FinalizedCoursesTwo
                      finalizedCourses={activeTabCourses.filter(
                        (course) => course.CourseSem === 'S2' && course.courseYear === yearNumber
                      )}
                      prereqClasses={prereqClasses}
                      semester={2}
                      yearNumber={yearNumber}
                      onCourseSelection={(courseId, isSelected) =>
                        onCourseSelection(courseId, isSelected, yearNumber, 'Spring')
                      }
                      onKeepCourse={handleKeepCourse}
                      electiveData={electiveData}
                      onElectiveClick={handleElectiveClick}
                      tabs={tabs}
                      activeTab={activeTab}
                      selectedMajor1={selectedMajor1}
                      selectedElectiveCategory={selectedElCat}
                      majorRecData={transformedMajorRecData}
                      finalizedCourses1={activeTabCourses}
                    />
                    <div className="milestones-container-spring">
                      {renderMilestones(yearNumber, 'Spring', milestones)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>Loading user data...</p>
        )}
      </div>

      {/* Modals for Adding Degrees and Minors */}
      {/* Modal for Adding a Degree */}
      {showDegreeModal && (
        <div className="fixed inset-0 z-50 flex">
          <div className="flex-1 h-full bg-black bg-opacity-50" onClick={closeModal}></div>
          <div
            className="relative w-1/3 max-w-full h-full bg-gray-50 shadow-lg overflow-y-auto"
            ref={modalRef}
          >
            {/* Modal Header */}
            <div className="flex justify-between items-center p-4 md:p-5 border-b border-gray-200">
              <h3 className="text-xl font-semibold center text-black">Select a Degree</h3>
              <button
                type="button"
                onClick={closeModal}
                className="text-white bg-transparent hover:bg-blue-600 hover:text-white rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close menu</span>
              </button>
            </div>
            {/* Modal Content */}
            <div className="p-4 md:p-5 space-y-4 text-left">
              {/* Filters */}
              <div className="mb-2 bg-white p-4 rounded border border-gray-200">
                {/* Search Input */}
                <div className="mb-4 relative">
                  <label
                    htmlFor="searchQuery"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Search:
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="searchQuery"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                      className="form-control w-full pl-10 rounded border-gray-300 shadow-sm"
                    />
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      {/* Search Icon */}
                      <svg
                        className="w-5 h-5 text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.9 14.32a8 8 0 111.414-1.414l5.387 5.386-1.415 1.414-5.386-5.386zm-4.9-1.32a5 5 0 100-10 5 5 0 000 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {/* Faculty Filter */}
                <div className="mb-2">
                  <label
                    htmlFor="facultyFilter"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Filter by Faculty:
                  </label>
                  <select
                    id="facultyFilter"
                    value={selectedFaculty || ''}
                    onChange={(e) => handleFacultyFilterChange(e.target.value)}
                    className="form-control w-full rounded border-gray-300 shadow-sm"
                  >
                    <option value="">All Faculties</option>
                    {faculties.map((faculty) => (
                      <option key={faculty} value={faculty}>
                        {faculty}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Degree List */}
              <ul className="space-y-0">
                {Object.keys(filteredFetchedMajors).map((major, index) => (
                  <li
                    key={major}
                    className={`w-full text-base leading-relaxed text-black cursor-pointer px-4 py-3 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-300 ${
                      index !== Object.keys(filteredFetchedMajors).length - 1
                        ? 'border-b border-gray-200'
                        : ''
                    }`}
                    onClick={() => handleDegreeSelection({ Major: major })}
                  >
                    {major}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Adding a Second Degree */}
      {showDegreeModal1 && (
        <div className="fixed inset-0 z-50 flex">
          <div className="flex-1 h-full bg-black bg-opacity-50" onClick={closeModal1}></div>
          <div
            className="relative w-1/3 max-w-full h-full bg-gray-50 shadow-lg overflow-y-auto"
            ref={modalRef1}
          >
            {/* Modal Header */}
            <div className="flex justify-between items-center p-4 md:p-5 border-b border-gray-200">
              <h3 className="text-xl font-semibold text-black">Select a Second Degree</h3>
              <button
                type="button"
                onClick={closeModal1}
                className="text-white bg-transparent hover:bg-green-600 hover:text-white rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center"
              >
                {/* Close Icon */}
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close menu</span>
              </button>
            </div>
            {/* Modal Content */}
            <div className="p-4 md:p-5 space-y-4 text-left">
              {/* Filters */}
              <div className="mb-2 bg-white p-4 rounded border border-gray-200">
                {/* Search Input */}
                <div className="mb-4 relative">
                  <label
                    htmlFor="searchQuery"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Search:
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="searchQuery"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                      className="form-control w-full pl-10 rounded border-gray-300 shadow-sm"
                    />
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      {/* Search Icon */}
                      <svg
                        className="w-5 h-5 text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.9 14.32a8 8 0 111.414-1.414l5.387 5.386-1.415 1.414-5.386-5.386zm-4.9-1.32a5 5 0 100-10 5 5 0 000 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                {/* Faculty Filter */}
                <div className="mb-2">
                  <label
                    htmlFor="facultyFilter"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Filter by Faculty:
                  </label>
                  <select
                    id="facultyFilter"
                    value={selectedFaculty || ''}
                    onChange={(e) => handleFacultyFilterChange(e.target.value)}
                    className="form-control w-full rounded border-gray-300 shadow-sm"
                  >
                    <option value="">All Faculties</option>
                    {faculties.map((faculty) => (
                      <option key={faculty} value={faculty}>
                        {faculty}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Second Degree List */}
              <ul className="space-y-0">
                {Object.keys(filteredFetchedMajors).map((major, index) => {
                  const isSelected = selectedSecondMajor.includes(major);
                  return (
                    <li
                      key={major}
                      className={`w-full flex items-center text-base leading-relaxed text-black cursor-pointer px-4 py-3 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-300 ${
                        index !== Object.keys(filteredFetchedMajors).length - 1
                          ? 'border-b border-gray-200'
                          : ''
                      }`}
                      onClick={() => handleSecondMajorSelection({ Major: major })}
                    >
                      <div
                        className={`flex items-center justify-center w-6 h-6 rounded-full mr-2 ${
                          isSelected ? 'bg-green-100 text-green-600' : 'bg-blue-100 text-blue-600'
                        }`}
                      >
                        {isSelected ? (
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                          </svg>
                        ) : (
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                          </svg>
                        )}
                      </div>
                      {major}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}

      {showDegreeModal2 && filteredMinors && (
        <div className="fixed inset-0 z-50 flex">
          <div className="flex-1 h-full bg-black bg-opacity-50" onClick={closeModal2}></div>
          <div
            className="relative w-1/3 max-w-full h-full bg-gray-50 shadow-lg overflow-y-auto"
            ref={modalRef2}
          >
            <div className="flex justify-between items-center p-4 md:p-5 border-b dark:border-gray-600">
              <h3 className="text-xl font-semibold text-black">Select a Minor</h3>
              <button
                type="button"
                onClick={closeModal2}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close menu</span>
              </button>
            </div>
            <div className="p-4 md:p-5 space-y-4 text-left">
              {/* Search and Filter Inputs */}
              <div className="mb-2 bg-white p-4 rounded border border-gray-200">
                <label
                  htmlFor="searchQuery"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Search:
                </label>
                <input
                  type="text"
                  id="searchQuery"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    filterMinors(e.target.value);
                  }}
                  className="form-control mb-3 w-full rounded border-gray-300 shadow-sm"
                />
              </div>

              {/* Display Filtered Minors */}
              <ul className="space-y-2">
                {Object.keys(filteredMinors).map((minorName) => {
                  const isSelected = selectedMinor.includes(minorName);

                  return (
                    <li key={minorName}>
                      <button
                        className="w-full text-left mb-4 px-3 py-2 text-black border-b border-gray-200 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center"
                        onClick={() => handleMinorSelection(minorName)}
                      >
                        <div
                          className={`flex items-center justify-center w-6 h-6 rounded-full mr-2 ${
                            isSelected ? 'bg-green-100 text-green-600' : 'bg-blue-100 text-blue-600'
                          }`}
                        >
                          {isSelected ? (
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                          ) : (
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                            </svg>
                          )}
                        </div>
                        {minorName.replace('Minor: ', '')}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </DndProvider>
  );
};

export default MajorPage;
