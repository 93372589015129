import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Initialize Firebase with environment variables
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL, 
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET, 
  messagingSenderId: process.env.MESSAGING_SENDER_ID, 
  appId: process.env.APP_ID 
});
console.log('Env vars: ', process.env.REACT_APP_TYPE, process.env.REACT_APP_AUTH_DOMAIN, process.env.REACT_APP_DATABASE_URL, process.env.REACT_APP_PROJECT_ID);

// Initialize services
const auth = app.auth();
const db = app.firestore();
const functions = app.functions();

// Set the authentication persistence to LOCAL
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    console.log("Auth persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Error setting auth persistence:", error);
  });

// // Use emulators if running on localhost
// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://localhost:9099");
//   connectFirestoreEmulator(firestore, "localhost", 8080);
//   connectDatabaseEmulator(database, "localhost", 9000);
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }

export { auth, db, firebase, functions };
export default app;