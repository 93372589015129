import React, { useState } from 'react';

const CoursesSection = ({ courses = [], setCourses, setShowClassSearch }) => {
  const handleRemoveClass = (courseToRemove) => {
    const updatedCourses = courses.filter(
      (course) => course.courseInfo_courseNumber !== courseToRemove.courseInfo_courseNumber
    );
    setCourses(updatedCourses);
  };

  const handleSelectCourse = (index) => {
    setShowClassSearch('courses', index);
  };

  const handleAssignPossibilities = (index) => {
    setShowClassSearch('possibilities', index, 'courses');
  };

  const handleAddCourse = () => {
    const newCourse = {
      courseInfo_courseNumber: '',
      courseYear: '',
      CourseSem: '',
      Description: '',
      Possibilities: [], // Initialized as an empty array
      Requirements: '',
      courseType: 'Major Course',
    };
    setCourses([...courses, newCourse]);
  };

  const handleAddPossibilities = (index, possibilities) => {
    const updatedCourses = [...courses];
    const validPossibilities = possibilities.filter(
      (possibility) => possibility.courseInfo_courseNumber
    );
    updatedCourses[index].Possibilities = [
      ...updatedCourses[index].Possibilities,
      ...validPossibilities,
    ];
    setCourses(updatedCourses);
  };

  const handleRemovePossibility = (courseIndex, possibilityIndex) => {
    const updatedCourses = [...courses];
    if (
      Array.isArray(updatedCourses[courseIndex].Possibilities) &&
      updatedCourses[courseIndex].Possibilities.length > possibilityIndex
    ) {
      updatedCourses[courseIndex].Possibilities.splice(possibilityIndex, 1);
      setCourses(updatedCourses);
    }
  };

  // For dropdown
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(index);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center mb-2"></div>
      {courses.length === 0 ? (
        <p>No courses currently included</p>
      ) : (
        <ul className="space-y-4">
          {courses.map((course, index) => (
            <li key={index} className="p-4 bg-gray-100 rounded-lg shadow">
              <div className="flex justify-between items-center">
                <span className="font-medium">
                  {course.courseInfo_courseNumber || 'No course selected'}
                </span>
                <div className="flex space-x-2">
                  {/* Conditionally render "Select Course" button if course is not selected */}
                  {!course.courseInfo_courseNumber && (
                    <button
                      type="button"
                      onClick={() => handleSelectCourse(index)}
                      className="bg-blue-600 hover:bg-blue-700 text-white px-2 py-1 rounded transition-colors"
                    >
                      Select Course
                    </button>
                  )}

                  {/* Conditionally render "Assign Possibilities" button if course is selected */}
                  {course.courseInfo_courseNumber && (
                    <button
                      type="button"
                      onClick={() => handleAssignPossibilities(index)}
                      className="bg-purple-600 hover:bg-purple-700 text-white px-2 py-1 rounded transition-colors"
                    >
                      Assign Possibilities
                    </button>
                  )}

                  {/* "Remove" button is always rendered */}
                  <button
                    type="button"
                    onClick={() => handleRemoveClass(course)}
                    className="bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700 transition-colors"
                  >
                    Remove
                  </button>
                </div>
              </div>

              {/* Course Details */}
              <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Course Year
                  </label>
                  <select
                    value={course.courseYear}
                    onChange={(e) => {
                      const updatedCourses = [...courses];
                      updatedCourses[index].courseYear = e.target.value;
                      setCourses(updatedCourses);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Select Course Year
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Semester
                  </label>
                  <select
                    value={course.CourseSem}
                    onChange={(e) => {
                      const updatedCourses = [...courses];
                      updatedCourses[index].CourseSem = e.target.value;
                      setCourses(updatedCourses);
                    }}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
                  >
                    <option value="" disabled>
                      Select Semester
                    </option>
                    <option value="S1">S1</option>
                    <option value="S2">S2</option>
                  </select>
                </div>
              </div>

              {/* Possibilities Dropdown */}
              {course.courseInfo_courseNumber && (
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Possibilities
                  </label>
                  <div className="mt-1 relative">
                    <button
                      type="button"
                      onClick={() => toggleDropdown(index)}
                      className="w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    >
                      {course.Possibilities.length > 0
                        ? `${course.Possibilities.length} Possibility(ies)`
                        : 'No Possibilities Assigned'}
                    </button>
                    {openDropdown === index && (
                      <div className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {course.Possibilities.length === 0 ? (
                          <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                            No possibilities assigned.
                          </div>
                        ) : (
                          course.Possibilities.map((possibility, pIndex) => (
                            <div key={pIndex} className="flex justify-between items-center cursor-pointer select-none relative py-2 px-4 text-gray-700 hover:bg-gray-100">
                              <span>{possibility.courseInfo_courseNumber}</span>
                              <button type="button" onClick={() => handleRemovePossibility(index, pIndex)} className="text-red-600 hover:text-red-800">
                                Remove
                              </button>
                            </div>
                          ))      
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddCourse}
        className="w-full mt-4 bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700 transition-colors"
      >
        Add Course
      </button>
    </div>
  );
};

export default CoursesSection;
