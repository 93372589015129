import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import NavBar from '../NavBar';
import FinalizedCoursesNew from './FinalizedCoursesNew';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import './SemesterCard.css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MilestoneModal from '../Milestones/MilestoneModal';
import { createPDFDocument } from './ExportData/exportDegreePlan';
import PDFPreview from './ExportData/PDFPreview';
import { useAuth } from '../../contexts/AuthContext';
import { FaPlus, FaMagic, FaTrash, FaMinus } from 'react-icons/fa';
import MilestoneDrawer from '../Milestones/MilestoneDrawer';
import PlanJoyrideTour from './PlanJoyrideTour';
import ElectiveSidebar from '../Degree Plans/ElectiveSidebar';
import EvaluationModal from './EvaluationModal';
import { BsThreeDots } from "react-icons/bs";
import { FaStar, FaRegStar, FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { GrFormAdd, GrFormClose } from "react-icons/gr";
import { CiGrid2V, CiGrid41 } from "react-icons/ci";
import { LuGoal } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { BiSolidHide, BiSolidShow } from "react-icons/bi";

const SemesterCardTwo = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const dropdownRefs = useRef({});
  const buttonRefs = useRef([]);
  const [numYears, setNumYears] = useState(4);
  const [finalizedCourses, setFinalizedCourses] = useState([]);
  const [finalizedCourseIds, setFinalizedCourseIds] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [newScheduleName, setNewScheduleName] = useState('');
  const [activeTab, setActiveTab] = useState('defaultTab'); // Set a default value for activeTab
  const [primaryTab, setPrimaryTab] = useState('defaultTab');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(''); // State for managing errors
  const [dataFetchComplete, setDataFetchComplete] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [hideCompleted, setHideCompleted] = useState(false);
  const [hideCompletedYears, setHideCompletedYears] = useState(false);

  const [showModal, setShowModal] = useState(false);  // Add this line
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentSemester, setCurrentSemester] = useState({ year: null, semester: null });
  const [showAllMilestonesDrawer, setShowAllMilestonesDrawer] = useState(false);
  const [editingTab, setEditingTab] = useState(null);
  const [newTabName, setNewTabName] = useState('');

  const { currentUser, isUserDataFetched, fetchUserData, fetchedCourseData, fetchedPrereqData, majorData, fetchedMajorRecs, fetchedMinors, fetchedMajors, pathwayGroupingsData } = useAuth();

  const generateInitialState = (numYears) => {
    const yearObject = {};

    for (let i = 1; i <= numYears; i++) {
      yearObject[`year${i}`] = {
        Fall: [],
        Spring: []
      };
    }

    return yearObject;
  };

    const [finalizedOrganizedCourses, setFinalizedOrganizedCourses] = useState(generateInitialState(numYears));

  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [cumulativeCourseIds, setCumulativeCourseIds] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [majorCourses, setMajorCourses] = useState([]);
  const [minorCourses, setMinorCourses] = useState([]);

  const [allCoursesByYearAndSemester, setAllCoursesByYearAndSemester] = useState({});
  const [middleschoolClasses, setMiddleschoolClasses] = useState([]);
  const [prereqs, setPrereqs] = useState([]);
  const [majorPathwayData, setMajorPathwayData] = useState(0);
  const [majorElectives, setMajorElectives] = useState(generateInitialState(numYears));
  const [majorRequirementCourses, setMajorRequirementCourses] = useState(generateInitialState(numYears));
  const [minorRequirementCourses, setMinorRequirementCourses] = useState(generateInitialState(numYears));
  const [finalizedMilestones, setFinalizedMilestones] = useState([]);
  const [milestonesByYearAndSemester, setMilestonesByYearAndSemester] = useState(generateInitialState(numYears));
  const [pdfBytes, setPdfBytes] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [highlightOption, setHighlightOption] = useState(null);
  const [completedSemesters, setCompletedSemesters] = useState({});
  const [userRecsData, setUserRecsData] = useState({});
  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedElectiveCategory, setSelectedElectiveCategory] = useState([]);
  const [electiveYear, setElectiveYear] = useState([]);
  const [electiveSemester, setElectiveSemester] = useState([]);
  const [isEvaluationModalOpen, setEvaluationModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [viewMode, setViewMode] = useState('vertical');
  const [isAutofilling, setIsAutofilling] = useState(false);
  const [areAnyPrereqsNotMet, setAreAnyPrereqsNotMet] = useState(false);

  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedMajorRecData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedMajorRecData);
    }
  }, [fetchedMajorRecs]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownKeys = Object.keys(dropdownRefs.current);
      let isClickInside = false;

      for (let key of dropdownKeys) {
        if (dropdownRefs.current[key]?.contains(event.target)) {
          isClickInside = true;
          break;
        }
      }

      for (let button of buttonRefs.current) {
        if (button?.contains(event.target)) {
          isClickInside = true;
          break;
        }
      }

      // Check if the event target is the "Remove" button or its parent container
      if (event.target.closest('.remove-button')) {
        isClickInside = true;
      }

      if (!isClickInside) {
        setDropdownOpen({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowAddScheduleModal(false); // Close the modal
    }
  };

  useEffect(() => {
    if (showAddScheduleModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showAddScheduleModal]);

  useEffect(() => {
      const hasAnyUnmetPrereqs = Object.entries(finalizedOrganizedCourses).some(([year, semesters]) =>
        Object.entries(semesters).some(([semester, courses]) => {
          // Map semester names to keys
          const semesterKey = semester === 'Fall' ? 'S1' : 'S2';
          console.log(finalizedOrganizedCourses, year, semesterKey, 'hiasdf')
          const semesterHasAnyUnmetPrereqs = finalizedOrganizedCourses[year]?.[semester].some((index, course) => {
            return !course.isPrereqMet
          })
          return semesterHasAnyUnmetPrereqs;
        })
      );

      setAreAnyPrereqsNotMet(hasAnyUnmetPrereqs);
  }, [finalizedOrganizedCourses]);
  

  const organizeAllCoursesByYearAndSemester = useCallback((allCourses) => {
    const coursesByYearAndSemester = {};
    allCourses.forEach((course) => {
      const year = course.courseYear || 'Uncategorized';
      const semester = course.CourseSem || 'Uncategorized';
      if (!coursesByYearAndSemester[year]) {
        coursesByYearAndSemester[year] = { Fall: [], Spring: [] };
      }
      const semesterName = semester === 'S1' ? 'Fall' : (semester === 'S2' ? 'Spring' : 'Uncategorized');
      if (semesterName !== 'Uncategorized') {
        coursesByYearAndSemester[year][semesterName].push(course);
      }
    });
    return coursesByYearAndSemester;
  }, []);

  const organizeMajorCoursesByYearAndSemester = useCallback((majorCourses) => {
    const coursesByYearAndSemester = {};
    majorCourses.forEach((course) => {
      const year = course.courseYear || 'Uncategorized';
      const semester = course.CourseSem || 'Uncategorized';
      if (!coursesByYearAndSemester[year]) {
        coursesByYearAndSemester[year] = { Fall: [], Spring: [] };
      }
      const semesterName = semester === 'S1' ? 'Fall' : (semester === 'S2' ? 'Spring' : 'Uncategorized');
      if (semesterName !== 'Uncategorized') {
        coursesByYearAndSemester[year][semesterName].push(course);
      }
    });
    return coursesByYearAndSemester;
  }, []);

  const hasUncompletedCourses = useMemo(() => {
    return Object.entries(finalizedOrganizedCourses).some(([year, semesters]) =>
      Object.entries(semesters).some(([semester, courses]) => {
        // Map semester names to keys
        const semesterKey = semester === 'Fall' ? 'S1' : 'S2';
        return !completedSemesters[year]?.[semesterKey] && courses.length > 0;
      })
    );
  }, [finalizedOrganizedCourses, completedSemesters]);

    const loadActiveTabCourses = async (user) => {
      const currentUser = user;
      try {
        if (currentUser && currentUser.uid) {
          const userRef = db.collection('users').doc(currentUser.uid);
          const userDoc = await userRef.get();
          if (userDoc.exists) {
            const userData = userDoc.data();
            const courseIds = userData.schedules[activeTab] || [];
            setFinalizedCourseIds(courseIds);
  
            const cumulativeIds = calculateCumulativeIds(courseIds);
            setCumulativeCourseIds(cumulativeIds);
  
            const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCourses, prereqs, cumulativeIds);
            setFinalizedOrganizedCourses(organizedCourses);
          }
        }
      } catch (error) {
        console.error('Error setting active tab:', error);
      }
    };
  

  const organizeCoursesByYearAndSemester = useCallback((courseIds, allCourses, prereqs, cumulativeIds) => {
    const coursesByYearAndSemester = {};

    for (const yearKey in courseIds) {
      const yearObj = courseIds[yearKey];

      for (const semesterKey in yearObj) {
        const semesterIds = yearObj[semesterKey].courses;
        const semesterClasses = Array.isArray(semesterIds) ? allCourses.filter(course => semesterIds.includes(course.courseInfo_courseNumber)) : [];
      
        semesterClasses.forEach(course => {
          const courseInfo = fetchedPrereqData.find(prereq => prereq.Course === course.courseInfo_courseNumber);
          if (courseInfo) {
            course.Prerequisites = parsePrerequisites(courseInfo.Prerequisites);
            course.Corequisites = parseCorequisites(courseInfo.Corequisites);
          }
          course.isPrereqMet = checkPrerequisites(course, yearKey, semesterKey, cumulativeIds);
        });

        const semesterName = semesterKey === 'S1' ? 'Fall' : 'Spring';
        if (!coursesByYearAndSemester[yearKey]) {
          coursesByYearAndSemester[yearKey] = { Fall: [], Spring: [] };
        }
        coursesByYearAndSemester[yearKey][semesterName].push(...semesterClasses);
      }
    }

    return coursesByYearAndSemester;
  }, []);

  const organizeMilestonesByYearAndSemester = useCallback((milestones = []) => {
    // Ensure milestones is an array
    if (!Array.isArray(milestones)) {
      console.error('Milestones is not an array:', milestones);
      return {};
    }
  
    const milestonesByYearAndSemester = {};
    
    // Initialize milestonesByYearAndSemester with empty arrays for each year and semester
    for (let i = 1; i <= numYears; i++) {
      milestonesByYearAndSemester[`year${i}`] = {
        Fall: [],
        Spring: []
      };
    }
  
    // Iterate through milestones and organize them by year and semester
    milestones.forEach((milestone) => {
      if (milestone) {
        const year = `year${milestone.Milestone_Year}`;
        const semester = milestone.Milestone_Sem === 'S1' ? 'Fall' : 'Spring';
        if (milestonesByYearAndSemester[year] && milestonesByYearAndSemester[year][semester]) {
          milestonesByYearAndSemester[year][semester].push(milestone);
        }
      }
    });
  
    return milestonesByYearAndSemester;
  }, [numYears]);  

  const [numYears2, setNumYears2] = useState([]);

  const fetchUserDataLocal = useCallback(async () => {
    console.log('Fetching user data...');
    try {
      if (currentUser) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        const userDoc = await userDocRef.get();
  
        if (userDoc.exists) {
          const userData = userDoc.data();
          console.log('User Data: ', userData);

          const majorPathwayData = pathwayGroupingsData.filter(major => major.pathwayType === 'Major')
          const userMajorPathwayData = majorPathwayData.find(major => major.pathwayName == userData.surveyAnswers.Major) || {}
          setMajorPathwayData(userMajorPathwayData)

          const courseIds = userData.schedules?.[activeTab] || []; // Ensure activeTab is used safely
          setFinalizedCourseIds(courseIds);
  
          const middleschoolcourseIds = userData.middleschoolClasses || [];
          setMiddleschoolClasses(middleschoolcourseIds);
  
          const backendSurveyAnswers = userData.surveyAnswers || [];
          setSurveyAnswers(backendSurveyAnswers);
  
          const backendUserRecData = userData.recommendationRatings || {};
          setUserRecsData(backendUserRecData);
  
          const calculateRecScore = require('../CourseRecommendation');
  
          const allCourses = fetchedCourseData;
          const prereqs = fetchedPrereqData;
  
  
          let transformedMajorRecs = {};
  
          for (let i = 0; i < fetchedMajorRecs.length; i++) {
            const curr = fetchedMajorRecs[i];
            const { Major, ...categories } = curr;
            transformedMajorRecs[Major] = categories;
          }
  
          const allCoursesWithRecScore = allCourses.map(course => {
            const recScore = calculateRecScore(transformedMajorRecs[course.Major], userData.recommendationRatings, course, userData.surveyAnswers);
      
            return {
              ...course,
              recScore
            };
          });


          // Assume surveyData['Majors'] and surveyData['Minors'] are arrays of major and minor names
          const majorNames = backendSurveyAnswers['Majors'];
          const minorNames = backendSurveyAnswers['Minors'] || []; // Handling if minors are not present

          // Objects to store data keyed by major/minor name
          const majorCoursesData = {};
          const majorElectivesData = {};
          const majorRequirementsData = {};

          const minorCoursesData = {};
          const minorRequirementsData = {};

          // Function to calculate max years for given courses
          const calculateMaxYears = (courses) => Math.max(...courses.map(course => course.courseYear));

          // Semester mapping
          const semesterMap = {
              'S1': 'Fall',
              'S2': 'Spring'
          };
          console.log('Major Names: ', majorNames);
          // Process each major
          majorNames.forEach((majorName) => {

              const majorCoursesFromBackend = Object.values(fetchedMajors[majorName]);

              const majorCoursesMap = new Map();
              const majorRequirementsMap = {};
              const electiveCoursesMap = {};

              const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

              for (let i = 1; i <= maxMajorYears; i++) {
                  majorRequirementsMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
                  electiveCoursesMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
              }
              console.log('MajorCourses: ', majorCoursesFromBackend);
              majorCoursesFromBackend.forEach(course => {
                  if (course.courseType === 'Major Course') {
                      if (course.Requirements === '') {
                          majorCoursesMap.set(course.courseInfo_courseNumber, {
                              courseYear: course.courseYear,
                              courseSemester: course.CourseSem
                          });
                      } else {
                          if (course.courseYear <= maxMajorYears) {
                              const semester = semesterMap[course.CourseSem] || course.CourseSem;
                              majorRequirementsMap[`year${course.courseYear}`][semester].push(course);
                          }
                      }
                  } else if (course.courseType === 'Elective') {
                      if (course.courseYear <= maxMajorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          electiveCoursesMap[`year${course.courseYear}`][semester].push(course);
                      }
                  } else if (course.courseType === 'Requirement') {
                      if (course.courseYear <= maxMajorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          majorRequirementsMap[`year${course.courseYear}`][semester].push(course);
                      }
                  }
              });

              const allCoursesWithRecScore = allCourses.map(course => {
                  const recScore = calculateRecScore(transformedMajorRecs[course.Major], userData.recommendationRatings, course, userData.surveyAnswers);
                  const majorCourseNumbers = majorCoursesFromBackend.map(majorCourse => majorCourse.courseInfo_courseNumber);
                  const courseType = majorCourseNumbers.includes(course.courseInfo_courseNumber) ? "Major Course" : "Elective";

                  return {
                      ...course,
                      recScore,
                      courseType
                  };
              });

              const majorCoursesInfo = allCoursesWithRecScore
                  .filter(course => majorCoursesMap.has(course.courseInfo_courseNumber))
                  .map(course => {
                      const { courseYear, courseSemester } = majorCoursesMap.get(course.courseInfo_courseNumber);
                      return {
                          ...course,
                          courseYear,
                          CourseSem: courseSemester
                      };
                  });

              majorCoursesData[majorName] = majorCoursesInfo;
              majorElectivesData[majorName] = electiveCoursesMap;
              majorRequirementsData[majorName] = majorRequirementsMap;
          });

          // Process each minor
          minorNames.forEach((minorName) => {
              const minorCoursesFromBackend = Object.values(fetchedMinors[minorName]);

              const minorCoursesMap = {};
              const minorRequirementsMap = {};

              const maxMinorYears = calculateMaxYears(minorCoursesFromBackend);

              for (let i = 1; i <= maxMinorYears; i++) {
                  minorCoursesMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
                  minorRequirementsMap[`year${i}`] = {
                      Fall: [],
                      Spring: []
                  };
              }

              minorCoursesFromBackend.forEach(course => {
                  if (course.Requirements === '') {
                      if (course.courseYear <= maxMinorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          minorCoursesMap[`year${course.courseYear}`][semester].push(course);
                      }
                  } else {
                      if (course.courseYear <= maxMinorYears) {
                          const semester = semesterMap[course.CourseSem] || course.CourseSem;
                          minorRequirementsMap[`year${course.courseYear}`][semester].push(course);
                      }
                  }
              });

              minorCoursesData[minorName] = minorCoursesMap;
              minorRequirementsData[minorName] = minorRequirementsMap;
          });

          // Set the state with the new structured data
          setMajorCourses(majorCoursesData);
          setMajorElectives(majorElectivesData);
          setMajorRequirementCourses(majorRequirementsData);
          console.log('Major Electives Test: ', majorElectives);

          setMinorCourses(minorCoursesData);
          setMinorRequirementCourses(minorRequirementsData);

          setAllCourses(allCoursesWithRecScore);

          setNumYears2(userData?.surveyAnswers?.SchoolEnd?.year - userData?.surveyAnswers?.SchoolStart?.year);
          setNumYears(userData?.schedules?.[activeTab]?.numYears || 4);

          console.log('Numyears firestore:', numYears);
  
          const cumulativeIds = calculateCumulativeIds(courseIds);
          setCumulativeCourseIds(cumulativeIds);
  
          const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCoursesWithRecScore, prereqs, cumulativeIds);
          setFinalizedOrganizedCourses(organizedCourses);
  
          const allOrganizedCourses = organizeAllCoursesByYearAndSemester(allCoursesWithRecScore);
          setAllCoursesByYearAndSemester(allOrganizedCourses);
  
          const milestonesData = userData.finalizedMilestones?.[activeTab] || {};
          const finalizedMilestonesArray = Object.values(milestonesData).reduce((acc, yearData) => {
            Object.values(yearData).forEach(semData => {
              acc = acc.concat(semData.milestones);
            });
            return acc;
          }, []);
  
          setFinalizedMilestones(finalizedMilestonesArray);
  
          const organizedMilestones = organizeMilestonesByYearAndSemester(finalizedMilestonesArray);
          setMilestonesByYearAndSemester(organizedMilestones);
  
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  }, [fetchedCourseData, fetchedPrereqData, fetchedMajors, majorData, fetchedMajorRecs, fetchedMinors, currentUser, activeTab, tabs]);
  
  useEffect(() => {
    if (activeTab) {
      fetchUserDataLocal();
    }
  }, [fetchUserDataLocal, activeTab]);
  
  useEffect(() => {
    fetchUserDataLocal();
  }, [fetchUserDataLocal]);

  useEffect(() => {
    const fetchScheduleData = async () => {
      try {
        if (currentUser) {
          const userDocRef = db.collection('users').doc(currentUser.uid);
          const userDoc = await userDocRef.get();
  
          if (userDoc.exists) {
            const userData = userDoc.data();
            const scheduleArray = Object.keys(userData.schedules || {}).map((tabName) => ({
              name: tabName,
              data: userData.schedules[tabName],
            })).sort((a, b) => a.name.localeCompare(b.name)); // Optional: Sort alphabetically or by some other criteria
  
            setTabs(scheduleArray);
            const initialTab = userData.activeTab || scheduleArray[0].name;
            setActiveTab(initialTab);

            const primaryTabFromBackend = userData?.primaryTab || scheduleArray[0].name;
            setPrimaryTab(primaryTabFromBackend)

            if (!userData?.primaryTab){
              await userDocRef.update({
                primaryTab: primaryTabFromBackend
              });
            }

            // Ensure the courses for the initial active tab are loaded
            const courseIds = userData.schedules[initialTab] || [];
            setFinalizedCourseIds(courseIds);
  
            const cumulativeIds = calculateCumulativeIds(courseIds);
            setCumulativeCourseIds(cumulativeIds);
  
            const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCourses, prereqs, cumulativeIds);
            setFinalizedOrganizedCourses(organizedCourses);
  
          } else {
            setShowAddScheduleModal(true);
          }
        }
      } catch (error) {
        console.error('Error fetching schedule data', error);
      }
    };
  
    fetchScheduleData();
  }, [currentUser]); // Ensure this runs when the user, allCourses, or prereqs changes
  

  useEffect(() => {
    if (currentUser) {
      const fetchCompletedSemesters = async () => {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setCompletedSemesters(userData.completedSemesters || {});
        }
      };
      fetchCompletedSemesters();
    }
  }, [currentUser]);

  const handleToggleSemesterComplete = async (year, semester) => {
    const semesterKey = semester === 'Fall' ? 'S1' : 'S2';
    const newStatus = !completedSemesters[`year${year}`]?.[semesterKey];
    const updatedCompletedSemesters = {
        ...completedSemesters,
        [`year${year}`]: {
            ...completedSemesters[`year${year}`],
            [semesterKey]: newStatus,
        },
    };

    // If the semester is marked incomplete, mark all subsequent semesters as incomplete
    if (!newStatus) {
        for (let i = year; i <= numYears; i++) {
            const yearKey = `year${i}`;
            if (i === year && semesterKey === 'S1') {
                updatedCompletedSemesters[yearKey] = {
                    S1: false,
                    S2: false,
                };
            } else if (i === year && semesterKey === 'S2') {
                updatedCompletedSemesters[yearKey] = {
                    ...updatedCompletedSemesters[yearKey],
                    S2: false,
                };
            } else {
                updatedCompletedSemesters[yearKey] = {
                    S1: false,
                    S2: false,
                };
            }
        }
    }

    setCompletedSemesters(updatedCompletedSemesters);

    // Set a unique toast ID for managing toast lifecycle
    const loadingToastId = 'semester-update-toast';
    
    // Show a loading toast with custom width and a minimum display duration of 500ms
    if (!toast.isActive(loadingToastId)) {
        toast.loading('Updating status', {
            toastId: loadingToastId,
            autoClose: false,
            className: 'custom-toast custom-toast-loading',
            position: 'top-center',
            style: {
                width: '12rem', // Set your desired width
            },
        });
    }

    // Update completedSemesters in the user document, not in schedules
    if (currentUser) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        try {
            const userDoc = await userDocRef.get();

            if (userDoc.exists) {
                const userData = userDoc.data();
                const allPlans = Object.keys(userData.schedules || {});
                const activeTab = userData.activeTab || allPlans[0];

                // Fetch courses from the active tab for the completed semester
                const coursesToCopy = userData.schedules[activeTab][`year${year}`]?.[semesterKey]?.courses || [];

                // Prepare batch updates for completedSemesters only in the user's document
                const batch = db.batch();

                // Update only the completedSemesters field in Firestore
                batch.update(userDocRef, {
                    completedSemesters: updatedCompletedSemesters,
                });

                // Overwrite the courses in the completed semester in each plan
                allPlans.forEach((plan) => {
                    const updatedSchedule = {
                        ...userData.schedules[plan],
                        [`year${year}`]: {
                            ...userData.schedules[plan][`year${year}`],
                            [semesterKey]: {
                                ...userData.schedules[plan][`year${year}`]?.[semesterKey],
                                courses: coursesToCopy,
                            },
                        },
                    };

                    batch.update(userDocRef, {
                        [`schedules.${plan}`]: updatedSchedule,
                    });
                });

                // Commit all updates and delay updating the toast status to ensure it shows for at least 500ms
                setTimeout(async () => {
                    await batch.commit();
                    toast.update(loadingToastId, {
                        render: 'Status updated',
                        type: 'success',
                        autoClose: 1000,
                        isLoading: false,
                        hideProgressBar: true,
                        style: {
                            width: '12rem', // Ensure the success toast keeps the same width
                        },
                    });
                }, 500); // Delay the transition by 500ms to keep the loading state visible
            }
        } catch (error) {
            // Show an error toast if the operation fails, ensuring the loading toast stays for at least 500ms
            setTimeout(() => {
                toast.update(loadingToastId, {
                    render: 'Failed to update semester status.',
                    type: 'error',
                    autoClose: 3000,
                    isLoading: false,
                    style: {
                        width: '20rem', // Ensure the error toast keeps the same width
                    },
                });
            }, 500);
            console.error('Error updating completed semesters:', error);
        }
    }
};

const shouldShowMarkCompleteButton = (year, semester) => {
  if (semester === 'Fall') {
      const prevYear = `year${year - 1}`;
      const prevSemester = 'Spring';
      return year === 1 || completedSemesters[prevYear]?.['S2']; // Check Spring of the previous year
  } else if (semester === 'Spring') {
      const prevYear = `year${year}`;
      const prevSemester = 'Fall';
      return completedSemesters[prevYear]?.['S1']; // Check Fall of the same year
  }
  return true;
};

const shouldShowRemoveYearButton = (year) => {
  const yearKey = `year${year}`;
  const completed = completedSemesters[yearKey];
  // Check if either Fall (S1) or Spring (S2) semesters are completed
  return !(completed?.S1 || completed?.S2);
};

  const calculateCumulativeIds = (courseIds) => {
    let cumulativeIds = { year0: [] };
    let prevYearsCourses = [];

    for (let i = 1; i <= numYears; i++) {
      let year = `year${i}`;
      let yearCourses = {};
      let cumulativeCourses = prevYearsCourses;

      for (let j = 1; j <= 2; j++) {
        let semester = `S${j}`;
        if (courseIds && courseIds[year] && courseIds[year][semester]) {
          cumulativeCourses = cumulativeCourses.concat(courseIds[year][semester].courses);
        }
        cumulativeCourses = Array.from(new Set(cumulativeCourses));
        yearCourses[semester] = [...cumulativeCourses];
      }

      prevYearsCourses = prevYearsCourses.concat(cumulativeCourses);
      prevYearsCourses = Array.from(new Set(prevYearsCourses));
      cumulativeIds[year] = yearCourses;
    }

    return cumulativeIds;
  };

  const parsePrerequisites = (prerequisites) => {
    let arrayOfPrerequisites = prerequisites.split("&");
    arrayOfPrerequisites = arrayOfPrerequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfPrerequisites.map(element => element.split("||"));
  };

  const parseCorequisites = (corequisites) => {
    let arrayOfCorequisites = corequisites.split("&");
    arrayOfCorequisites = arrayOfCorequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfCorequisites.map(element => element.split("||"));
  };

  const checkPrerequisites = (course, yearKey, semesterKey, cumulativeIds) => {
    let isPrereqMet = true;
    let isCoreqMet = true;

    if (course.Prerequisites && course.Prerequisites[0] && course.Prerequisites[0][0]) {
      isPrereqMet = course.Prerequisites.every(prereqGroup =>
        prereqGroup.some(prereq => {
          if (yearKey === 'year1' && (semesterKey === 'S1' || semesterKey === 'Fall')) {
            return cumulativeIds['year0'].includes(prereq);
          } else if ((semesterKey === 'S1' || semesterKey === 'Fall')) {
            const lastYear = parseInt(yearKey.charAt(4)) - 1;
            return cumulativeIds[`year${lastYear}`]['S2'].includes(prereq);
          } else {
            return cumulativeIds[yearKey]['S1'].includes(prereq);
          }
        })
      );
    }

    if (course.Corequisites && course.Corequisites[0] && course.Corequisites[0][0]) {
      let semesterCode = semesterKey === "Fall" ? "S1" : "S2";
      isCoreqMet = course.Corequisites.some(coreqGroup => {
        return coreqGroup.some(coreq => {
          return cumulativeIds[yearKey][semesterCode].includes(coreq);
        });
      });
    }

    const isTotalMet = isPrereqMet && isCoreqMet;

    return isTotalMet;
  };

  const recheckPrerequisites = useCallback((updatedOrganizedCourses) => {
    let cumulativeIds = { year0: [] };
    let prevYearsCourses = [];

    for (let i = 1; i <= numYears; i++) {
      let year = `year${i}`;
      let yearCourses = {};
      let cumulativeCourses = prevYearsCourses;

      for (let j = 1; j <= 2; j++) {
        let semester = `S${j}`;
        let semesterCode = semester === "S1" ? "Fall" : "Spring";

        if (updatedOrganizedCourses && updatedOrganizedCourses[year] && updatedOrganizedCourses[year][semesterCode]) {
          cumulativeCourses = cumulativeCourses.concat(updatedOrganizedCourses[year][semesterCode].map(course => course.courseInfo_courseNumber));
        }
        cumulativeCourses = Array.from(new Set(cumulativeCourses));
        yearCourses[semester] = [...cumulativeCourses];
      }

      prevYearsCourses = prevYearsCourses.concat(cumulativeCourses);
      prevYearsCourses = Array.from(new Set(prevYearsCourses));
      cumulativeIds[year] = yearCourses;
    }

    const updateObject = { ...updatedOrganizedCourses };

    for (const yearKey in updateObject) {
      ['Fall', 'Spring'].forEach(semesterKey => {
        const semesterClassesObject = updateObject[yearKey][semesterKey] || [];

        updateObject[yearKey][semesterKey] = semesterClassesObject.map(course => {
          const courseInfo = fetchedPrereqData.find(prereq => prereq.Course === course.courseInfo_courseNumber);

          if (courseInfo) {
            course.Prerequisites = parsePrerequisites(courseInfo.Prerequisites);
            course.Corequisites = parseCorequisites(courseInfo.Corequisites);
          }

          course.isPrereqMet = checkPrerequisites(course, yearKey, semesterKey, cumulativeIds);

          return { ...course };
        });
      });
    }

    setFinalizedOrganizedCourses(updateObject);
  }, [allCourses, prereqs]);

  const handleUpdateCourseProgress= async () => {
    try {
        if (currentUser && currentUser.uid) {
          const userDocRef = db.collection('users').doc(currentUser.uid);
          const userDoc = await userDocRef.get();

            if (userDoc.exists) {
                const userData = userDoc.data();
                const primaryTab = userData?.primaryTab;
                const finalizedSchedule = userData?.schedules[primaryTab];
                const completedSemesters = userData.completedSemesters || {};

                let courseArray = [];
                for (let i = 1; i <= 4; i++) {
                    let year = `year${i}`;
                    for (let j = 1; j <= 2; j++) {
                        let semester = `S${j}`;
                        if (finalizedSchedule && finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                            courseArray.push(...finalizedSchedule[year][semester]['courses']);
                        }
                    }
                }

                const studentCourses = fetchedCourseData ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber)) : [];
                const major = userData.surveyAnswers?.Majors[0] || 'N/A';

                const majorCourses = Object.values(fetchedMajors[major]);

                const totalCourses = majorCourses ? majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone').length : 0;

                const takenCoursesSet = new Set();
                Object.keys(completedSemesters).forEach(year => {
                    Object.keys(completedSemesters[year]).forEach(semesterKey => {
                        if (completedSemesters[year][semesterKey]) {
                            const completedCourses = finalizedSchedule[year][semesterKey]?.courses || [];
                            completedCourses.forEach(course => {
                                takenCoursesSet.add(course);
                            });
                        }
                    });
                });
                const takenCoursesArray = Array.from(takenCoursesSet);

                const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));

                function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
                    courses.sort((a, b) => {
                        if (a.courseInfo_courseNumber < b.courseInfo_courseNumber) return -1;
                        if (a.courseInfo_courseNumber > b.courseInfo_courseNumber) return 1;
                        return 0;
                      });
                    const usedCoursesForRequirements = [];
                    let plannedRequirementCoursesArray = [];
                    let largestValidArray = [];
                
                    function backtrack(requirementIndex) {
                        if (requirementIndex === majorCourses.length) {
                            if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                                largestValidArray = [...plannedRequirementCoursesArray];
                            }
                            return; // Continue checking other requirements
                        }
                
                        const majorCourse = majorCourses[requirementIndex];
                        
                        if ((majorCourse.courseType === 'Major Course' && majorCourse.Requirements) || (majorCourse.courseType === 'Requirement')) {
                            const possibleCourses = majorCourse.Possibilities.split(',').map(course => course.trim());
                
                            let foundValidCourse = false;
                
                            for (let i = 0; i < possibleCourses.length; i++) {
                                const courseNumber = possibleCourses[i];
                                
                                const matchingCourseIndex = courses.findIndex(course =>
                                    course.courseInfo_courseNumber === courseNumber &&
                                    !nonUsableCourses.includes(courseNumber) &&
                                    !usedCoursesForRequirements.includes(courseNumber)
                                );
                
                                if (matchingCourseIndex !== -1) {
                                    plannedRequirementCoursesArray.push(courseNumber);
                                    usedCoursesForRequirements.push(courseNumber);
                                    foundValidCourse = true;
                                    
                                    if (backtrack(requirementIndex + 1)) {
                                        return; // Continue to the next valid solution
                                    }
                
                                    plannedRequirementCoursesArray.pop();
                                    usedCoursesForRequirements.pop();
                                }
                            }
                
                            if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                                largestValidArray = [...plannedRequirementCoursesArray];
                            }
                
                            if (!foundValidCourse) {
                                backtrack(requirementIndex + 1);
                            }
                        } else {
                            backtrack(requirementIndex + 1);
                        }
                    }
                
                    backtrack(0);
                    return largestValidArray;
                }                    

                function findElectiveCourses(majorCourses, courses) {
                    courses.sort((a, b) => {
                        if (a.courseInfo_courseNumber < b.courseInfo_courseNumber) return -1;
                        if (a.courseInfo_courseNumber > b.courseInfo_courseNumber) return 1;
                        return 0;
                      });
                    const usedCoursesForElectives = [];
                    let takenElectiveCoursesArray = [];
                    let largestValidArray = [];
                
                    function backtrack(electiveIndex) {
                        if (electiveIndex === majorCourses.length) {
                            if (takenElectiveCoursesArray.length > largestValidArray.length) {
                                largestValidArray = [...takenElectiveCoursesArray];
                            }
                            return; // Continue checking other courses
                        }
                
                        const majorCourse = majorCourses[electiveIndex];
                        
                        if (majorCourse.courseType === 'Elective') {
                            const electiveName = majorCourse.Possibilities;
                
                            let foundValidCourse = false;
                
                            for (let i = 0; i < courses.length; i++) {
                                const studentCourse = courses[i];
                                
                                if (
                                    studentCourse.Elective_fulfillment.includes(electiveName) &&
                                    !usedCoursesForElectives.includes(studentCourse.courseInfo_courseNumber)
                                ) {
                                    takenElectiveCoursesArray.push(studentCourse.courseInfo_courseNumber);
                                    usedCoursesForElectives.push(studentCourse.courseInfo_courseNumber);
                                    foundValidCourse = true;
                                    break; // Stop searching since we found a valid course
                                }
                            }
                
                            if (takenElectiveCoursesArray.length > largestValidArray.length) {
                                largestValidArray = [...takenElectiveCoursesArray];
                            }
                
                            backtrack(electiveIndex + 1);
                        } else {
                            backtrack(electiveIndex + 1);
                        }
                    }
                
                    backtrack(0);
                    return largestValidArray;
                }
                

                // Calculate taken courses

                const takenMajorCoursesArray = takenCourses.filter(course => {
                    const isInMajorCourses = majorCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
                    return isInMajorCourses;
                });

                const takenElectiveCoursesArray = findElectiveCourses(
                    majorCourses.filter(course => course.courseType === 'Elective'),
                    takenCourses
                );
                
                const takenRequirementCoursesArray = findRequirementCourses(
                    majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || (course.courseType === 'Requirement')),
                    takenCourses,
                    takenElectiveCoursesArray
                );      


                // Calculate all course properties, then subtract fo planned

                const allMajorCoursesArray = studentCourses.filter(course => {
                    const isInMajorCourses = majorCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
                    return isInMajorCourses;
                });

                const allElectiveCoursesArray = findElectiveCourses(
                    majorCourses.filter(course => course.courseType === 'Elective'),
                    studentCourses
                );
                
                const allRequirementCoursesArray = findRequirementCourses(
                    majorCourses.filter(course => (course.courseType === 'Major Course' && course.Requirements) || (course.courseType === 'Requirement')),
                    studentCourses,
                    allElectiveCoursesArray
                );      

                const takenCoursesLengths = takenMajorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length

                const allCoursesLengths = allMajorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths

                const courseProgressObj = {taken: takenCoursesLengths, planned: allCoursesLengths, total: totalCourses}
                  
                await userDocRef.update({
                  courseProgress: courseProgressObj
                });

                console.log("Course Progress Updated Successfully");
            }
        }
    } catch (error) {
        console.error('Error updating course progress:', error);
    } finally {
        setLoading(false);
    }
  };

  /*useEffect(() => {
    handleUpdateCourseProgress();
  }, [primaryTab]);

  useEffect(() => {
    if (activeTab == primaryTab){
      handleUpdateCourseProgress();
    }
  }, [primaryTab, activeTab, finalizedOrganizedCourses, completedSemesters]);*/

  useEffect(() => {
    handleUpdateCourseProgress();
  }, [primaryTab, activeTab]);


  const moveCourse = useCallback(
    async (course, fromYear, fromSemester, toYear, toSemester, targetIndex = null) => {
      // Handle reordering within the same semester
      if (fromYear === toYear && fromSemester === toSemester && targetIndex !== null) {
        setFinalizedOrganizedCourses((prev) => {
          const newCourses = { ...prev };
          const semesterCourses = [...newCourses[`year${fromYear}`][fromSemester]];
  
          // Remove and reinsert the course at the new position
          const [movedCourse] = semesterCourses.splice(
            semesterCourses.findIndex((c) => c.courseInfo_courseNumber === course.courseInfo_courseNumber),
            1
          );
          semesterCourses.splice(targetIndex, 0, movedCourse);
  
          newCourses[`year${fromYear}`][fromSemester] = semesterCourses;
          recheckPrerequisites(newCourses);
          return newCourses;
        });
        return;
      }
  
      // Existing logic for moving between different semesters
      const userId = currentUser?.uid;
      const userRef = db.collection('users').doc(userId);
  
      // Set a unique toast ID for managing toast lifecycle
      const loadingToastId = 'move-course-toast';
  
      // Show a loading toast with custom width and a minimum display duration of 500ms
      if (!toast.isActive(loadingToastId)) {
        toast.loading('Moving course', {
          toastId: loadingToastId,
          autoClose: false,
          className: 'custom-toast custom-toast-loading',
          position: 'top-center',
          style: {
            width: '12rem', // Set your desired width
          },
        });
      }
  
      try {
        let fromSemesterKey = fromSemester === 'Fall' ? 'S1' : 'S2';
        let toSemesterKey = toSemester === 'Fall' ? 'S1' : 'S2';
  
        const batch = db.batch(); // Create a new batch instance
  
        const userDoc = await userRef.get();
        if (!userDoc.exists) {
          throw new Error('User document does not exist!');
        }
  
        const userData = userDoc.data();
        const finalizedSchedule = userData.schedules[activeTab] || {};
  
        const fromCourses = finalizedSchedule[`year${fromYear}`]?.[fromSemesterKey]?.courses || [];
        const toCourses = finalizedSchedule[`year${toYear}`]?.[toSemesterKey]?.courses || [];
  
        // Remove the course from the original semester
        const updatedFromCourses = fromCourses.filter(
          (id) => id !== course.courseInfo_courseNumber
        );
  
        // Only add the course to the new semester if it doesn't already exist there
        const updatedToCourses = toCourses.includes(course.courseInfo_courseNumber)
          ? toCourses
          : [...toCourses, course.courseInfo_courseNumber];
  
        // Batch the removal of the course from the original semester
        batch.update(userRef, {
          [`schedules.${activeTab}.year${fromYear}.${fromSemesterKey}.courses`]: updatedFromCourses,
        });
  
        // Batch the addition of the course to the new semester
        batch.update(userRef, {
          [`schedules.${activeTab}.year${toYear}.${toSemesterKey}.courses`]: updatedToCourses,
        });
  
        // Commit the batch
        await batch.commit();
  
        // Update the local state to reflect the changes
        setFinalizedOrganizedCourses((prev) => {
          const newCourses = { ...prev };
          newCourses[`year${fromYear}`][fromSemester] = newCourses[`year${fromYear}`][fromSemester].filter(
            (courseItem) => courseItem.courseInfo_courseNumber !== course.courseInfo_courseNumber
          );
  
          // If targetIndex is provided, insert the course at the correct position; otherwise, add to the end
          if (targetIndex !== null) {
            const updatedSemesterCourses = [...newCourses[`year${toYear}`][toSemester]];
            updatedSemesterCourses.splice(targetIndex, 0, course);
            newCourses[`year${toYear}`][toSemester] = updatedSemesterCourses;
          } else {
            newCourses[`year${toYear}`][toSemester] = [
              ...new Set([...newCourses[`year${toYear}`][toSemester], course]),
            ];
          }
  
          recheckPrerequisites(newCourses);
  
          return newCourses;
        });
  
        // Update the toast to success status
        setTimeout(() => {
          toast.update(loadingToastId, {
            render: 'Course moved',
            type: 'success',
            autoClose: 1000,
            isLoading: false,
            hideProgressBar: true,
            style: {
              width: '12rem', // Ensure the success toast keeps the same width
            },
          });
        }, 500); // Delay the transition by 500ms to keep the loading state visible
      } catch (error) {
        // Show an error toast if the operation fails, ensuring the loading toast stays for at least 500ms
        setTimeout(() => {
          toast.update(loadingToastId, {
            render: 'Failed to move course.',
            type: 'error',
            autoClose: 3000,
            isLoading: false,
            style: {
              width: '20rem', // Ensure the error toast keeps the same width
            },
          });
        }, 500);
        console.error('Failed to move course:', error);
      }
    },
    [currentUser, recheckPrerequisites, activeTab]
  );

  const handleRemove = useCallback(async (classToRemove, year, sem) => {
    const removedCourseNumber = classToRemove.courseInfo_courseNumber;
    try {
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);
        let semesterCode = sem === "Fall" ? "S1" : "S2";

        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }

          const userData = userDoc.data();
          const previousFinalizedSchedule = userData.schedules[activeTab] || {};
          const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
          const newUpdateObj = {
            ...updateObj,
            [`year${year}`]: {
              ...updateObj[`year${year}`],
              [semesterCode]: {
                ...updateObj[`year${year}`][semesterCode],
                courses: updateObj[`year${year}`][semesterCode].courses.filter(item => item !== removedCourseNumber)
              }
            }
          };

          transaction.update(userRef, {
            [`schedules.${activeTab}`]: newUpdateObj
          }, { lastUpdateTime: userDoc.updateTime });

        }).catch(error => {
          console.error('Transaction failed:', error);
          throw error;
        });

        setFinalizedOrganizedCourses(prev => {
          const newCourses = { ...prev };
          newCourses[`year${year}`][sem] = newCourses[`year${year}`][sem].filter(course => course.courseInfo_courseNumber !== removedCourseNumber);

          recheckPrerequisites(newCourses);

          return newCourses;
        });

      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error handling removal:', error);
    }
  }, [currentUser, recheckPrerequisites, activeTab]);

  const handleAutofillCourse = useCallback(async (yearNumber, semester) => {

        // Set a unique toast ID for managing toast lifecycle
        const loadingToastId = 'autofill-course-toast';

        // Show a loading toast with custom width and a minimum display duration of 500ms
        if (!toast.isActive(loadingToastId)) {
            toast.loading('Autofilling courses', {
                toastId: loadingToastId,
                autoClose: false,
                className: 'custom-toast custom-toast-loading',
                position: 'top-center',
                style: {
                    width: '12rem', // Set your desired width
                },
            });
        }

    try {
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);
        console.log('This is the autofilled user: ', currentUser.uid, userRef);
        await db.runTransaction(async (transaction) => {
          const userData = await transaction.get(userRef);
          if (!userData.exists) {
            throw new Error('User data not found');
          }
          let semesterCode = semester === "Fall" ? "S1" : "S2";
          const previousFinalizedSchedule = userData.data().schedules[activeTab] || {};

          const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
          
          if (!updateObj[`year${yearNumber}`]) {
            updateObj[`year${yearNumber}`] = {};
          }
          if (!updateObj[`year${yearNumber}`][semesterCode]) {
            updateObj[`year${yearNumber}`][semesterCode] = { courses: [] };
          }

          const existingFinalizedSchedule = updateObj[`year${yearNumber}`][semesterCode].courses;

          const allFinalizedCourses = new Set();
          for (const year in finalizedOrganizedCourses) {
            for (const sem in finalizedOrganizedCourses[year]) {
              finalizedOrganizedCourses[year][sem].forEach(course => {
                allFinalizedCourses.add(course.courseInfo_courseNumber);
              });
            }
          }

          let allClassNumbers = [];

          for (const year in updateObj) {
            for (const semester in updateObj[year]) {
              allClassNumbers = allClassNumbers.concat(updateObj[year][semester].courses);
            }
          }

          const allAutofillableCourses = allCourses.filter(course => 
              (course.courseYear <= 4) &&
              (course.Offering.includes(semester)) &&
              !allFinalizedCourses.has(course.courseInfo_courseNumber) &&
              (course.isPrereqMet)
          );


          // Major Courses

          const combinedSemesterMajorCourses = [];

          Object.entries(majorCourses).forEach(([majorName, courseInfo]) => {
            const organizedMajorCourses = organizeMajorCoursesByYearAndSemester(courseInfo);
            if (organizedMajorCourses && organizedMajorCourses[yearNumber]) {
              combinedSemesterMajorCourses.push(...(organizedMajorCourses[yearNumber][semester] || []));
            }
          });

          let semesterMajorCourses;

          if (combinedSemesterMajorCourses.length > 0) {
            semesterMajorCourses = combinedSemesterMajorCourses;
          } else {
            semesterMajorCourses = [];
          }

          const semesterMajorCoursesWithAutofill = semesterMajorCourses.map(course => ({
            ...course,
            autofillType: "Major Course",
          }));



          // Major Electives

          let combinedElectiveCourses = [];

          // Iterate through each year and combine the elective courses for the specified semester
          Object.entries(majorElectives).forEach(([majorName, electivesByMajor]) => {
            if (electivesByMajor && electivesByMajor[`year${yearNumber}`]) {
              combinedElectiveCourses.push(...electivesByMajor[`year${yearNumber}`][semester]);
            }
          });

          const electiveNameStrings = combinedElectiveCourses.map(course => course.Possibilities);

          const semesterElectiveRequirements = electiveNameStrings.reduce((acc, name) => {
            if (!acc[name]) {
              const course = combinedElectiveCourses.find(course => course.Possibilities === name);
              const electiveFullName = course ? course.Requirements : '';
              acc[name] = { quantity: 0, electiveFullName: electiveFullName };
            }
            acc[name].quantity += 1;

            return acc;
          }, {});

          let autofillElectiveCourses = [];

          Object.entries(semesterElectiveRequirements).forEach(([requirementName, { quantity, electiveFullName }]) => {
            const existingCourses = existingFinalizedSchedule
              .map(courseNumber =>allCourses .find(course => course.courseInfo_courseNumber === courseNumber))
              .filter(course => course && course.Elective_fulfillment.includes(requirementName));

            const remainingQuantity = quantity - existingCourses.length;

            if (remainingQuantity > 0) {
              const filteredCourses = allAutofillableCourses.filter(course => {
                return course.Elective_fulfillment.includes(requirementName) && !allClassNumbers.includes(course.courseInfo_courseNumber);
              });

              const sortedCourses = filteredCourses.sort((a, b) => b.recScore - a.recScore);

              const topCourses = sortedCourses.slice(0, remainingQuantity).map(course => ({
                ...course,
                electiveRequirement: requirementName,
                electiveFullName,
              }));

              autofillElectiveCourses.push(...topCourses);
            }
          });

          const autofillElectiveCoursesWithAutofill = autofillElectiveCourses.map(course => ({
            ...course,
            autofillType: 'Elective',
          }));



          // Major Requirements


          let combinedRequirementCourses = [];

          // Iterate through each year and combine the requirement courses for the specified semester
          Object.entries(majorRequirementCourses).forEach(([majorName, requirementsByMajor]) => {
            if (requirementsByMajor && requirementsByMajor[`year${yearNumber}`]) {
              combinedRequirementCourses.push(...requirementsByMajor[`year${yearNumber}`][semester]);
            }
          });

          const requirementNameStrings = combinedRequirementCourses.map(course => course.Requirements);

          const semesterRequirementDetails = requirementNameStrings.reduce((acc, name) => {
            if (!acc[name]) {
              const course = combinedRequirementCourses.find(course => course.Requirements === name);
              const possibleCourses = course ? course.Possibilities.split(',').map(course => course.trim()) : [];
              acc[name] = { quantity: 0, possibleCourses: possibleCourses };
            }
            acc[name].quantity += 1;

            return acc;
          }, {});

          let autofillRequirementCourses = [];

          Object.entries(semesterRequirementDetails).forEach(([requirementName, { quantity, possibleCourses }]) => {
            const commonElementsCount = possibleCourses.filter(course => existingFinalizedSchedule.includes(course)).length;

            const remainingQuantity = quantity - commonElementsCount;

            if (remainingQuantity > 0) {
              const filteredCourses = possibleCourses
                .map(courseNumber => allAutofillableCourses.find(course => course.courseInfo_courseNumber === courseNumber && !allClassNumbers.includes(courseNumber)))
                .filter(course => course !== undefined);

              const sortedCourses = filteredCourses.sort((a, b) => b.recScore - a.recScore);

              const topCourses = sortedCourses.slice(0, remainingQuantity).map(course => ({
                ...course,
                requirementName: requirementName,
              }));

              autofillRequirementCourses.push(...topCourses);
            }
          });

          const autofillRequirementCoursesWithAutofill = autofillRequirementCourses.map(course => ({
            ...course,
            autofillType: 'Requirement',
          }));



          // Minor Courses

          let autofillMinorCoursesWithAutofill = [];

          let combinedMinorCourses = [];

          // Iterate through each year and combine the requirement courses for the specified semester
          Object.entries(minorCourses).forEach(([minorName, requirementsByMinor]) => {
            if (requirementsByMinor && requirementsByMinor[`year${yearNumber}`]) {
              combinedMinorCourses.push(...requirementsByMinor[`year${yearNumber}`][semester]);
            }
          });

          if (combinedMinorCourses.length > 0) {
            const autofillMinorCoursesArray = combinedMinorCourses.map(course => course.courseInfo_courseNumber);

            const autofillMinorCoursesUnique = autofillMinorCoursesArray
              .map(courseNumber => allAutofillableCourses.find(course =>
                course.courseInfo_courseNumber === courseNumber && !allClassNumbers.includes(courseNumber)
              ))
              .filter(course => course !== undefined);

            autofillMinorCoursesWithAutofill = autofillMinorCoursesUnique.map(course => ({
              ...course,
              autofillType: 'Minor Course',
            }));
          }



          // Minor Requirements


          let combinedMinorRequirementCourses = [];

          // Iterate through each minor and combine the minor requirement courses for the specified year and semester
          Object.entries(minorRequirementCourses).forEach(([minorName, requirementsByMinor]) => {
            if (requirementsByMinor && requirementsByMinor[`year${yearNumber}`] && requirementsByMinor[`year${yearNumber}`][semester]) {
              combinedMinorRequirementCourses.push(...requirementsByMinor[`year${yearNumber}`][semester]);
            }
          });

          let autofillMinorRequirementCourses = [];

          if (combinedMinorRequirementCourses.length > 0) {
            const requirementMinorNameStrings = combinedMinorRequirementCourses.map(course => course.Requirements);

            const semesterMinorRequirementDetails = requirementMinorNameStrings.reduce((acc, name) => {
              if (!acc[name]) {
                const course = combinedMinorRequirementCourses.find(course => course.Requirements === name);
                const possibleCourses = course ? course.Possibilities.split(',').map(course => course.trim()) : [];
                acc[name] = { quantity: 0, possibleCourses: possibleCourses };
              }
              acc[name].quantity += 1;

              return acc;
            }, {});


            Object.entries(semesterMinorRequirementDetails).forEach(([requirementName, { quantity, possibleCourses }]) => {
              const commonElementsCount = possibleCourses.filter(course => existingFinalizedSchedule.includes(course)).length;

              const remainingQuantity = quantity - commonElementsCount;

              if (remainingQuantity > 0) {
                const filteredCourses = possibleCourses
                  .map(courseNumber => allAutofillableCourses.find(course =>
                    course.courseInfo_courseNumber === courseNumber && !allClassNumbers.includes(courseNumber)
                  ))
                  .filter(course => course !== undefined);

                const sortedCourses = filteredCourses.sort((a, b) => b.recScore - a.recScore);

                const topCourses = sortedCourses.slice(0, remainingQuantity).map(course => ({
                  ...course,
                  requirementName: requirementName,
                }));

                autofillMinorRequirementCourses.push(...topCourses);
              }
            });
          }


          const autofillMinorRequirementCoursesWithAutofill = autofillMinorRequirementCourses.map(course => ({
            ...course,
            autofillType: 'Requirement',
          }));

          const semesterAutofillCourses = [...semesterMajorCoursesWithAutofill, ...autofillElectiveCoursesWithAutofill, ...autofillRequirementCoursesWithAutofill, ...autofillMinorCoursesWithAutofill, ...autofillMinorRequirementCoursesWithAutofill];

          const uniqueAutofillCourses = semesterAutofillCourses.reduce((acc, current) => {
            const x = acc.find(item => item.courseInfo_courseNumber === current.courseInfo_courseNumber);
            if (!x) {
              acc.push(current);
            }
            return acc.filter(course => !allClassNumbers.includes(course.courseInfo_courseNumber));
          }, []);    
          
          const semesterAutofillCoursesWithStatus = uniqueAutofillCourses.map(course => ({
            ...course,
            isAutofill: true,
          }));

          const semesterAutofillCourseIds = semesterAutofillCoursesWithStatus.map(course => course.courseInfo_courseNumber);
          const updatedFinalizedSchedule = [...new Set([...existingFinalizedSchedule, ...semesterAutofillCourseIds])];
          updateObj[`year${yearNumber}`][semesterCode].courses = updatedFinalizedSchedule;

          transaction.update(userRef, {
            [`schedules.${activeTab}.year${yearNumber}.${semesterCode}.courses`]: updatedFinalizedSchedule,
          });


          setFinalizedOrganizedCourses(prev => {
            const newCourses = { ...prev };

            const existingCourseNumbers = newCourses?.[`year${yearNumber}`]?.[semester]?.map(course => course?.courseInfo_courseNumber) ?? [];

            const filteredCourses = semesterAutofillCoursesWithStatus.filter(course => !existingCourseNumbers.includes(course.courseInfo_courseNumber));

            // Ensure newCourses[`year${yearNumber}`] is defined
            newCourses[`year${yearNumber}`] = newCourses[`year${yearNumber}`] ?? {};

            // Ensure newCourses[`year${yearNumber}`][semester] is defined
            newCourses[`year${yearNumber}`][semester] = newCourses[`year${yearNumber}`][semester] ?? [];

            // Now safely update newCourses[`year${yearNumber}`][semester]
            newCourses[`year${yearNumber}`][semester] = [...new Set([...newCourses[`year${yearNumber}`][semester], ...filteredCourses])];

            recheckPrerequisites(newCourses);

            return newCourses;
          });

          console.log('Courses Autofilled Successfully.');
                // Update the toast to success status
                setTimeout(() => {
                    toast.update(loadingToastId, {
                        render: 'Autofilled courses',
                        type: 'success',
                        autoClose: 1000,
                        isLoading: false,
                        hideProgressBar: true,
                        style: {
                            width: '12rem', // Ensure the success toast keeps the same width
                        },
                    });
                }, 500); // Delay the transition by 500ms to keep the loading state visible
            });
        } else {
            console.error('Error: currentUser or currentUser.uid is not defined');
        }
    } catch (error) {
        // Show an error toast if the operation fails, ensuring the loading toast stays for at least 500ms
        setTimeout(() => {
            toast.update(loadingToastId, {
                render: 'Failed to autofill courses.',
                type: 'error',
                autoClose: 3000,
                isLoading: false,
                style: {
                    width: '20rem', // Ensure the error toast keeps the same width
                },
            });
        }, 500);
        console.error('Error autofilling courses:', error);
    }
}, [currentUser, majorCourses, organizeMajorCoursesByYearAndSemester, recheckPrerequisites, activeTab]);

  const handleAutofillAllCourses = useCallback(async () => {
    setIsAutofilling(true);
    try {
      const years = activePlanNumYears >= 4 ? [1, 2, 3, 4] : Array.from({ length: numYears }, (_, index) => index + 1);
      const semesters = ["Fall", "Spring"];

      for (const yearNumber of years) {
        for (const semester of semesters) {
          let semesterCode = semester === "Fall" ? "S1" : "S2";
          const isCompleted = (completedSemesters) && (completedSemesters[`year${yearNumber}`]) && (completedSemesters[`year${yearNumber}`][semesterCode]) && (completedSemesters[`year${yearNumber}`][semesterCode] == true)
          if (!isCompleted){
            await handleAutofillCourse(yearNumber, semester);
          }
        }
      }
    } catch (error) {
      console.error('Error autofilling all courses:', error);
    } finally {
      setIsAutofilling(false);
    }
  }, [handleAutofillCourse, numYears]);

  const organizePrereqCourses = useCallback(() => {
    const prereqCourses = {};
    for (let i = 1; i <= numYears; i++) {
      if (!prereqCourses[i]) {
        prereqCourses[i] = [];
      }
      prereqCourses[i].push(...middleschoolClasses);
      finalizedCourses.forEach((course) => {
        const year = course.courseYear || 'Uncategorized';
        if (year < i) {
          prereqCourses[i].push(course.courseInfo_courseNumber);
        }
      });
    }
    return prereqCourses;
  }, [middleschoolClasses, finalizedCourses, numYears]);

  const prereqClasses = organizePrereqCourses();

  const getTotalCreditsForSemester = useCallback((courses) => {
    if (!courses) {
      return 0;
    }
    return courses.reduce((total, course) => {
      const credits = course.Credits === "N/A" ? 0 : course.Credits;
      return total + credits;
    }, 0);
  }, []);



  const getTotalCreditsForSchedule = useCallback((allOrganizedCourses) => {

    let studentCourses = [];
    let totalCreditsCount = 0;
    let upperDivisionCreditsCount = 0;

    for (const year in allOrganizedCourses) {
      if (allOrganizedCourses.hasOwnProperty(year)) {
        const semesters = allOrganizedCourses[year];
        for (const semester in semesters) {
          if (semesters.hasOwnProperty(semester)) {
            const courses = semesters[semester];
            studentCourses = studentCourses.concat(courses);
            const semesterCreditsCount = courses.reduce((total, course) => {
              return total + (course.Credits === 'N/A' || course.Credits === undefined ? 0 : Number(course.Credits));
            }, 0);          
            totalCreditsCount += semesterCreditsCount;
            const upperDivisionSemesterCreditsCount = courses
              .filter(course => {
                // Extract the first character after a space in courseInfo_courseNumber
                const match = course.courseInfo_courseNumber.match(/\s(\d)/);
                const courseYear = match ? parseInt(match[1], 10) : 1;
                return courseYear > 2;
              })
              .reduce((total, course) => {
                return total + (course.Credits === 'N/A' || course.Credits === undefined ? 0 : Number(course.Credits));
              }, 0);
            upperDivisionCreditsCount += upperDivisionSemesterCreditsCount;
          }
        }
      }
    }

    // UNUSED LOGIC FOR NOW, WILL IMPLEMENT LATER FOR CREDIT OVERVIEW


    
    /*function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
      courses.sort((a, b) => {
        if (a.courseInfo_courseNumber < b.courseInfo_courseNumber) return -1;
        if (a.courseInfo_courseNumber > b.courseInfo_courseNumber) return 1;
        return 0;
      });

      const usedCoursesForRequirements = [];
      let plannedRequirementCoursesArray = [];
      let largestValidArray = [];
  
      function backtrack(requirementIndex) {
          if (requirementIndex === majorCourses.length) {
              // Update the largestValidArray if the current array is larger
              if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                  largestValidArray = [...plannedRequirementCoursesArray];
              }
              return; // Continue checking other requirements
          }
  
          const majorCourse = majorCourses[requirementIndex];
          
          if ((majorCourse.courseType === 'Major Course' && majorCourse.Requirements) || (majorCourse.courseType === 'Requirement')) {
              const possibleCourses = majorCourse.Possibilities.split(',').map(course => course.trim());
  
              let foundValidCourse = false;
  
              for (let i = 0; i < possibleCourses.length; i++) {
                  const courseNumber = possibleCourses[i];
                  
                  const matchingCourseIndex = courses.findIndex(course =>
                      course.courseInfo_courseNumber === courseNumber &&
                      !nonUsableCourses.includes(courseNumber) &&
                      !usedCoursesForRequirements.includes(courseNumber)
                  );
  
                  if (matchingCourseIndex !== -1) {
                      // Choose this course for the current requirement
                      plannedRequirementCoursesArray.push(courseNumber);
                      usedCoursesForRequirements.push(courseNumber);
                      foundValidCourse = true;
                      
                      // Recursively attempt to satisfy the next requirement
                      if (backtrack(requirementIndex + 1)) {
                          return; // Continue to the next valid solution
                      }
  
                      // Backtrack: un-choose the course and try the next possibility
                      plannedRequirementCoursesArray.pop();
                      usedCoursesForRequirements.pop();
                  }
              }
  
              // Update the largest valid array if the current array is larger
              if (plannedRequirementCoursesArray.length > largestValidArray.length) {
                  largestValidArray = [...plannedRequirementCoursesArray];
              }
  
              // Continue checking other possible courses even if no valid course was found
              if (!foundValidCourse) {
                  backtrack(requirementIndex + 1);
              }
          } else {
              // If not a major course with requirements, just move to the next one
              backtrack(requirementIndex + 1);
          }
      }
  
      backtrack(0);
      return largestValidArray;
    }                    

    function findElectiveCourses(majorCourses, courses) {
      courses.sort((a, b) => {
        if (a.courseInfo_courseNumber < b.courseInfo_courseNumber) return -1;
        if (a.courseInfo_courseNumber > b.courseInfo_courseNumber) return 1;
        return 0;
      });
        const usedCoursesForElectives = [];
        let takenElectiveCoursesArray = [];
        let largestValidArray = [];
    
        function backtrack(electiveIndex) {
            if (electiveIndex === majorCourses.length) {
                // Update the largestValidArray if the current array is larger
                if (takenElectiveCoursesArray.length > largestValidArray.length) {
                    largestValidArray = [...takenElectiveCoursesArray];
                }
                return; // Continue checking other courses
            }
    
            const majorCourse = majorCourses[electiveIndex];
            
            if (majorCourse.courseType === 'Elective') {
                const electiveName = majorCourse.Possibilities;
    
                let foundValidCourse = false;
    
                for (let i = 0; i < courses.length; i++) {
                    const studentCourse = courses[i];
                    
                    if (
                        studentCourse.Elective_fulfillment.includes(electiveName) &&
                        !usedCoursesForElectives.includes(studentCourse.courseInfo_courseNumber)
                    ) {
                        // Choose this course for the current elective
                        takenElectiveCoursesArray.push(studentCourse.courseInfo_courseNumber);
                        usedCoursesForElectives.push(studentCourse.courseInfo_courseNumber);
                        foundValidCourse = true;
                        break; // Stop searching since we found a valid course
                    }
                }
    
                // Update the largest valid array if the current array is larger
                if (takenElectiveCoursesArray.length > largestValidArray.length) {
                    largestValidArray = [...takenElectiveCoursesArray];
                }
    
                // Continue to the next elective even if no valid course was found
                backtrack(electiveIndex + 1);
            } else {
                // If not an elective, just move to the next one
                backtrack(electiveIndex + 1);
            }
        }
    
        backtrack(0);
        return largestValidArray;
    } 

    const allMajorCoursesArray = studentCourses.filter(course => {
      const isInMajorCourses = majorData.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
      return isInMajorCourses;
    });

    const allElectiveCoursesArray = findElectiveCourses(
        majorData.filter(course => course.courseType === 'Elective'),
        studentCourses
    );
    
    const allRequirementCoursesArray = findRequirementCourses(
        majorData.filter(course => (course.courseType === 'Major Course' && course.Requirements) || (course.courseType === 'Requirement')),
        studentCourses,
        allElectiveCoursesArray
    ); */

    // const allRequirementCourseObjects = allCourses.filter(course => allRequirementCoursesArray.includes(course.courseInfo_courseNumber))

    /*const allMajorCourses = [...allMajorCoursesArray, ...allElectiveCoursesArray, ...allRequirementCourseObjects]

    let majorCreditsCount = allMajorCourses.reduce((total, course) => {
      return total + (course.Credits === 'N/A' || course.Credits === undefined ? 0 : Number(course.Credits));
    }, 0);*/
  
    return {/*creditsTowardsMajor: majorCreditsCount, */totalCredits: totalCreditsCount, majorRequiredCredits: majorPathwayData.OverallCR, upperDivisionCredits: upperDivisionCreditsCount, majorRequiredUpperDivisionCredits: majorPathwayData.UDCR};

  }, [currentUser, activeTab]);


  const CreditsOverview = ({ finalizedOrganizedCourses }) => {
    const [finalizedOrganizedCoursesCreditsOverview, setFinalizedOrganizedCoursesCreditsOverview] = useState(finalizedOrganizedCourses);
  
    useEffect(() => {
      setFinalizedOrganizedCoursesCreditsOverview(finalizedOrganizedCourses);
    }, [finalizedOrganizedCourses]);
  
    let {
      // creditsTowardsMajor,
      totalCredits,
      majorRequiredCredits,
      upperDivisionCredits,
      majorRequiredUpperDivisionCredits
    } = getTotalCreditsForSchedule(finalizedOrganizedCoursesCreditsOverview);

    if (!majorRequiredCredits || majorRequiredCredits == 0){
      majorRequiredCredits = 120
    }

    if (!majorRequiredUpperDivisionCredits || majorRequiredUpperDivisionCredits == 0){
      majorRequiredUpperDivisionCredits = 45
    }
  
    return (
      <div className="p-2 max-w-full mx-auto bg-whites rounded-lg">
        {/*<h2 className="text-lg font-semibold mb-4">Credit Overview</h2>*/}
        <div className="flex flex-wrap justify-between gap-2">
        <p className="text-sm">
          <span
            className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center ${
              totalCredits >= majorRequiredCredits
                ? 'bg-green-100 text-green-700'
                : totalCredits / majorRequiredCredits > 0.4
                ? 'bg-yellow-100 text-yellow-700'
                : 'bg-red-100 text-red-700'
            }`}
          >
            Total Credits: {totalCredits}/{majorRequiredCredits}
          </span>
        </p>

        {/*<p className="text-sm">
          <span className="font-medium">Counting Towards Major:</span> {creditsTowardsMajor}
        </p>*/}

        <p className="text-sm">
          <span
            className={`bg-opacity-50 text-sm font-semibold rounded-lg px-2 py-1 mr-2 flex items-center ${
              upperDivisionCredits >= majorRequiredUpperDivisionCredits
                ? 'bg-green-100 text-green-700'
                : upperDivisionCredits / majorRequiredUpperDivisionCredits > 0.4
                ? 'bg-yellow-100 text-yellow-700'
                : 'bg-red-100 text-red-700'
            }`}
          >
            Upper Division Credits: {upperDivisionCredits}/{majorRequiredUpperDivisionCredits}
          </span>
        </p>
        </div>
      </div>
    );    
  };
  
  const DraggableCourse = ({
    course,
    yearNumber,
    semester,
    onRemove,
    moveCourse,
    onAlternativesClick,
    isCompleted,
    onEvaluate,
    viewMode,
    prereqClasses,
  }) => {
    const dragRef = useRef(null); // Reference to the drag element
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 }); // State to store the offset
  
    // Define the drag properties
    const [{ isDragging }, drag, preview] = useDrag(() => ({
      type: 'course',
      item: { course, fromYear: yearNumber, fromSemester: semester },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          moveCourse(
            item.course,
            item.fromYear,
            item.fromSemester,
            dropResult.toYear,
            dropResult.toSemester
          );
        }
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: !isCompleted,
    }));
  
    // Capture the mouse offset when the drag starts
    const handleDragStart = (event) => {
      const rect = dragRef.current.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;
      setDragOffset({ x: offsetX, y: offsetY });
    };
  
    // Set up a custom drag preview that maintains the drag offset
    useEffect(() => {
      if (dragRef.current) {
        preview(dragRef.current, {
          anchorX: dragOffset.x,
          anchorY: dragOffset.y,
          captureDraggingState: true,
        });
      }
    }, [preview, dragOffset]);
  
    return (
      <div
        ref={isCompleted ? null : drag}
        className={`draggableCourse ${isDragging ? 'dragging' : ''}`}
        onDragStart={handleDragStart} // Set the drag start handler to capture offset
      >
        <div ref={dragRef}> {/* This ref is used to control the drag preview */}
          <FinalizedCoursesNew
            onAlternativesClick={onAlternativesClick}
            finalizedCourses={[course]}
            prereqClasses={prereqClasses}
            yearNumber={yearNumber}
            semester={semester}
            onRemove={() => onRemove(course, yearNumber, semester)}
            isCompleted={isCompleted}
            onEvaluate={onEvaluate}
            viewMode={viewMode}
            isDragging={isDragging} // Pass down the dragging state
          />
        </div>
      </div>
    );
  };
  
  
  const DroppableSemester = ({ children, yearNumber, semester }) => {
    const isCompleted = completedSemesters[`year${yearNumber}`]?.[semester === 'Fall' ? 'S1' : 'S2'];
  
    const [, drop] = useDrop({
      accept: 'course',
      drop: () => {
        // Prevent dropping into a completed semester by returning undefined
        return isCompleted ? undefined : { toYear: yearNumber, toSemester: semester };
      },
      canDrop: () => !isCompleted, // Controls whether the drop zone appears active or not
    });
  
    return <div ref={drop}>{children}</div>;
  };  

  const handleAddMilestone = (year, semester) => {
    console.log('Adding milestone for:', { year, semester });
    setCurrentSemester({ year, semester });
    setShowDrawer(true);
  };  
  
  const handleOpenAllMilestonesDrawer = () => {
    setShowAllMilestonesDrawer(true);
  };  

  const handleSaveMilestone = async (title, description, dueDate) => {
    console.log('Saving milestone with currentSemester:', currentSemester);
    if (!title || !description || !dueDate || !currentSemester || !currentSemester.year || !currentSemester.semester) {
        console.error('Invalid currentSemester:', currentSemester);
        return;
    }

    const newMilestone = {
        Milestone_ID: `milestone_${Date.now()}`,
        Title: title,
        Description: description,
        DueDate: dueDate,
        Milestone_Year: currentSemester.year,
        Milestone_Sem: currentSemester.semester === 'Fall' ? 'S1' : 'S2',
        createdAt: new Date().toISOString(),
        completed: false,
    };

    try {
        if (currentUser && currentUser.uid) {
            const userDocRef = db.collection('users').doc(currentUser.uid);
            await db.runTransaction(async (transaction) => {
                const userDoc = await transaction.get(userDocRef);
                if (userDoc.exists) {
                    const userData = userDoc.data();
                    const activeTab = userData.activeTab || 'default'; // Use activeTab to store under the correct plan/tab
                    const year = `year${currentSemester.year}`;
                    const semester = currentSemester.semester === 'Fall' ? 'S1' : 'S2';

                    const updatedMilestones = [
                        ...(userData.finalizedMilestones?.[activeTab]?.[year]?.[semester]?.milestones || []),
                        newMilestone,
                    ];

                    transaction.set(userDocRef, {
                        finalizedMilestones: {
                            ...userData.finalizedMilestones,
                            [activeTab]: {
                                ...userData.finalizedMilestones?.[activeTab],
                                [year]: {
                                    ...userData.finalizedMilestones?.[activeTab]?.[year],
                                    [semester]: { milestones: updatedMilestones },
                                },
                            },
                        },
                    }, { merge: true });
                }
            });

            // Update the state with the new milestone
            setFinalizedMilestones((prev) => [...prev, newMilestone]);
            setShowModal(false);
        }
    } catch (error) {
        console.error('Error saving milestone:', error);
    }
};

  const handleRemoveMilestone = async (milestoneToRemove) => {
    try {
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default';
            const year = `year${milestoneToRemove.Milestone_Year}`;
            const semester = milestoneToRemove.Milestone_Sem === 'S1' ? 'S1' : 'S2';
            const updatedMilestones = userData.finalizedMilestones[activeTab][year][semester].milestones.filter(
              (milestone) => milestone.Milestone_ID !== milestoneToRemove.Milestone_ID
            );

            if (updatedMilestones.length === 0) {
              // If there are no milestones left in this semester, remove the semester
              delete userData.finalizedMilestones[activeTab][year][semester];
              
              // Check if the year object is empty after removing the semester
              if (Object.keys(userData.finalizedMilestones[activeTab][year]).length === 0) {
                delete userData.finalizedMilestones[activeTab][year];
              }

              // Check if the activeTab object is empty after removing the year
              if (Object.keys(userData.finalizedMilestones[activeTab]).length === 0) {
                delete userData.finalizedMilestones[activeTab];
              }
            } else {
              // Otherwise, update the milestones for that semester
              userData.finalizedMilestones[activeTab][year][semester].milestones = updatedMilestones;
            }

            transaction.update(userDocRef, { finalizedMilestones: userData.finalizedMilestones });

            // Update local state
            setFinalizedMilestones((prev) => prev.filter((milestone) => milestone.Milestone_ID !== milestoneToRemove.Milestone_ID));
            setMilestonesByYearAndSemester((prev) => ({
              ...prev,
              [year]: {
                ...prev[year],
                [semester]: updatedMilestones,
              },
            }));
          }
        });
      }
    } catch (error) {
      console.error('Error removing milestone:', error);
    }
  };

  const handleToggleComplete = async (milestone, event) => {
    event.stopPropagation();
    try {
      const updatedMilestone = { ...milestone, completed: !milestone.completed };
  
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const userData = userDoc.data();
            const activeTab = userData.activeTab || 'default';
            const year = `year${milestone.Milestone_Year}`;
            const semester = milestone.Milestone_Sem === 'S1' ? 'S1' : 'S2';
            const milestoneIndex = userData.finalizedMilestones[activeTab][year][semester].milestones.findIndex(
              (m) => m.Milestone_ID === milestone.Milestone_ID
            );
  
            if (milestoneIndex > -1) {
              userData.finalizedMilestones[activeTab][year][semester].milestones[milestoneIndex] = updatedMilestone;
  
              transaction.update(userDocRef, { finalizedMilestones: userData.finalizedMilestones });
  
              setFinalizedMilestones((prev) =>
                prev.map((m) => (m.Milestone_ID === milestone.Milestone_ID ? updatedMilestone : m))
              );
              setMilestonesByYearAndSemester((prev) => ({
                ...prev,
                [year]: {
                  ...prev[year],
                  [semester]: userData.finalizedMilestones[activeTab][year][semester].milestones,
                },
              }));
            }
          }
        });
      }
    } catch (error) {
      console.error('Error toggling milestone completion:', error);
    }
  };

  useEffect(() => {
    const organizedMilestones = organizeMilestonesByYearAndSemester(finalizedMilestones);
    setMilestonesByYearAndSemester(organizedMilestones);
  }, [finalizedMilestones, organizeMilestonesByYearAndSemester]);

  const handleExportPlan = async () => {
    try {
      // Reset isUserDataFetched to allow fetchUserData to run
      if (isUserDataFetched && isUserDataFetched.current !== undefined) {
        isUserDataFetched.current = false;
      } else {
        console.error('isUserDataFetched is undefined or null');
      }
  
      // Call fetchUserData and wait for it to complete
      await fetchUserData(currentUser);
  
      // Set dataFetchComplete to true after data is fetched
      setDataFetchComplete(true);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    // Check if data fetching is complete before generating the PDF
    if (dataFetchComplete) {
      const generatePDF = async () => {
        try {
          const pdfBytes = await createPDFDocument(finalizedOrganizedCourses, currentUser);
          setPdfBytes(pdfBytes);
          setShowPdfModal(true);
        } catch (error) {
          console.error('Error generating PDF:', error);
        } finally {
          // Reset dataFetchComplete to prevent repeated exports
          setDataFetchComplete(false);
        }
      };
  
      generatePDF();
    }
  }, [dataFetchComplete, finalizedOrganizedCourses, currentUser]);  
  
  const closePdfModal = () => {
    setShowPdfModal(false);
    setPdfBytes(null);
  };

  const handleRemoveSemesterCourses = async (year, semester) => {
    if (currentUser && currentUser.uid) {
      const userRef = db.collection('users').doc(currentUser.uid);

      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }

          const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };

          updatedFinalizedSchedule[`year${year}`][semester].courses = [];

          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });

          const updatedCompletedSemesters = {
            ...completedSemesters,
            [`year${year}`]: {
              ...completedSemesters[`year${year}`],
              [semester]: false,
            },
          };
          transaction.update(userRef, { completedSemesters: updatedCompletedSemesters });
          setCompletedSemesters(updatedCompletedSemesters);
        });

        const semesterString = semester === 'S1' ? 'Fall' : 'Spring';
        setFinalizedOrganizedCourses((prev) => {
          const newCourses = {
            ...prev,
            [`year${year}`]: {
              ...prev[`year${year}`],
              [semesterString]: [],
            },
          };

          recheckPrerequisites(newCourses);

          return newCourses;
        });

        console.log(`Courses for Year ${year} ${semesterString} removed successfully`);
      } catch (error) {
        console.error('Failed to remove semester courses:', error);
      }
    } else {
      console.error('Error: currentUser or currentUser.uid is not defined');
    }
  };

  const handleRemoveAllCourses = async () => {
    if (currentUser && currentUser.uid) {
      const userRef = db.collection('users').doc(currentUser.uid);
  
      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };
          const numYearsValue = updatedFinalizedSchedule.numYears; // Save the numYears value
  
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedSchedule[yearKey] = {
              S1: fallCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S1 : { courses: [] },
              S2: springCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S2 : { courses: [] },
            };
          }
  
          updatedFinalizedSchedule.numYears = numYearsValue; // Re-assign the numYears value to ensure it is not removed
  
          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });
  
          const updatedFinalizedCourses = {};
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedCourses[yearKey] = {
              Fall: fallCompleted ? finalizedOrganizedCourses[yearKey]?.Fall : [],
              Spring: springCompleted ? finalizedOrganizedCourses[yearKey]?.Spring : [],
            };
          }
  
          setFinalizedOrganizedCourses(updatedFinalizedCourses);
  
          console.log('Courses removed successfully from incomplete semesters.');
        });
      } catch (error) {
        console.error('Failed to remove courses from incomplete semesters:', error);
      }
    } else {
      console.error('Error: currentUser or currentUser.uid is not defined');
    }
  };  


  const handleReshuffleCourses= async () => {
    if (currentUser && currentUser.uid) {
      const userRef = db.collection('users').doc(currentUser.uid);
  
      try {
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          /*const userData = userDoc.data();
          const updatedFinalizedSchedule = { ...userData.schedules[activeTab] };
          const numYearsValue = updatedFinalizedSchedule.numYears; // Save the numYears value
  
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedSchedule[yearKey] = {
              S1: fallCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S1 : { courses: [] },
              S2: springCompleted ? userDoc.data().schedules[activeTab][yearKey]?.S2 : { courses: [] },
            };
          }
  
          updatedFinalizedSchedule.numYears = numYearsValue; // Re-assign the numYears value to ensure it is not removed
  
          transaction.update(userRef, { [`schedules.${activeTab}`]: updatedFinalizedSchedule });
  
          const updatedFinalizedCourses = {};
          for (let i = 1; i <= numYears; i++) {
            const yearKey = `year${i}`;
            const fallCompleted = completedSemesters[yearKey]?.S1;
            const springCompleted = completedSemesters[yearKey]?.S2;
  
            updatedFinalizedCourses[yearKey] = {
              Fall: fallCompleted ? finalizedOrganizedCourses[yearKey]?.Fall : [],
              Spring: springCompleted ? finalizedOrganizedCourses[yearKey]?.Spring : [],
            };
          }
  
          setFinalizedOrganizedCourses(updatedFinalizedCourses);*/

          console.log('Clicked reshuffle')
  
          console.log('Courses removed successfully from incomplete semesters.');
        });
      } catch (error) {
        console.error('Failed to remove courses from incomplete semesters:', error);
      }
    } else {
      console.error('Error: currentUser or currentUser.uid is not defined');
    }
  };  

  const handleAddYear = async () => {
    try {
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const activeTabIndex = tabs.findIndex(tab => tab.name === activeTab);
          if (activeTabIndex === -1) return; // No active tab found
  
          const activeTabData = tabs[activeTabIndex];
          const activePlanNumYears = numYears;
          const updatedNumYears = activePlanNumYears + 1;
          console.log('Updated num years: ', activeTabData, activeTabData.numYears, updatedNumYears);
  
          const newYearKey = `year${updatedNumYears}`;
          const updatedTab = {
            ...activeTabData,
            numYears: updatedNumYears,
            [newYearKey]: {
              S1: { courses: [] },
              S2: { courses: [] },
            },
          };
  
          // Update Firestore document without adding a new 'data' key
          transaction.update(userRef, {
            [`schedules.${activeTab}.numYears`]: updatedNumYears,
            [`schedules.${activeTab}.${newYearKey}`]: {
              S1: { courses: [] },
              S2: { courses: [] },
            },
          });
  
          // Update the tabs array in state
          const updatedTabs = [...tabs];
          updatedTabs[activeTabIndex] = updatedTab;
  
          setTabs(updatedTabs);
          setFinalizedOrganizedCourses(prev => ({
            ...prev,
            [newYearKey]: {
              Fall: [],
              Spring: []
            }
          }));
  
          console.log('New year added to finalizedSchedule');
        });
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Failed to add new year:', error);
    }
  };
  
  

  const handleRemoveYear = async () => {
    try {
        if (currentUser && currentUser.uid) {
            const userRef = db.collection('users').doc(currentUser.uid);
            await db.runTransaction(async (transaction) => {
                const userDoc = await transaction.get(userRef);
                if (!userDoc.exists) {
                    throw new Error('User data not found');
                }

                const userData = userDoc.data();
                const activeTabIndex = tabs.findIndex(tab => tab.name === activeTab);
                if (activeTabIndex === -1) return; // No active tab found

                const activeTabData = tabs[activeTabIndex];
                const activePlanNumYears = numYears;

                if (activePlanNumYears <= 1) {
                    console.error('Cannot remove the last remaining year.');
                    return; // Prevent removing the last year
                }

                const updatedNumYears = activePlanNumYears - 1;
                console.log('Updated Num Years: ', updatedNumYears);

                // Remove the last year but keep its courses
                const lastYearKey = `year${activePlanNumYears}`;
                const removedCourses = {
                    Fall: finalizedOrganizedCourses[lastYearKey]?.Fall || [],
                    Spring: finalizedOrganizedCourses[lastYearKey]?.Spring || [],
                };

                // Optionally, handle these removedCourses (e.g., archive them, move them to another year, etc.)

                // Remove the milestones for the last year
                const updatedMilestones = { ...userData.finalizedMilestones };
                if (updatedMilestones[activeTab] && updatedMilestones[activeTab][lastYearKey]) {
                    delete updatedMilestones[activeTab][lastYearKey]; // Remove milestones for the year being deleted
                }

                // Update Firestore document with both schedules and milestones
                const updatedSchedules = {
                    ...userData.schedules,
                    [activeTab]: {
                        ...userData.schedules[activeTab],
                        numYears: updatedNumYears,
                    },
                };
                delete updatedSchedules[activeTab][lastYearKey];

                transaction.update(userRef, {
                    schedules: updatedSchedules,
                    finalizedMilestones: updatedMilestones, // Update milestones in Firestore
                });

                // Update the tabs array in state
                const updatedTabs = [...tabs];
                updatedTabs[activeTabIndex] = {
                    ...activeTabData,
                    numYears: updatedNumYears,
                };
                console.log('Tabs: ', updatedTabs);
                setTabs(updatedTabs);

                // Update finalizedOrganizedCourses but keep the courses
                setFinalizedOrganizedCourses(prev => {
                    const newCourses = { ...prev };
                    delete newCourses[lastYearKey];
                    // Optionally, handle removedCourses here (e.g., save them elsewhere)
                    return newCourses;
                });

                // Update finalizedMilestones in state to remove milestones for the removed year
                setFinalizedMilestones(prev => {
                    const newMilestones = { ...prev };
                    if (newMilestones[activeTab]) {
                        delete newMilestones[activeTab][lastYearKey]; // Remove milestones from local state
                    }
                    return newMilestones;
                });

                console.log('Last year removed from finalizedSchedule, milestones and courses preserved/handled');
            });
        } else {
            console.error('Error: currentUser or currentUser.uid is not defined');
        }
    } catch (error) {
        console.error('Failed to remove last year:', error);
    }
};


  const getYearAndSemesterName = useMemo(() => {
    if (!surveyAnswers?.SchoolStart?.year) return () => '';
  
    const startYear = Number(surveyAnswers.SchoolStart.year);
  
    const getYearName = (yearNumber) => {
      if (yearNumber < 1) return '';
      return startYear + (yearNumber - 1);
    };
  
    return getYearName;
  }, [surveyAnswers?.SchoolStart?.year]);

  // Updating the rendering logic to use the numYears for the active plan
  const activePlanNumYears = tabs[activeTab]?.numYears;

  const hasCoursesInSemester = (yearNumber, semester) => {
    const semesterKey = semester === 'Fall' ? 'Fall' : 'Spring';
    return (finalizedOrganizedCourses[`year${yearNumber}`]?.[semesterKey]?.length || 0) > 0;
  };

  if (loading) {
    return (
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  const handleDropdownToggle = (year, semester) => {
    const key = `${year}-${semester}`;
    setDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleAlternativesClick = (category, year, semester) => {
    setSelectedElectiveCategory(category);
    setElectiveYear(year);
    if (semester === 'Fall') {
      semester = 1;
    } else {
      semester = 2;
    }
    setElectiveSemester(semester);
    setShowSidebar(true);
  };

  const handleKeepElectiveCourse = async (course, yr, sem) => {
    try {
      if (currentUser && currentUser.uid) {
        const userDocRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userDocRef);
          if (userDoc.exists) {
            const previousFinalizedSchedule = userDoc.data().schedules[activeTab] || {};

            const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
            const updatedFinalizedSchedule = {
              ...updateObj,
              [`year${yr}`]: {
                ...updateObj[`year${yr}`],
                [`S${sem}`]: {
                  ...(updateObj[`year${yr}`] && updateObj[`year${yr}`][`S${sem}`] ? updateObj[`year${yr}`][`S${sem}`] : {}),
                  courses: (updateObj[`year${yr}`] && updateObj[`year${yr}`][`S${sem}`] && updateObj[`year${yr}`][`S${sem}`].courses
                    ? updateObj[`year${yr}`][`S${sem}`].courses.concat([course.courseInfo_courseNumber])
                    : [course.courseInfo_courseNumber]),
                },
              },
            };

            transaction.update(userDocRef, {
              [`schedules.${activeTab}`]: updatedFinalizedSchedule,
            });
          } else {
            console.error('User data not found for the given userId:', currentUser.uid);
            throw new Error('User data not found');
          }
        });
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error keeping elective course:', error);
    }
  };

  const handleEvaluate = (course) => {
    setSelectedCourse(course);
    setEvaluationModalOpen(true);
  };

  const handleCloseEvaluationModal = () => {
    setEvaluationModalOpen(false);
    setSelectedCourse(null);
  };

  const handleOpenAddScheduleModal = () => {
    setShowAddScheduleModal(true);
  };

  const handleCloseAddScheduleModal = () => {
    setShowAddScheduleModal(false);
    setNewScheduleName('');
    setError(''); // Clear the error message when the modal is closed
  };  

  const handleAddPlan = async (event) => {
    event.preventDefault(); // Prevent page reload
  
    if (!newScheduleName) {
      setError('Please enter a name for the new plan.');
      return;
    }
  
    if (tabs.some(tab => tab.name === newScheduleName)) {
      setError('A plan with this name already exists.');
      return;
    }
  
    try {
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const userData = userDoc.data();
          const newSchedule = {
            numYears: numYears2,
          };
  
          for (let i = 1; i <= numYears2; i++) {
            newSchedule[`year${i}`] = {
              S1: { courses: [] },
              S2: { courses: [] },
            };
  
            // Copy completed semesters' courses to the new schedule
            if (completedSemesters[`year${i}`]?.S1) {
              newSchedule[`year${i}`].S1.courses = userData.schedules[activeTab][`year${i}`].S1.courses || [];
            }
            if (completedSemesters[`year${i}`]?.S2) {
              newSchedule[`year${i}`].S2.courses = userData.schedules[activeTab][`year${i}`].S2.courses || [];
            }
          }
  
          // Prepare the new tab data and add it to the existing schedules
          const updatedSchedules = {
            ...userData.schedules,
            [newScheduleName]: newSchedule,
          };
  
          // Ensure that no 'data' header is added anywhere
          transaction.update(userRef, {
            schedules: updatedSchedules,
            activeTab: newScheduleName,
          });
  
          // Update local state
          setTabs([...tabs, { name: newScheduleName, ...newSchedule }]);
          setActiveTab(newScheduleName);
          setError(''); // Clear the error message
          handleCloseAddScheduleModal();
        });
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error adding new schedule:', error);
      setError('An error occurred while adding the new plan.');
    }
  };
  

  const handleRemovePlan = async (planNameToRemove) => {
    if (!currentUser || !currentUser.uid) {
        console.error('Error: currentUser or currentUser.uid is not defined');
        return;
    }

    if (tabs.length <= 1) {
        setError('Cannot remove the last plan.');
        return;
    }

    try {
        const userRef = db.collection('users').doc(currentUser.uid);
        const batch = db.batch(); // Create a new batch instance

        const userDoc = await userRef.get();
        if (!userDoc.exists) {
            throw new Error('User data not found');
        }

        const userData = userDoc.data();

        // Remove the plan from schedules
        const updatedSchedules = tabs.filter(tab => tab.name !== planNameToRemove);

        if (updatedSchedules.length === tabs.length) {
            throw new Error('Plan not found');
        }

        const updatedSchedulesObject = updatedSchedules.reduce((acc, tab) => {
            acc[tab.name] = { ...userData.schedules[tab.name] }; // Correctly copy the existing tab data
            return acc;
        }, {});

        // Remove the associated milestones
        const updatedFinalizedMilestones = { ...userData.finalizedMilestones };
        if (updatedFinalizedMilestones && updatedFinalizedMilestones[planNameToRemove]) {
            delete updatedFinalizedMilestones[planNameToRemove];
        }

        let newActiveTab = activeTab;
        if (activeTab === planNameToRemove) {
            const removedTabIndex = tabs.findIndex(tab => tab.name === planNameToRemove);
            if (removedTabIndex > 0 && removedTabIndex === tabs.length - 1) {
                newActiveTab = tabs[removedTabIndex - 1].name;
            } else if (removedTabIndex > 0) {
                newActiveTab = tabs[removedTabIndex + 1].name;
            } else {
                newActiveTab = updatedSchedules[0].name;
            }
        }

        // Check if the removed plan is the primary tab
        if (primaryTab === planNameToRemove) {
            // Set the first available tab as the new primary if the primary one is removed
            const newPrimaryTab = updatedSchedules.length > 0 ? updatedSchedules[0].name : '';
            setPrimaryTab(newPrimaryTab);

            // Update Firestore with the new primary tab
            batch.update(userRef, {
                primaryTab: newPrimaryTab,
            });
        }

        // Prepare the batch update
        const batchUpdateData = {
            schedules: updatedSchedulesObject,
            activeTab: newActiveTab,
            finalizedMilestones: updatedFinalizedMilestones, // Include updated milestones
        };

        batch.update(userRef, batchUpdateData);

        // Commit the batch
        await batch.commit();

        // Update local state to reflect changes
        setTabs(updatedSchedules);
        setActiveTab(newActiveTab);

        // Update finalizedMilestones state
        setFinalizedMilestones(prev => {
            const { [planNameToRemove]: removed, ...rest } = prev;
            return rest;
        });

        setError('');

        console.log(`Plan "${planNameToRemove}" removed and active tab updated successfully`);
    } catch (error) {
        console.error('Error removing plan:', error);
        setError(`An error occurred while removing the plan: ${error.message}`);
    }
};
  
  const handleTabClick = async (tabName) => {
    try {
      setActiveTab(tabName);
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);
        await userRef.update({
          activeTab: tabName
        });
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          const courseIds = userData.schedules[tabName] || [];
          setFinalizedCourseIds(courseIds);
  
          const cumulativeIds = calculateCumulativeIds(courseIds);
          setCumulativeCourseIds(cumulativeIds);
  
          const organizedCourses = organizeCoursesByYearAndSemester(courseIds, allCourses, prereqs, cumulativeIds);
          setFinalizedOrganizedCourses(organizedCourses);
        }
      }
    } catch (error) {
      console.error('Error setting active tab:', error);
    }
  };  

  const handleSwitchPrimaryTab = async (newPrimaryTab) => {
    try {
      setPrimaryTab(newPrimaryTab);
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);
        await userRef.update({
          primaryTab: newPrimaryTab
        });
      }
    } catch (error) {
      console.error('Error setting primary tab:', error);
    }
  };
  
  const handleTabDoubleClick = (tab) => {
    setEditingTab(tab);
    setNewTabName(tab);
  };
  
  const handleRenameTab = async (oldTabName, newTabName) => {
    if (!newTabName || newTabName === oldTabName) {
      setEditingTab(null);
      return;
    }
  
    if (tabs.some(tab => tab.name === newTabName)) {
      alert('A plan with this name already exists.');
      return;
    }
  
    try {
      if (currentUser && currentUser.uid) {
        const userRef = db.collection('users').doc(currentUser.uid);
        await db.runTransaction(async (transaction) => {
          const userDoc = await transaction.get(userRef);
          if (!userDoc.exists) {
            throw new Error('User data not found');
          }
  
          const newTabs = tabs.map(tab =>
            tab.name === oldTabName
              ? { ...tab, name: newTabName }
              : tab
          );
  
          transaction.update(userRef, {
            schedules: newTabs.reduce((acc, tab) => {
              acc[tab.name] = tab.data;
              return acc;
            }, {})
          });
  
          setTabs(newTabs);
          if (activeTab === oldTabName) {
            setActiveTab(newTabName);
          }
          setEditingTab(null);
        });
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      console.error('Error renaming schedule:', error);
    }
  };
  
  const handleRenameSubmit = (event) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      if (newTabName.length <= 65) { // Ensure the name is within the allowed limit
        handleRenameTab(editingTab, newTabName);
      } else {
        alert('Tab name is too long. Please use 65 characters or fewer.');
      }
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <PlanJoyrideTour />
      <div className={`w-full ${viewMode === 'horizontal' ? 'mb-20 mt-20' : 'mt-20 mb-0'}`}>

      <div className="flex border-b border-gray-200 dark:border-gray-700 mb-2 items-center">
  
        {/* Tabs and Add Plan Button */}
        <div className="flex items-center flex-1 overflow-x-auto custom-scrollbar space-x-2">
          
          {/* Tabs Section */}
          <ul className="flex flex-nowrap">
            {tabs.map((tab, index) => (
              <li key={index} className="relative mr-2 flex items-center flex-shrink-0" role="presentation">
                {editingTab === tab.name ? (
                  <input
                    type="text"
                    value={newTabName}
                    onChange={(e) => setNewTabName(e.target.value)}
                    onBlur={handleRenameSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleRenameSubmit(e);
                    }}
                    className="inline-block py-2 px-3 border-b-2 rounded-t-lg focus:outline-none"
                    style={{ width: `${tab.name.length * 10 + 20}px` }}
                    maxLength={65}
                    autoFocus
                  />
                ) : (
                  <button
                    className={`inline-block py-2 px-3 border-b-2 rounded-t-lg flex justify-between items-center ${
                      activeTab === tab.name
                        ? 'border-blue-500 text-blue-500'
                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                    }`}
                    onClick={() => handleTabClick(tab.name)}
                    onDoubleClick={() => handleTabDoubleClick(tab.name)}
                    type="button"
                    role="tab"
                    aria-controls={`${tab.name}-content`}
                    aria-selected={activeTab === tab.name ? 'true' : 'false'}
                  >
                    {/* Bookmark Icon */}
                    <button
                      className="mr-2 focus:outline-none"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering tab click
                        handleSwitchPrimaryTab(tab.name);
                      }}
                      aria-label={`Bookmark ${tab.name}`}
                    >
                      {tab.name === primaryTab ? (
                        <FaBookmark className="text-red-700 text-sm" />
                      ) : (
                        <FaRegBookmark className="text-red-700 text-sm hover:text-black hover:text-md" />
                      )}
                    </button>
                    <span className="truncate">{tab.name}</span>
                    {tabs.length > 1 && (
                      <button
                        className="ml-2 text-black hover:text-red-700"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering tab click
                          handleRemovePlan(tab.name);
                        }}
                        aria-label={`Remove ${tab.name}`}
                      >
                        &times;
                      </button>
                    )}
                  </button>
                )}
              </li>
            ))}
          </ul>

          {/* Add Plan Button */}
          <button
            onClick={handleOpenAddScheduleModal}
            className="text-black hover:text-gray-700 text-lg p-0 flex items-center mr-4" // Added mr-4 here
            aria-label="Add Plan"
          >
            <GrFormAdd className="text-s" />
          </button>
        </div>
        
        {/* Action Buttons Section */}
        <div className="flex items-center space-x-2 ml-auto">
          
          {/* View Toggle Button */}
          <button
            onClick={() => setViewMode(viewMode === 'horizontal' ? 'vertical' : 'horizontal')}
            className="inline-flex items-center px-2 py-1 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700"
            aria-label={viewMode === 'horizontal' ? 'Switch to Vertical View' : 'Switch to Horizontal View'}
          >
            {viewMode === 'horizontal' ? (
              <CiGrid2V className="w-5 h-5" />
            ) : (
              <CiGrid41 className="w-5 h-5" />
            )}
          </button>

          {/* Export and View All Milestones Buttons */}
          <div className="inline-flex rounded-md shadow-sm" role="group">
            <button 
              type="button"
              onClick={handleOpenAllMilestonesDrawer}
              className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              <LuGoal className="w-3 h-3 me-2" />
              View All Milestones
            </button>
            <button
              type="button"
              onClick={handleExportPlan}
              className="inline-flex items-center px-3 py-1.5 text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
            >
              <svg
                className="w-3 h-3 me-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
              </svg>
              Export Plan
            </button>
          </div>
        </div>
      </div>

        <NavBar />
        <div className="w-full">
          {showSidebar && (
            <ElectiveSidebar
              onClose={() => setShowSidebar(false)}
              showSidebar={showSidebar}
              elective={selectedElectiveCategory}
              electiveYear={electiveYear}
              electiveSemester={electiveSemester} 
              courses={fetchedCourseData}
              onKeepElectiveCourse={handleKeepElectiveCourse}
            />
          )}
          {viewMode === 'horizontal' ? (
            // Horizontal View
          <div className="grid grid-cols-1 gap-y-4 w-full">
            {/* Header Section: Total Credits and Control Buttons */}
            <div className="flex justify-between items-center mb-0">
              {/* Total Credits and Upper Division Credits */}
              <div className="flex items-center">
                <CreditsOverview finalizedOrganizedCourses={finalizedOrganizedCourses} />
              </div>

              {/* Autofill, Remove All, and Hide/Show Completed Years Button */}
              <div className="ml-auto flex space-x-2 items-center">
                {areAnyPrereqsNotMet && (
                  <button
                    onClick={handleRemoveAllCourses}
                    disabled={isAutofilling}
                    className={`remove-button ${
                      isAutofilling ? 'disabled' : ''
                    } text-green-700 hover:text-white border border-red-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                  >
                    Re-Shuffle Courses
                  </button>
                )}
                {hasUncompletedCourses && (
                  <button
                    onClick={handleRemoveAllCourses}
                    disabled={isAutofilling}
                    className={`remove-button ${
                      isAutofilling ? 'disabled' : ''
                    } text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                  >
                    Remove All Courses
                  </button>
                )}
                <button
                  onClick={handleAutofillAllCourses}
                  className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5"
                >
                  Autofill Plan
                </button>
                {/* Hide/Show Completed Years Button */}
                {Object.values(completedSemesters).some((year) =>
                  Object.values(year).every((semester) => semester)
                ) && (
                  <button
                    type="button"
                    className="flex items-center py-1.5 px-3 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    onClick={() => setHideCompletedYears(!hideCompletedYears)}
                  >
                    {hideCompletedYears ? (
                      <BiSolidShow className="inline mr-1" style={{ verticalAlign: 'middle' }} />
                    ) : (
                      <BiSolidHide className="inline mr-1" style={{ verticalAlign: 'middle' }} />
                    )}
                    {hideCompletedYears ? 'Show Completed Years' : 'Hide Completed Years'}
                  </button>
                )}
              </div>
            </div>

            {/* Render each year */}
            {Array.from({ length: numYears }, (_, i) => i + 1).map((yearNumber) => {
              const isYearCompleted =
                completedSemesters[`year${yearNumber}`]?.S1 && completedSemesters[`year${yearNumber}`]?.S2;

              if (hideCompletedYears && isYearCompleted) {
                return null;
              }

              return (
                <div key={yearNumber} className="w-full">
                  <div className="grid grid-cols-2 gap-3 w-full DroppableSemester">
                    {/* Fall Semester */}
                    <div className="flex-1 min-w-[200px]">
                      <DroppableSemester yearNumber={yearNumber} semester="Fall">
                        <div
                          className={`border-solid border-1 p-2.5 rounded-lg border-gray ${
                            completedSemesters[`year${yearNumber}`]?.S1 ? 'bg-blue-50' : ''
                          }`}
                        >
                          {/* Header Section Inside the Card */}
                          <div className="flex-none flex items-center justify-between mb-2">
                            <h2 className="year-heading text-base font-medium">
                              {`${getYearAndSemesterName(yearNumber)} Fall`}
                            </h2>
                            <div className="flex items-center space-x-2">
                              <span className="bg-purple-100 text-purple-800 text-xs font-medium px-1 py-0.25 rounded border border-purple-400">
                                {getTotalCreditsForSemester(
                                  finalizedOrganizedCourses[`year${yearNumber}`]?.Fall || []
                                )}{' '}
                                Credits
                              </span>
                              <div
                                className="relative inline-block text-left"
                                ref={(el) => (dropdownRefs.current[`${yearNumber}-Fall`] = el)}
                              >
                                <button
                                  id={`dropdownButton-${yearNumber}-Fall`}
                                  className="p-0 m-0 border-0 shadow-none text-gray-500 hover:text-gray-700"
                                  type="button"
                                  onClick={() => handleDropdownToggle(yearNumber, 'Fall')}
                                >
                                  <BsThreeDots className="w-5 h-5 mt-1.5" />
                                </button>
                                <div
                                  id={`dropdown-${yearNumber}-Fall`}
                                  className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${
                                    dropdownOpen[`${yearNumber}-Fall`] ? '' : 'hidden'
                                  }`}
                                  style={{ left: '-9.25rem' }}
                                >
                                  <ul
                                    className="py-2 text-sm text-gray-700"
                                    aria-labelledby={`dropdownButton-${yearNumber}-Fall`}
                                  >
                                    <li>
                                      <Link
                                        to={{
                                          pathname: `/SemesterDetails/${yearNumber}/S1`,
                                          search: `?plan=${activeTab}`,
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Details
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() => handleAddMilestone(yearNumber, 'Fall')}
                                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                      >
                                        Milestones
                                      </button>
                                    </li>
                                    {hasCoursesInSemester(yearNumber, 'Fall') &&
                                      shouldShowMarkCompleteButton(yearNumber, 'Fall') && (
                                        <li>
                                          <button
                                            onClick={() => handleToggleSemesterComplete(yearNumber, 'Fall')}
                                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                          >
                                            {completedSemesters[`year${yearNumber}`]?.S1
                                              ? 'Mark Incomplete'
                                              : 'Mark Complete'}
                                          </button>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Courses Section */}
                          {(finalizedOrganizedCourses[`year${yearNumber}`]?.Fall || []).length === 0 ? (
                            <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
                              No Courses Planned
                            </span>
                          ) : (
                            finalizedOrganizedCourses[`year${yearNumber}`]?.Fall.map((course) => (
                              <DraggableCourse
                                key={course.courseInfo_courseNumber}
                                course={course}
                                yearNumber={yearNumber}
                                semester="Fall"
                                onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, 'Fall')}
                                moveCourse={moveCourse}
                                onAlternativesClick={handleAlternativesClick}
                                isCompleted={completedSemesters[`year${yearNumber}`]?.S1}
                                onEvaluate={handleEvaluate}
                                viewMode={viewMode}
                                isDragging={isDragging}
                              />
                            ))
                          )}

                          {/* Footer Section - Conditionally Rendered */}
                          {!completedSemesters[`year${yearNumber}`]?.S1 && (
                            <div className="flex justify-end mt-2 bg-white py-2">
                              <div className="flex space-x-2 add-autofill-container">
                                <button
                                  ref={(el) => buttonRefs.current.push(el)}
                                  className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center add-btn"
                                >
                                  <Link
                                    to={{
                                      pathname: '/Search',
                                      search: `?yearNumber=${yearNumber}&semester=Fall`,
                                    }}
                                  >
                                    <FaPlus className="inline mb-0.5" /> Add
                                  </Link>
                                </button>
                                {yearNumber <= 4 && (
                                  <>
                                    <div className="border-gray-300 border-l h-5 inline-block mt-[5px]"></div>
                                    <button
                                      ref={(el) => buttonRefs.current.push(el)}
                                      onClick={() => handleAutofillCourse(yearNumber, 'Fall')}
                                      className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                                    >
                                      <FaMagic className="inline mr-1 mb-0.5" /> Autofill
                                    </button>
                                  </>
                                )}
                              </div>
                              {hasCoursesInSemester(yearNumber, 'Fall') && (
                                <>
                                  <div className="border-gray-300 ml-2 border-l h-5 inline-block mt-[5px] mr-[2px]"></div>
                                  <button
                                    ref={(el) => buttonRefs.current.push(el)}
                                    onClick={() => handleRemoveSemesterCourses(yearNumber, 'S1')}
                                    className="mr-1 text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                                  >
                                    <FaTrash className="inline mr-0.5 mb-0.5 ml-0.5" /> Clear
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </DroppableSemester>
                    </div>

                    {/* Spring Semester */}
                    <div className="flex-1 min-w-[200px]">
                      <DroppableSemester yearNumber={yearNumber} semester="Spring">
                        <div
                          className={`border-solid border-1 p-2.5 rounded-lg border-gray ${
                            completedSemesters[`year${yearNumber}`]?.S2 ? 'bg-blue-50' : ''
                          }`}
                        >
                          {/* Header Section Inside the Card */}
                          <div className="flex-none flex items-center justify-between mb-2">
                            <h2 className="year-heading text-base font-medium">
                              {`${getYearAndSemesterName(yearNumber + 1)} Spring`}
                            </h2>
                            <div className="flex items-center space-x-2">
                              <span className="bg-purple-100 text-purple-800 text-xs font-medium px-1 py-0.25 rounded border border-purple-400">
                                {getTotalCreditsForSemester(
                                  finalizedOrganizedCourses[`year${yearNumber}`]?.Spring || []
                                )}{' '}
                                Credits
                              </span>
                              <div
                                className="relative inline-block text-left"
                                ref={(el) => (dropdownRefs.current[`${yearNumber}-Spring`] = el)}
                              >
                                <button
                                  id={`dropdownButton-${yearNumber}-Spring`}
                                  className="p-0 m-0 border-0 shadow-none text-gray-500 hover:text-gray-700"
                                  type="button"
                                  onClick={() => handleDropdownToggle(yearNumber, 'Spring')}
                                >
                                  <BsThreeDots className="w-5 h-5 mt-1.5" />
                                </button>
                                <div
                                  id={`dropdown-${yearNumber}-Spring`}
                                  className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${
                                    dropdownOpen[`${yearNumber}-Spring`] ? '' : 'hidden'
                                  }`}
                                  style={{ left: '-9.25rem' }}
                                >
                                  <ul
                                    className="py-2 text-sm text-gray-700"
                                    aria-labelledby={`dropdownButton-${yearNumber}-Spring`}
                                  >
                                    <li>
                                      <Link
                                        to={{
                                          pathname: `/SemesterDetails/${yearNumber}/S2`,
                                          search: `?plan=${activeTab}`,
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Details
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() => handleAddMilestone(yearNumber, 'Spring')}
                                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                      >
                                        Milestones
                                      </button>
                                    </li>
                                    {hasCoursesInSemester(yearNumber, 'Spring') &&
                                      shouldShowMarkCompleteButton(yearNumber, 'Spring') && (
                                        <li>
                                          <button
                                            onClick={() => handleToggleSemesterComplete(yearNumber, 'Spring')}
                                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                          >
                                            {completedSemesters[`year${yearNumber}`]?.S2
                                              ? 'Mark Incomplete'
                                              : 'Mark Complete'}
                                          </button>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Courses Section */}
                          {(finalizedOrganizedCourses[`year${yearNumber}`]?.Spring || []).length === 0 ? (
                            <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
                              No Courses Planned
                            </span>
                          ) : (
                            finalizedOrganizedCourses[`year${yearNumber}`]?.Spring.map((course) => (
                              <DraggableCourse
                                key={course.courseInfo_courseNumber}
                                course={course}
                                yearNumber={yearNumber}
                                semester="Spring"
                                onRemove={(classToRemove) =>
                                  handleRemove(classToRemove, yearNumber, 'Spring')
                                }
                                moveCourse={moveCourse}
                                onAlternativesClick={handleAlternativesClick}
                                isCompleted={completedSemesters[`year${yearNumber}`]?.S2}
                                onEvaluate={handleEvaluate}
                                viewMode={viewMode}
                                isDragging={isDragging}
                              />
                            ))
                          )}

                          {/* Footer Section - Conditionally Rendered */}
                          {!completedSemesters[`year${yearNumber}`]?.S2 && (
                            <div className="flex justify-end mt-2 bg-white py-2">
                              <div className="flex space-x-2 add-autofill-container">
                                <button
                                  ref={(el) => buttonRefs.current.push(el)}
                                  className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center add-btn"
                                >
                                  <Link
                                    to={{
                                      pathname: '/Search',
                                      search: `?yearNumber=${yearNumber}&semester=Spring`,
                                    }}
                                  >
                                    <FaPlus className="inline mb-0.5" /> Add
                                  </Link>
                                </button>
                                {yearNumber <= 4 && (
                                  <>
                                    <div className="border-gray-300 border-l h-5 inline-block mt-[5px]"></div>
                                    <button
                                      ref={(el) => buttonRefs.current.push(el)}
                                      onClick={() => handleAutofillCourse(yearNumber, 'Spring')}
                                      className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                                    >
                                      <FaMagic className="inline mr-1 mb-0.5" /> Autofill
                                    </button>
                                  </>
                                )}
                              </div>
                              {hasCoursesInSemester(yearNumber, 'Spring') && (
                                <>
                                  <div className="border-gray-300 ml-2 border-l h-5 inline-block mt-[5px] mr-[2px]"></div>
                                  <button
                                    ref={(el) => buttonRefs.current.push(el)}
                                    onClick={() => handleRemoveSemesterCourses(yearNumber, 'S2')}
                                    className="mr-1 text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                                  >
                                    <FaTrash className="inline mr-0.5 mb-0.5 ml-0.5" /> Clear
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </DroppableSemester>
                    </div>
                  </div>

                  {/* Add and Remove Year Buttons */}
                  {yearNumber === numYears && (
                    <div className="flex justify-center mt-4 mb-10 space-x-4">
                      {numYears > 1 && shouldShowRemoveYearButton(yearNumber) && (
                        <button
                          onClick={handleRemoveYear}
                          className="text-red-700 bg-red-200 bg-opacity-60 hover:bg-red-300 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-full w-10 h-10 flex items-center justify-center"
                        >
                          <FaMinus />
                        </button>
                      )}
                      <button
                        onClick={handleAddYear}
                        className="text-blue-700 bg-blue-200 bg-opacity-60 hover:bg-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full w-10 h-10 flex items-center justify-center"
                      >
                        <FaPlus />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          ) : (
            // Vertical View
            <div className="w-full flex flex-col p-0 m-0">
              {/* Header Section: Total Credits and Control Buttons */}
              <div className="flex justify-between items-center mb-2">
                {/* Total Credits and Upper Division Credits */}
                <div className="flex items-center">
                  <CreditsOverview finalizedOrganizedCourses={finalizedOrganizedCourses} />
                </div>

                {/* Autofill, Remove All, and Hide/Show Completed Buttons */}
                <div className="ml-auto flex space-x-2 items-center">
                  {areAnyPrereqsNotMet && (
                    <button
                      onClick={handleReshuffleCourses}
                      disabled={isAutofilling}
                      className={`remove-button ${
                        isAutofilling ? 'disabled' : ''
                      } text-green-700 hover:text-white border border-red-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                    >
                      Re-Shuffle Courses
                    </button>
                  )}
                  {hasUncompletedCourses && (
                    <button
                      onClick={handleRemoveAllCourses}
                      disabled={isAutofilling}
                      className={`remove-button ${isAutofilling ? 'disabled' : ''} text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5`}
                    >
                      Remove All Courses
                    </button>
                  )}
                  <button
                    onClick={handleAutofillAllCourses}
                    // **Removed the ternary to keep label consistent**
                    className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5"
                  >
                    Autofill Plan
                  </button>
                  {/* Hide/Show Completed Terms Button */}
                  {Object.values(completedSemesters).some((year) =>
                    Object.values(year).some((semester) => semester)
                  ) && (
                    <button
                      type="button"
                      className="flex items-center py-1.5 px-3 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                      onClick={() => setHideCompleted(!hideCompleted)}
                    >
                      {hideCompleted ? (
                        <BiSolidShow className="inline mr-1" style={{ verticalAlign: 'middle' }} />
                      ) : (
                        <BiSolidHide className="inline mr-1" style={{ verticalAlign: 'middle' }} />
                      )}
                      {hideCompleted ? 'Show Completed Terms' : 'Hide Completed Terms'}
                    </button>
                  )}
                </div>
              </div>

              {/* Semesters Container */}
              <div
                className="flex w-full h-full overflow-x-auto"
                style={{
                  padding: '0',
                  margin: '0',
                  alignItems: 'flex-start',
                  gap: '0.5rem', // gap-2 corresponds to 0.5rem
                  overflowX: 'auto', // Enables horizontal scrolling when content exceeds width
                }}
              >
                {Array.from({ length: numYears }, (_, i) => i + 1).flatMap((yearNumber) => {
                  const totalSemesters = 6; // Fixed total semesters for width calculation
                  const totalGaps = totalSemesters - 1; // Number of gaps between semesters
                  const semesterWidth = `calc((100% - (${totalGaps} * 0.5rem)) / ${totalSemesters})`;

                  return [
                    // **Fall Semester Column**
                    !completedSemesters[`year${yearNumber}`]?.S1 || !hideCompleted ? (
                      <div
                        key={`fall-${yearNumber}`}
                        className="flex flex-col justify-start"
                        style={{
                          width: semesterWidth,
                          flexShrink: 0, // Prevents shrinking of the cards
                          minWidth: '12.5rem', // Set a minimum width to maintain consistency
                          marginBottom: '0.5rem', // Adds vertical gap between semesters
                        }}
                      >
                        <DroppableSemester yearNumber={yearNumber} semester="Fall">
                          <div
                            className={`border-solid border-1 rounded-lg border-gray flex flex-col group ${
                              completedSemesters[`year${yearNumber}`]?.S1 ? 'bg-blue-50' : ''
                            }`}
                            style={{
                              height: '32rem', // Fixed height for consistency
                              overflow: 'hidden',
                            }}
                          >
                            {/* Header Section Inside the Card */}
                            <div className="flex-none flex items-center justify-between mb-1 bg-gray-50 border-b border-gray-300 p-1.5 w-full"> {/* Reduced mb-2 to mb-1 */}
                              <h2 className="year-heading text-sm font-medium">
                                {`${getYearAndSemesterName(yearNumber)} Fall`}
                              </h2>
                              <span className="bg-purple-100 text-purple-800 text-xs font-medium px-1 py-0.25 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400">
                                {getTotalCreditsForSemester(finalizedOrganizedCourses[`year${yearNumber}`]?.Fall || [])} Credits
                              </span>
                              <div
                                className="relative inline-block text-left"
                                ref={(el) => (dropdownRefs.current[`${yearNumber}-Fall`] = el)}
                              >
                                <button
                                  id={`dropdownButton-${yearNumber}-Fall`}
                                  className="p-0 m-0 border-0 shadow-none text-gray-500 hover:text-gray-700"
                                  type="button"
                                  onClick={() => handleDropdownToggle(yearNumber, 'Fall')}
                                >
                                  <BsThreeDots className="w-5 h-5 mt-1.5" />
                                </button>
                                <div
                                  id={`dropdown-${yearNumber}-Fall`}
                                  className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${
                                    dropdownOpen[`${yearNumber}-Fall`] ? '' : 'hidden'
                                  }`}
                                  style={{ left: '-9.25rem' }}
                                >
                                  <ul
                                    className="py-2 text-sm text-gray-700"
                                    aria-labelledby={`dropdownButton-${yearNumber}-Fall`}
                                  >
                                    <li>
                                      <Link
                                        to={{
                                          pathname: `/SemesterDetails/${yearNumber}/S1`,
                                          search: `?plan=${activeTab}`,
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Details
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() => handleAddMilestone(yearNumber, 'Fall')}
                                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                      >
                                        Milestones
                                      </button>
                                    </li>
                                    {hasCoursesInSemester(yearNumber, 'Fall') &&
                                      shouldShowMarkCompleteButton(yearNumber, 'Fall') && (
                                        <li>
                                          <button
                                            onClick={() => handleToggleSemesterComplete(yearNumber, 'Fall')}
                                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                          >
                                            {completedSemesters[`year${yearNumber}`]?.S1
                                              ? 'Mark Incomplete'
                                              : 'Mark Complete'}
                                          </button>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            {/* Courses Section */}
                            <div
                              className="flex-1 overflow-y-auto custom-scrollbar relative p-1.5" // Reduced padding from p-2 to p-1.5
                              style={{
                                overflowX: 'hidden',
                              }}
                            >
                              {(finalizedOrganizedCourses[`year${yearNumber}`]?.Fall || []).length === 0 ? (
                                <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 flex items-center justify-center">
                                  No Courses Planned
                                </span>
                              ) : (
                                finalizedOrganizedCourses[`year${yearNumber}`]?.Fall.map((course) => (
                                  <DraggableCourse
                                    key={course.courseInfo_courseNumber}
                                    course={course}
                                    yearNumber={yearNumber}
                                    semester="Fall"
                                    onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, 'Fall')}
                                    moveCourse={moveCourse}
                                    onAlternativesClick={handleAlternativesClick}
                                    isCompleted={completedSemesters[`year${yearNumber}`]?.S1}
                                    onEvaluate={handleEvaluate}
                                    viewMode={viewMode}
                                    isDragging={isDragging}
                                    style={{
                                      overflow: 'visible',
                                      margin: '0 auto',
                                      maxWidth: 'calc(100% - 10px)',
                                      position: 'relative',
                                      zIndex: 1,
                                    }}
                                  />
                                ))
                              )}
                            </div>

                            {/* Footer Section - Conditionally Rendered */}
                            {!completedSemesters[`year${yearNumber}`]?.S1 && (
                              <div
                                className={`flex-none flex ${
                                  hasCoursesInSemester(yearNumber, 'Fall') ? 'justify-center' : 'justify-end'
                                } mt-1 bg-white py-1.5 hidden group-hover:flex transition-opacity duration-300`}
                              >
                                {/* Add and Autofill Buttons */}
                                <div className="flex space-x-2">
                                  <button
                                    ref={(el) => buttonRefs.current.push(el)}
                                    className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center add-btn"
                                  >
                                    <Link
                                      to={{
                                        pathname: '/Search',
                                        search: `?yearNumber=${yearNumber}&semester=Fall`,
                                      }}
                                    >
                                      <FaPlus className="inline mb-0.5" /> Add
                                    </Link>
                                  </button>
                                  <div className="border-gray-300 border-l h-5 inline-block mt-[5px]"></div>
                                  <button
                                    ref={(el) => buttonRefs.current.push(el)}
                                    onClick={() => handleAutofillCourse(yearNumber, 'Fall')}
                                    className={`text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn ${
                                      !hasCoursesInSemester(yearNumber, 'Fall') ? 'mr-2' : ''
                                    }`}
                                  >
                                    <FaMagic className="inline mr-1 mb-0.5" /> Autofill
                                  </button>
                                </div>
                                {/* Clear Button (Only if Courses Exist) */}
                                {hasCoursesInSemester(yearNumber, 'Fall') && (
                                  <div className="flex space-x-2 ml-2">
                                    <div className="border-gray-300 border-l h-5 inline-block mt-[5px]"></div>
                                    <button
                                      ref={(el) => buttonRefs.current.push(el)}
                                      onClick={() => handleRemoveSemesterCourses(yearNumber, 'S1')}
                                      className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                                    >
                                      <FaTrash className="inline mr-0.5 mb-0.5 ml-0.5" /> Clear
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </DroppableSemester>
                      </div>
                    ) : null,

                    // **Spring Semester Column**
                    !completedSemesters[`year${yearNumber}`]?.S2 || !hideCompleted ? (
                      <div
                        key={`spring-${yearNumber}`}
                        className="flex flex-col justify-start"
                        style={{
                          width: semesterWidth,
                          flexShrink: 0, // Prevents shrinking of the cards
                          minWidth: '12.5rem', // Set a minimum width to maintain consistency
                          marginBottom: '0.5rem', // Adds vertical gap between semesters
                        }}
                      >
                        <DroppableSemester yearNumber={yearNumber} semester="Spring">
                          <div
                            className={`border-solid border-1 rounded-lg border-gray flex flex-col group ${
                              completedSemesters[`year${yearNumber}`]?.S2 ? 'bg-blue-50' : ''
                            }`}
                            style={{
                              height: '32rem', // Fixed height for consistency
                              overflow: 'hidden',
                            }}
                          >
                            {/* Header Section Inside the Card */}
                            <div className="flex-none flex items-center justify-between mb-1 bg-gray-50 border-b border-gray-300 p-1.5 w-full"> {/* Reduced mb-2 to mb-1 */}
                              <h2 className="year-heading text-sm font-medium">
                                {`${getYearAndSemesterName(yearNumber + 1)} Spring`}
                              </h2>
                              <span className="bg-purple-100 text-purple-800 text-xs font-medium px-1 py-0.25 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400">
                                {getTotalCreditsForSemester(finalizedOrganizedCourses[`year${yearNumber}`]?.Spring || [])} Credits
                              </span>
                              <div
                                className="relative inline-block text-left"
                                ref={(el) => (dropdownRefs.current[`${yearNumber}-Spring`] = el)}
                              >
                                <button
                                  id={`dropdownButton-${yearNumber}-Spring`}
                                  className="p-0 m-0 border-0 shadow-none text-gray-500 hover:text-gray-700"
                                  type="button"
                                  onClick={() => handleDropdownToggle(yearNumber, 'Spring')}
                                >
                                  <BsThreeDots className="w-5 h-5 mt-1.5" />
                                </button>
                                <div
                                  id={`dropdown-${yearNumber}-Spring`}
                                  className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${
                                    dropdownOpen[`${yearNumber}-Spring`] ? '' : 'hidden'
                                  }`}
                                  style={{ left: '-9.25rem' }}
                                >
                                  <ul
                                    className="py-2 text-sm text-gray-700"
                                    aria-labelledby={`dropdownButton-${yearNumber}-Spring`}
                                  >
                                    <li>
                                      <Link
                                        to={{
                                          pathname: `/SemesterDetails/${yearNumber}/S2`,
                                          search: `?plan=${activeTab}`,
                                        }}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Details
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        onClick={() => handleAddMilestone(yearNumber, 'Spring')}
                                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                      >
                                        Milestones
                                      </button>
                                    </li>
                                    {hasCoursesInSemester(yearNumber, 'Spring') &&
                                      shouldShowMarkCompleteButton(yearNumber, 'Spring') && (
                                        <li>
                                          <button
                                            onClick={() => handleToggleSemesterComplete(yearNumber, 'Spring')}
                                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                                          >
                                            {completedSemesters[`year${yearNumber}`]?.S2
                                              ? 'Mark Incomplete'
                                              : 'Mark Complete'}
                                          </button>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            {/* Courses Section */}
                            <div
                              className="flex-1 overflow-y-auto custom-scrollbar relative p-1.5" // Reduced padding from p-2 to p-1.5
                              style={{
                                overflowX: 'hidden',
                              }}
                            >
                              {(finalizedOrganizedCourses[`year${yearNumber}`]?.Spring || []).length === 0 ? (
                                <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 flex items-center justify-center">
                                  No Courses Planned
                                </span>
                              ) : (
                                finalizedOrganizedCourses[`year${yearNumber}`]?.Spring.map((course) => (
                                  <DraggableCourse
                                    key={course.courseInfo_courseNumber}
                                    course={course}
                                    yearNumber={yearNumber}
                                    semester="Spring"
                                    onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, 'Spring')}
                                    moveCourse={moveCourse}
                                    onAlternativesClick={handleAlternativesClick}
                                    isCompleted={completedSemesters[`year${yearNumber}`]?.S2}
                                    onEvaluate={handleEvaluate}
                                    viewMode={viewMode}
                                    isDragging={isDragging}
                                    style={{
                                      overflow: 'visible',
                                      margin: '0 auto',
                                      maxWidth: 'calc(100% - 10px)',
                                      position: 'relative',
                                      zIndex: 1,
                                    }}
                                  />
                                ))
                              )}
                            </div>

                            {/* Footer Section - Conditionally Rendered */}
                            {!completedSemesters[`year${yearNumber}`]?.S2 && (
                              <div
                                className={`flex-none flex ${
                                  hasCoursesInSemester(yearNumber, 'Spring') ? 'justify-center' : 'justify-end'
                                } mt-1 bg-white py-1.5 hidden group-hover:flex transition-opacity duration-300`}
                              >
                                {/* Add and Autofill Buttons */}
                                <div className="flex space-x-2">
                                  <button
                                    ref={(el) => buttonRefs.current.push(el)}
                                    className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center add-btn"
                                  >
                                    <Link
                                      to={{
                                        pathname: '/Search',
                                        search: `?yearNumber=${yearNumber}&semester=Spring`,
                                      }}
                                    >
                                      <FaPlus className="inline mb-0.5" /> Add
                                    </Link>
                                  </button>
                                  <div className="border-gray-300 border-l h-5 inline-block mt-[5px]"></div>
                                  <button
                                    ref={(el) => buttonRefs.current.push(el)}
                                    onClick={() => handleAutofillCourse(yearNumber, 'Spring')}
                                    className={`text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn ${
                                      !hasCoursesInSemester(yearNumber, 'Spring') ? 'mr-2' : ''
                                    }`}
                                  >
                                    <FaMagic className="inline mr-1 mb-0.5" /> Autofill
                                  </button>
                                </div>
                                {/* Clear Button (Only if Courses Exist) */}
                                {hasCoursesInSemester(yearNumber, 'Spring') && (
                                  <div className="flex space-x-2 ml-2">
                                    <div className="border-gray-300 border-l h-5 inline-block mt-[5px]"></div>
                                    <button
                                      ref={(el) => buttonRefs.current.push(el)}
                                      onClick={() => handleRemoveSemesterCourses(yearNumber, 'S2')}
                                      className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                                    >
                                      <FaTrash className="inline mr-0.5 mb-0.5 ml-0.5" /> Clear
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </DroppableSemester>
                      </div>
                    ) : null,
                  ];
                })}

              </div>

              {/* Add and Remove Year Buttons Aligned to the Right */}
              <div className="flex justify-end mt-4 pr-4">
                {/* **Single Remove Year Button** */}
                {numYears > 1 && shouldShowRemoveYearButton(numYears) && (
                  <button
                    onClick={() => handleRemoveYear(numYears)} // Remove the last year
                    className="text-red-700 bg-red-200 bg-opacity-60 hover:bg-red-300 focus:ring-4 focus:outline-none focus:ring-red-300 rounded-full w-10 h-10 flex items-center justify-center"
                  >
                    <FaMinus />
                  </button>
                )}
                {/* **Add Year Button** */}
                <button
                  onClick={handleAddYear}
                  className="text-blue-700 bg-blue-200 bg-opacity-60 hover:bg-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full w-10 h-10 flex items-center justify-center ml-4"
                >
                  <FaPlus />
                </button>
              </div>
            </div>
          )}
        </div>
        {showPdfModal && (
          <PDFPreview
            pdfBytes={pdfBytes}
            onClose={closePdfModal}
            firstName={currentUser?.firstName || 'Unknown'}
            lastName={currentUser?.lastName || 'Unknown'}
            numYears={currentUser?.schedules?.[activeTab]?.numYears || 4}
          />
        )}
      </div>
      {showDrawer && currentSemester.year && currentSemester.semester && (
        <MilestoneDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          milestones={finalizedMilestones.filter(milestone => 
            milestone.Milestone_Year === currentSemester.year &&
            milestone.Milestone_Sem === (currentSemester.semester === 'Fall' ? 'S1' : 'S2')
          )}
          handleRemoveMilestone={handleRemoveMilestone}
          handleToggleComplete={handleToggleComplete}
          handleAddMilestone={handleAddMilestone}
          showAllMilestones={false}
          setShowModal={setShowModal}
          currentSemester={currentSemester} // Pass currentSemester here
        />
      )}
      {showAllMilestonesDrawer && (
          <MilestoneDrawer 
            showDrawer={showAllMilestonesDrawer} 
            setShowDrawer={setShowAllMilestonesDrawer} 
            milestones={finalizedMilestones} 
            handleRemoveMilestone={handleRemoveMilestone} 
            handleToggleComplete={handleToggleComplete} 
            handleAddMilestone={() => setShowModal(true)}  // Ensure this function sets the show modal state to true
            showAllMilestones={true} 
            setShowModal={setShowModal}  // Pass the setShowModal function as a prop
          />
      )}
        <MilestoneModal
            showModal={showModal}
            setShowModal={setShowModal}
            handleSaveMilestone={handleSaveMilestone}
        />
        <EvaluationModal
          isOpen={isEvaluationModalOpen}
          onClose={handleCloseEvaluationModal}
          classData={selectedCourse}
        />
      {showAddScheduleModal && (
        <div id="authentication-modal" className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ease-in-out">
          <div className="relative p-4 w-full max-w-md max-h-2xl" ref={modalRef}>
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Add New Plan
                </h3>
                <button
                  type="button"
                  className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={handleCloseAddScheduleModal}
                  data-modal-hide="authentication-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <form className="space-y-4">
                  <div>
                    <label
                      htmlFor="schedule-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Plan Name
                    </label>
                    <input
                      type="text"
                      id="schedule-name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Enter schedule name"
                      value={newScheduleName}
                      onChange={(e) => setNewScheduleName(e.target.value)}
                      maxLength={65} // Limit to 50 characters
                      required
                    />
                  </div>
                  {error && (
                    <div className="flex justify-center mb-4">
                      <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                        {error}
                      </span>
                    </div>
                  )}
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      onClick={handleAddPlan}
                    >
                      Add New Plan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </DndProvider>
    
  );
};

export default SemesterCardTwo;