import React from 'react';

const ElectivesSection = ({ electives, setElectives }) => {
  // Fetch elective categories from local storage
  const electiveCategories = JSON.parse(localStorage.getItem('appDataCache')).electiveCategories || [];

  const handleRemoveElective = (index) => {
    const updatedElectives = electives.filter((_, idx) => idx !== index);
    setElectives(updatedElectives);
  };

  const handleAddElective = () => {
    setElectives([
      ...electives,
      {
        courseYear: '',
        CourseSem: '',
        category: '',  // Store selected elective category here
      },
    ]);
  };

  return (
    <div className="mb-4">
      {electives.length === 0 ? (
        <p className="text-gray-700">No electives currently included</p>
      ) : (
        <ul className="space-y-4">
          {electives.map((elective, index) => (
            <li key={index} className="p-4 bg-gray-50 rounded-lg shadow-md">
              <div className="flex justify-between items-center mb-2">
                <span className="text-gray-700 font-semibold">
                  {elective.category || 'No category selected'}
                </span>
                <button
                  type="button"
                  onClick={() => handleRemoveElective(index)}
                  className="bg-red-600 text-white px-3 py-1 rounded-lg hover:bg-red-700 transition-colors"
                >
                  Remove
                </button>
              </div>

              {/* Dropdown to select elective category */}
              <div className="mt-3">
                <label className="block text-sm font-medium text-gray-700">Elective Category</label>
                <select
                  value={elective.category}
                  onChange={(e) => {
                    const updatedElectives = [...electives];
                    updatedElectives[index].category = e.target.value;
                    setElectives(updatedElectives);
                  }}
                  className="block w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="" disabled>Select Category</option>
                  {electiveCategories.map((categoryObj, idx) => (
                    <option key={idx} value={categoryObj.Categories}>
                      {categoryObj.Categories}
                    </option>
                  ))}
                </select>
              </div>

              {/* Year and Semester Selection */}
              <div className="flex mt-3 space-x-4">
                <div className="w-1/2">
                  <label className="block text-sm font-medium text-gray-700">Course Year</label>
                  <select
                    value={elective.courseYear}
                    onChange={(e) => {
                      const updatedElectives = [...electives];
                      updatedElectives[index].courseYear = e.target.value;
                      setElectives(updatedElectives);
                    }}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="" disabled>Select Year</option>
                    {[1, 2, 3, 4].map((year) => (
                      <option key={year} value={year}>
                        Year {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/2">
                  <label className="block text-sm font-medium text-gray-700">Semester</label>
                  <select
                    value={elective.CourseSem}
                    onChange={(e) => {
                      const updatedElectives = [...electives];
                      updatedElectives[index].CourseSem = e.target.value;
                      setElectives(updatedElectives);
                    }}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="" disabled>Select Semester</option>
                    <option value="S1">Semester 1</option>
                    <option value="S2">Semester 2</option>
                  </select>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddElective}
        className="w-full mt-4 bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700 transition-colors"
      >
        Add Elective
      </button>
    </div>
  );
};

export default ElectivesSection;
