import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals
import { TextInput } from 'flowbite-react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import AdStudentProfile from './Advisor Review/AsStudentProfile'; // Import the component
import { useAuth } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { db } from '../../firebase';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore'; // Import Firestore functions


const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex justify-center items-center">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-white bg-opacity-30 backdrop-blur-sm"
        onClick={onClose}
      ></div>
      {/* Modal content */}
      <div
        className="relative p-4 w-full max-w-3xl max-h-[80vh] z-50 overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

const SearchComponent = ({ onSearch, onFilter, selectedStudents }) => {
  const { currentUser, isUserDataFetched, fetchUserData, fetchedCourseData, fetchedPrereqData, majorData, fetchedMajorRecs, fetchedMinors, fetchedMajors, pathwayGroupingsData } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [transformedMajorRecs, setTransformedMajorRecs] = useState([]);
  const [availableFilters, setAvailableFilters] = useState({ gradYear: [], program: [], advisors: [] });
  const [selectedFilters, setSelectedFilters] = useState({ gradYear: [], program: [], advisors: [] });
  const [isFilterBarVisible, setIsFilterBarVisible] = useState(false);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [isSemesterSelectionVisible, setIsSemesterSelectionVisible] = useState(false);
  const [completedSemesters, setCompletedSemesters] = useState([]);
  const [selectedMajor, setSelectedMajor] = useState(null);
  const [allCourses, setAllCourses] = useState(fetchedCourseData);
  const [numYears, setNumYears] = useState(4);
  const [majorCourses, setMajorCourses] = useState([]);
  const [majorElectives, setMajorElectives] = useState([]);
  const [isAutofilling, setIsAutofilling] = useState(false);
  const [majorRequirementCourses, setMajorRequirementCourses] = useState([]);



  const fetchFilters = useCallback(async () => {
    try {
      const response = await fetch('/filters.json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAvailableFilters(data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }, []);

  const generateInitialState = (numYears) => {
    const yearObject = {};

    for (let i = 1; i <= numYears; i++) {
      yearObject[`year${i}`] = {
        Fall: [],
        Spring: []
      };
    }

    return yearObject;
  };

  const calculateRecScore = require('../../CourseRecommendation');


  const fetchTransformedMajorRecs = () => {
    let transformedMajorRecs = {};

    for (let i = 0; i < fetchedMajorRecs.length; i++) {
      const curr = fetchedMajorRecs[i];
      const { Major, ...categories } = curr;
      transformedMajorRecs[Major] = categories;
    }

    setTransformedMajorRecs(transformedMajorRecs);
  };

  // useEffect(() => {
  //   if (fetchedMajorRecs.length > 0) {
  //     fetchTransformedMajorRecs();
  //   }
  // }, [fetchedMajorRecs]);

  const parsePrerequisites = (prerequisites) => {
    let arrayOfPrerequisites = prerequisites.split("&");
    arrayOfPrerequisites = arrayOfPrerequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfPrerequisites.map(element => element.split("||"));
  };

  const parseCorequisites = (corequisites) => {
    let arrayOfCorequisites = corequisites.split("&");
    arrayOfCorequisites = arrayOfCorequisites.map(element => element.replace(/\(|\)/g, ''));
    return arrayOfCorequisites.map(element => element.split("||"));
  };

  const checkPrerequisites = (course, yearKey, semesterKey, cumulativeIds) => {
    let isPrereqMet = true;
    let isCoreqMet = true;

    if (course.Prerequisites && course.Prerequisites[0] && course.Prerequisites[0][0]) {
      isPrereqMet = course.Prerequisites.every(prereqGroup =>
        prereqGroup.some(prereq => {
          if (yearKey === 'year1' && (semesterKey === 'S1' || semesterKey === 'Fall')) {
            return cumulativeIds['year0'].includes(prereq);
          } else if ((semesterKey === 'S1' || semesterKey === 'Fall')) {
            const lastYear = parseInt(yearKey.charAt(4)) - 1;
            return cumulativeIds[`year${lastYear}`]['S2'].includes(prereq);
          } else {
            return cumulativeIds[yearKey]['S1'].includes(prereq);
          }
        })
      );
    }

    if (course.Corequisites && course.Corequisites[0] && course.Corequisites[0][0]) {
      let semesterCode = semesterKey === "Fall" ? "S1" : "S2";
      isCoreqMet = course.Corequisites.some(coreqGroup => {
        return coreqGroup.some(coreq => {
          return cumulativeIds[yearKey][semesterCode].includes(coreq);
        });
      });
    }

    const isTotalMet = isPrereqMet && isCoreqMet;

    return isTotalMet;
  };

  const recheckPrerequisites = useCallback((updatedOrganizedCourses) => {
    let cumulativeIds = { year0: [] };
    let prevYearsCourses = [];

    for (let i = 1; i <= numYears; i++) {
      let year = `year${i}`;
      let yearCourses = {};
      let cumulativeCourses = prevYearsCourses;

      for (let j = 1; j <= 2; j++) {
        let semester = `S${j}`;
        let semesterCode = semester === "S1" ? "Fall" : "Spring";

        if (updatedOrganizedCourses && updatedOrganizedCourses[year] && updatedOrganizedCourses[year][semesterCode]) {
          cumulativeCourses = cumulativeCourses.concat(updatedOrganizedCourses[year][semesterCode].map(course => course.courseInfo_courseNumber));
        }
        cumulativeCourses = Array.from(new Set(cumulativeCourses));
        yearCourses[semester] = [...cumulativeCourses];
      }

      prevYearsCourses = prevYearsCourses.concat(cumulativeCourses);
      prevYearsCourses = Array.from(new Set(prevYearsCourses));
      cumulativeIds[year] = yearCourses;
    }

    const updateObject = { ...updatedOrganizedCourses };

    for (const yearKey in updateObject) {
      ['Fall', 'Spring'].forEach(semesterKey => {
        const semesterClassesObject = updateObject[yearKey][semesterKey] || [];

        updateObject[yearKey][semesterKey] = semesterClassesObject.map(course => {
          const courseInfo = fetchedPrereqData.find(prereq => prereq.Course === course.courseInfo_courseNumber);

          if (courseInfo) {
            course.Prerequisites = parsePrerequisites(courseInfo.Prerequisites);
            course.Corequisites = parseCorequisites(courseInfo.Corequisites);
          }

          course.isPrereqMet = checkPrerequisites(course, yearKey, semesterKey, cumulativeIds);

          return { ...course };
        });
      });
    }

    setFinalizedOrganizedCourses(updateObject);
  }, []);

  const fetchMajorRequirementCourses = async (major) => {
    console.log('Fetching major requirement courses for major:', major);
    try {
  
          // Objects to store data keyed by major name
          const majorRequirementsData = {};

          // Function to calculate max years for given courses
          const calculateMaxYears = (courses) =>
            Math.max(...courses.map((course) => course.courseYear));

          // Semester mapping
          const semesterMap = {
            S1: 'Fall',
            S2: 'Spring',
          };

          // Ensure that fetchedMajors[major] exists
          if (fetchedMajors[major]) {
            const majorCoursesFromBackend = Object.values(fetchedMajors[major]);

            const majorRequirementsMap = {};

            const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

            // Initialize the majorRequirementsMap structure
            for (let i = 1; i <= maxMajorYears; i++) {
              majorRequirementsMap[`year${i}`] = {
                Fall: [],
                Spring: [],
              };
            }

            // Populate the majorRequirementsMap with requirement courses
            majorCoursesFromBackend.forEach((course) => {
              if (
                course.courseType === 'Requirement' ||
                (course.courseType === 'Major Course' && course.Requirements !== '')
              ) {
                if (course.courseYear <= maxMajorYears) {
                  const semester = semesterMap[course.CourseSem] || course.CourseSem;
                  majorRequirementsMap[`year${course.courseYear}`][semester].push(course);
                }
              }
            });

            // Assign the requirements data to the major name
            majorRequirementsData[major] = majorRequirementsMap;

            // Set the state with the new structured data
            setMajorRequirementCourses(majorRequirementsData);
            console.log('Major Requirement Courses Data:', majorRequirementsData);
      } else {
        console.error('Current user or major is not defined.');
      }
    } catch (error) {
      console.error('Error fetching major requirement courses:', error);
    }
  };

    const fetchMajorElectives = async (major) => {
      console.log('Fetching major electives for major:', major);
      try {
            // Objects to store data keyed by major name
            const majorElectivesData = {};

            // Function to calculate max years for given courses
            const calculateMaxYears = (courses) =>
              Math.max(...courses.map((course) => course.courseYear));

            // Semester mapping
            const semesterMap = {
              S1: 'Fall',
              S2: 'Spring',
            };

            // Ensure that fetchedMajors[major] exists
            if (fetchedMajors[major]) {
              const majorCoursesFromBackend = Object.values(fetchedMajors[major]);

              const electiveCoursesMap = {};

              const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

              // Initialize the electiveCoursesMap structure
              for (let i = 1; i <= maxMajorYears; i++) {
                electiveCoursesMap[`year${i}`] = {
                  Fall: [],
                  Spring: [],
                };
              }

              // Populate the electiveCoursesMap with electives
              majorCoursesFromBackend.forEach((course) => {
                if (course.courseType === 'Elective') {
                  if (course.courseYear <= maxMajorYears) {
                    const semester = semesterMap[course.CourseSem] || course.CourseSem;
                    electiveCoursesMap[`year${course.courseYear}`][semester].push(course);
                  }
                }
              });

              // Assign the electives data to the major name
              majorElectivesData[major] = electiveCoursesMap;

              // Set the state with the new structured data
              setMajorElectives(majorElectivesData);
              console.log('Major Electives Data:', majorElectivesData);
        } else {
          console.error('Current user or major is not defined.');
        }
      } catch (error) {
        console.error('Error fetching major electives:', error);
      }
    };

    const fetchMajorCoursesData = (userData) => {
      const surveyAnswers = userData.surveyAnswers;
      console.log('Survey answers: ', surveyAnswers, userData);
      // Assuming surveyAnswers and other dependencies are available in scope
      const majorNames = surveyAnswers['Majors'];
    
      const majorCoursesData = {};
      const semesterMap = {
        'S1': 'Fall',
        'S2': 'Spring',
      };
    
      const calculateMaxYears = (courses) =>
        Math.max(...courses.map((course) => course.courseYear));
    
      majorNames.forEach((majorName) => {
        const majorCoursesFromBackend = Object.values(fetchedMajors[majorName]);
    
        const majorCoursesMap = new Map();
        const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);
    
        for (let i = 1; i <= maxMajorYears; i++) {
          majorCoursesMap.set(`year${i}`, {
            Fall: [],
            Spring: [],
          });
        }
    
        majorCoursesFromBackend.forEach((course) => {
          if (course.courseType === 'Major Course' && course.Requirements === '') {
            majorCoursesMap.set(course.courseInfo_courseNumber, {
              courseYear: course.courseYear,
              courseSemester: course.CourseSem,
            });
          }
        });
        fetchTransformedMajorRecs();
    
        const allCoursesWithRecScore = allCourses.map((course) => {
          const recScore = calculateRecScore(
            transformedMajorRecs[course.Major],
            userData.recommendationRatings,
            course,
            userData.surveyAnswers
          );
          const majorCourseNumbers = majorCoursesFromBackend.map(
            (majorCourse) => majorCourse.courseInfo_courseNumber
          );
          const courseType = majorCourseNumbers.includes(
            course.courseInfo_courseNumber
          )
            ? 'Major Course'
            : 'Elective';
    
          return {
            ...course,
            recScore,
            courseType,
          };
        });
    
        const majorCoursesInfo = allCoursesWithRecScore
          .filter((course) =>
            majorCoursesMap.has(course.courseInfo_courseNumber)
          )
          .map((course) => {
            const { courseYear, courseSemester } = majorCoursesMap.get(
              course.courseInfo_courseNumber
            );
            return {
              ...course,
              courseYear,
              CourseSem: courseSemester,
            };
          });
    
        majorCoursesData[majorName] = majorCoursesInfo;
      });
    
      setMajorCourses(majorCoursesData);
    };

  const [finalizedOrganizedCourses, setFinalizedOrganizedCourses] = useState(generateInitialState(numYears));

  const organizeMajorCoursesByYearAndSemester = useCallback((majorCourses) => {
    const coursesByYearAndSemester = {};
    majorCourses.forEach((course) => {
      const year = course.courseYear || 'Uncategorized';
      const semester = course.CourseSem || 'Uncategorized';
      if (!coursesByYearAndSemester[year]) {
        coursesByYearAndSemester[year] = { Fall: [], Spring: [] };
      }
      const semesterName = semester === 'S1' ? 'Fall' : (semester === 'S2' ? 'Spring' : 'Uncategorized');
      if (semesterName !== 'Uncategorized') {
        coursesByYearAndSemester[year][semesterName].push(course);
      }
    });
    return coursesByYearAndSemester;
  }, []);

  const handleAutofillCourse = 
    async (yearNumber, semester, major, user) => {
      const currentUser = user;
      console.log('Current user:', currentUser);
      const newScheduleName = major + " Plan";
      
      const loadingToastId = "autofill-course-toast";
      
      fetchMajorElectives(major);
      fetchMajorRequirementCourses(major);
      if (!toast.isActive(loadingToastId)) {
        toast.loading("Autofilling courses", {
          toastId: loadingToastId,
          autoClose: false,
          className: "custom-toast custom-toast-loading",
          position: "top-center",
          style: {
            width: "12rem",
          },
        });
      }
  
      try {
        if (currentUser) {
          const userRef = db.collection("users").doc(currentUser);
          const userDoc = await userRef.get();
          console.log('User ref test: ', userRef);
          await db.runTransaction(async (transaction) => {
            const userData = userDoc.data();
            console.log('User Data: ', userData);
            fetchMajorCoursesData(userData);
            // if (!userData.exists) {
            //   throw new Error("User data not found");
            // }
            let semesterCode = semester === "Fall" ? "S1" : "S2";
            const previousFinalizedSchedule =
              userData.schedules[newScheduleName] || {};
              console.log('Prev final schedule: ', previousFinalizedSchedule);
  
            const updateObj = previousFinalizedSchedule
              ? { ...previousFinalizedSchedule }
              : {};
  
            if (!updateObj[`year${yearNumber}`]) {
              updateObj[`year${yearNumber}`] = {};
            }
            if (!updateObj[`year${yearNumber}`][semesterCode]) {
              updateObj[`year${yearNumber}`][semesterCode] = { courses: [] };
            }
  
            const existingFinalizedSchedule =
              updateObj[`year${yearNumber}`][semesterCode].courses;
  
            const allFinalizedCourses = new Set();
            for (const year in finalizedOrganizedCourses) {
              for (const sem in finalizedOrganizedCourses[year]) {
                finalizedOrganizedCourses[year][sem].forEach((course) => {
                  allFinalizedCourses.add(course.courseInfo_courseNumber);
                });
              }
            }
            console.log('All fin courses: ', allFinalizedCourses);
  
            let allClassNumbers = [];
  
            for (const year in updateObj) {
              for (const semester in updateObj[year]) {
                allClassNumbers = allClassNumbers.concat(
                  updateObj[year][semester].courses
                );
              }
            }
  
            const allAutofillableCourses = allCourses.filter(
              (course) =>
                course.courseYear <= 4 &&
                course.Offering.includes(semester) &&
                !allFinalizedCourses.has(course.courseInfo_courseNumber) &&
                course.isPrereqMet
            );
  
            // Major Courses
            const combinedSemesterMajorCourses = [];
            console.log('Major courses: ', majorCourses);
  
            Object.entries(majorCourses).forEach(([majorName, courseInfo]) => {
              const organizedMajorCourses =
                organizeMajorCoursesByYearAndSemester(courseInfo);
              if (
                organizedMajorCourses &&
                organizedMajorCourses[yearNumber]
              ) {
                combinedSemesterMajorCourses.push(
                  ...(organizedMajorCourses[yearNumber][semester] || [])
                );
              }
            });
  
            let semesterMajorCourses =
              combinedSemesterMajorCourses.length > 0
                ? combinedSemesterMajorCourses
                : [];
  
            const semesterMajorCoursesWithAutofill =
              semesterMajorCourses.map((course) => ({
                ...course,
                autofillType: "Major Course",
              }));
            // Major Electives
            let combinedElectiveCourses = [];
  
            Object.entries(majorElectives).forEach(
              ([majorName, electivesByMajor]) => {
                if (
                  electivesByMajor &&
                  electivesByMajor[`year${yearNumber}`]
                ) {
                  combinedElectiveCourses.push(
                    ...electivesByMajor[`year${yearNumber}`][semester]
                  );
                }
              }
            );
  
            const electiveNameStrings = combinedElectiveCourses.map(
              (course) => course.Possibilities
            );
  
            const semesterElectiveRequirements = electiveNameStrings.reduce(
              (acc, name) => {
                if (!acc[name]) {
                  const course = combinedElectiveCourses.find(
                    (course) => course.Possibilities === name
                  );
                  const electiveFullName = course ? course.Requirements : "";
                  acc[name] = {
                    quantity: 0,
                    electiveFullName: electiveFullName,
                  };
                }
                acc[name].quantity += 1;
  
                return acc;
              },
              {}
            );
  
            let autofillElectiveCourses = [];
  
            Object.entries(semesterElectiveRequirements).forEach(
              ([requirementName, { quantity, electiveFullName }]) => {
                const existingCourses = existingFinalizedSchedule
                  .map((courseNumber) =>
                    allCourses.find(
                      (course) =>
                        course.courseInfo_courseNumber === courseNumber
                    )
                  )
                  .filter(
                    (course) =>
                      course &&
                      course.Elective_fulfillment.includes(requirementName)
                  );
  
                const remainingQuantity =
                  quantity - existingCourses.length;
  
                if (remainingQuantity > 0) {
                  const filteredCourses = allAutofillableCourses.filter(
                    (course) =>
                      course.Elective_fulfillment.includes(requirementName) &&
                      !allClassNumbers.includes(
                        course.courseInfo_courseNumber
                      )
                  );
  
                  const sortedCourses = filteredCourses.sort(
                    (a, b) => b.recScore - a.recScore
                  );
  
                  const topCourses = sortedCourses
                    .slice(0, remainingQuantity)
                    .map((course) => ({
                      ...course,
                      electiveRequirement: requirementName,
                      electiveFullName,
                    }));
  
                  autofillElectiveCourses.push(...topCourses);
                }
              }
            );
  
            const autofillElectiveCoursesWithAutofill =
              autofillElectiveCourses.map((course) => ({
                ...course,
                autofillType: "Elective",
              }));
  
            // Major Requirements
            let combinedRequirementCourses = [];
  
            Object.entries(majorRequirementCourses).forEach(
              ([majorName, requirementsByMajor]) => {
                if (
                  requirementsByMajor &&
                  requirementsByMajor[`year${yearNumber}`]
                ) {
                  combinedRequirementCourses.push(
                    ...requirementsByMajor[`year${yearNumber}`][semester]
                  );
                }
              }
            );
  
            const requirementNameStrings = combinedRequirementCourses.map(
              (course) => course.Requirements
            );
  
            const semesterRequirementDetails = requirementNameStrings.reduce(
              (acc, name) => {
                if (!acc[name]) {
                  const course = combinedRequirementCourses.find(
                    (course) => course.Requirements === name
                  );
                  const possibleCourses = course
                    ? course.Possibilities.split(",").map((course) =>
                        course.trim()
                      )
                    : [];
                  acc[name] = {
                    quantity: 0,
                    possibleCourses: possibleCourses,
                  };
                }
                acc[name].quantity += 1;
  
                return acc;
              },
              {}
            );
  
            let autofillRequirementCourses = [];
  
            Object.entries(semesterRequirementDetails).forEach(
              ([requirementName, { quantity, possibleCourses }]) => {
                const commonElementsCount = possibleCourses.filter(
                  (course) =>
                    existingFinalizedSchedule.includes(course)
                ).length;
  
                const remainingQuantity = quantity - commonElementsCount;
  
                if (remainingQuantity > 0) {
                  const filteredCourses = possibleCourses
                    .map((courseNumber) =>
                      allAutofillableCourses.find(
                        (course) =>
                          course.courseInfo_courseNumber === courseNumber &&
                          !allClassNumbers.includes(courseNumber)
                      )
                    )
                    .filter((course) => course !== undefined);
  
                  const sortedCourses = filteredCourses.sort(
                    (a, b) => b.recScore - a.recScore
                  );
  
                  const topCourses = sortedCourses
                    .slice(0, remainingQuantity)
                    .map((course) => ({
                      ...course,
                      requirementName: requirementName,
                    }));
  
                  autofillRequirementCourses.push(...topCourses);
                }
              }
            );
  
            const autofillRequirementCoursesWithAutofill =
              autofillRequirementCourses.map((course) => ({
                ...course,
                autofillType: "Requirement",
              }));
  
            // Combine all autofill courses, excluding minor courses
            const semesterAutofillCourses = [
              ...semesterMajorCoursesWithAutofill,
              ...autofillElectiveCoursesWithAutofill,
              ...autofillRequirementCoursesWithAutofill,
              // Minor courses are excluded
            ];
  
            const uniqueAutofillCourses = semesterAutofillCourses.reduce(
              (acc, current) => {
                const x = acc.find(
                  (item) =>
                    item.courseInfo_courseNumber ===
                    current.courseInfo_courseNumber
                );
                if (!x) {
                  acc.push(current);
                }
                return acc.filter(
                  (course) =>
                    !allClassNumbers.includes(
                      course.courseInfo_courseNumber
                    )
                );
              },
              []
            );
  
            const semesterAutofillCoursesWithStatus =
              uniqueAutofillCourses.map((course) => ({
                ...course,
                isAutofill: true,
              }));
  
            const semesterAutofillCourseIds =
              semesterAutofillCoursesWithStatus.map(
                (course) => course.courseInfo_courseNumber
              );
            const updatedFinalizedSchedule = [
              ...new Set([
                ...existingFinalizedSchedule,
                ...semesterAutofillCourseIds,
              ]),
            ];
            updateObj[`year${yearNumber}`][semesterCode].courses =
              updatedFinalizedSchedule;
  
            transaction.update(userRef, {
              [`schedules.${newScheduleName}.year${yearNumber}.${semesterCode}.courses`]:
                updatedFinalizedSchedule,
            });
  
            setFinalizedOrganizedCourses((prev) => {
              const newCourses = { ...prev };
  
              const existingCourseNumbers =
                newCourses?.[`year${yearNumber}`]?.[
                  semester
                ]?.map((course) => course?.courseInfo_courseNumber) ?? [];
  
              const filteredCourses =
                semesterAutofillCoursesWithStatus.filter(
                  (course) =>
                    !existingCourseNumbers.includes(
                      course.courseInfo_courseNumber
                    )
                );
  
              // Ensure newCourses[`year${yearNumber}`] is defined
              newCourses[`year${yearNumber}`] =
                newCourses[`year${yearNumber}`] ?? {};
  
              // Ensure newCourses[`year${yearNumber}`][semester] is defined
              newCourses[`year${yearNumber}`][semester] =
                newCourses[`year${yearNumber}`][semester] ?? [];
  
              // Now safely update newCourses[`year${yearNumber}`][semester]
              newCourses[`year${yearNumber}`][semester] = [
                ...new Set([
                  ...newCourses[`year${yearNumber}`][semester],
                  ...filteredCourses,
                ]),
              ];
  
              recheckPrerequisites(newCourses);
  
              return newCourses;
            });
  
            console.log("Courses Autofilled Successfully.");
            // Update the toast to success status
            setTimeout(() => {
              toast.update(loadingToastId, {
                render: "Applied Template",
                type: "success",
                autoClose: 1000,
                isLoading: false,
                hideProgressBar: true,
                style: {
                  width: "12rem",
                },
              });
            }, 500); // Delay the transition by 500ms to keep the loading state visible
          });
        } else {
          console.error(
            "Error: currentUser or currentUser.uid is not defined"
          );
        }
      } catch (error) {
        // Show an error toast if the operation fails
        setTimeout(() => {
          toast.update(loadingToastId, {
            render: "Failed apply template.",
            type: "error",
            autoClose: 3000,
            isLoading: false,
            style: {
              width: "20rem",
            },
          });
        }, 500);
        console.error("Error autofilling courses:", error);
      }
    };
  

  const handleAutofillVariableCourses = 
    async (yearNum, major, users) => {
      setIsAutofilling(true);
      try {
        const years = Array.from({ length: yearNum }, (_, index) => index + 1);
        const semesters = ["Fall", "Spring"];
  
        for (const userId of users) {
          for (const yearNumber of years) {
            for (const semester of semesters) {
              let semesterCode = semester === "Fall" ? "S1" : "S2";
              const isCompleted =
                completedSemesters &&
                completedSemesters[`year${yearNumber}`] &&
                completedSemesters[`year${yearNumber}`][semesterCode] &&
                completedSemesters[`year${yearNumber}`][semesterCode] === true;
              if (!isCompleted) {
                await handleAutofillCourse(yearNumber, semester, major, userId);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error autofilling all courses:", error);
      } finally {
        setIsAutofilling(false);
      }
    }
  
  
  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    if (searchTerm !== '') {
      onSearch(searchTerm);
    }
  }, [searchTerm, onSearch]);

  useEffect(() => {
    onFilter(selectedFilters);
  }, [selectedFilters, onFilter]);

  const toggleFilterBarVisibility = () => {
    setIsFilterBarVisible(!isFilterBarVisible);
  };

  const toggleTemplateModalVisibility = () => {
    setIsTemplateModalVisible(!isTemplateModalVisible);
  };

  const handleMajorSelection = (major) => {
    setSelectedMajor(major);
    setIsSemesterSelectionVisible(true);
  };

  const renderModalContent = () => {
    const nMap = {1:"One", 2:"Two", 3:"Three", 4:"Four"};
    if (!isSemesterSelectionVisible) {
      return (
        <div className="grid grid-cols-1 gap-4">
          {Object.keys(fetchedMajors || {}).map((major) => (
            <div
              key={major}
              className="p-4 border rounded text-center cursor-pointer hover:bg-gray-100"
              onClick={() => handleMajorSelection(major)}
            >
              {major}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-2 gap-4">
          {[...Array(4)].map((_, i) => (
            <div
              key={i}
              className="p-4 border hover:bg-gray-100 rounded text-center cursor-pointer"
              onClick={() => {
                handleAutofillVariableCourses(i + 1, selectedMajor, selectedStudents);
                setIsTemplateModalVisible(false);
                setIsSemesterSelectionVisible(false);
                setSelectedMajor(null);
              }}
            >
              {nMap[i + 1]} Year 
            </div>
          ))}
        </div>
      );
    }
  };
  
  

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsTemplateModalVisible(false);
      }
    };
    if (isTemplateModalVisible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTemplateModalVisible]);

  return (
    <>
      <div className="bg-white dark:bg-gray-800 p-4 border-b border-gray-300 shadow-sm sticky top-0 z-40">
        <div className="relative w-full mb-4 flex items-center space-x-4">
          <TextInput
            id="search-student"
            type="text"
            placeholder="Search for students"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 w-full"
          />
          <button
            onClick={toggleTemplateModalVisibility}
            className="border-gray-300 border-1 text-black px-4 py-2 rounded flex items-center space-x-2 hover:bg-gray-100 focus:outline-none"
          >
            <FaPlus className="text-black text-sm" />
            <span className ="text-sm">Templates</span>
          </button>
          <button
            onClick={toggleFilterBarVisibility}
            className="text-black rounded flex items-center space-x-2 hover:bg-gray-100 focus:outline-none"
          >
            <FaFilter className="text-lg" />
          </button>

          {/* Updated Add Template Button */}
          
        </div>
      </div>

      {/* Modal */}
      <Modal isVisible={isTemplateModalVisible} onClose={() => setIsTemplateModalVisible(false)}>
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 ">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg text-gray-900 dark:text-white">
              {isSemesterSelectionVisible ? `Select a plan for ${selectedMajor.toLowerCase()}` : 'Select a Major'}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => setIsTemplateModalVisible(false)}
            >
              {/* Close button SVG */}
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-4 md:p-5">
            {renderModalContent()}
          </div>
          {/* Modal footer */}
          <div className="flex justify-end p-4 md:p-5 space-x-2">
            <button
              onClick={() => {
                setIsTemplateModalVisible(false);
                setIsSemesterSelectionVisible(false);
                setSelectedMajor(null);
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {isSemesterSelectionVisible ? 'Close' : 'Cancel'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};


const ResultsComponent = ({ students }) => {
  const [studentForProfile, setStudentForProfile] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]); // Using array
  const sidebarRef = useRef();
  const toggleButtonRef = useRef();
  const { currentUser } = useAuth(); // Access the current user

  useEffect(() => {
    // Fetch the selectedStudents array from Firestore when the component mounts
    const fetchSelectedStudents = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setSelectedStudents(data.selectedStudents || []);
        }
      }
    };
    fetchSelectedStudents();
  }, [currentUser]);

  const handleStudentClick = (studentId) => {
    const student = students.find((s) => s.id === studentId);
    setStudentForProfile(student);
    setIsSidebarVisible(false);
  };

  const handleCheckboxChange = async (studentId, isChecked) => {
    try {
      // Reference to the current user's document in Firestore
      const userRef = doc(db, 'users', currentUser.uid);

      if (isChecked) {
        // Add the student ID to the selectedStudents array
        await updateDoc(userRef, {
          selectedStudents: arrayUnion(studentId),
        });

        // Update local state
        setSelectedStudents((prevSelectedStudents) => {
          return [...prevSelectedStudents, studentId];
        });
      } else {
        // Remove the student ID from the selectedStudents array
        await updateDoc(userRef, {
          selectedStudents: arrayRemove(studentId),
        });

        // Update local state
        setSelectedStudents((prevSelectedStudents) => {
          return prevSelectedStudents.filter((id) => id !== studentId);
        });
      }
      console.log('Selected students: ', selectedStudents);
    } catch (error) {
      console.error('Error updating selectedStudents array:', error);
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar Toggle Button */}
      <button
        ref={toggleButtonRef}
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        className="fixed top-1/2 right-0 bg-edvise text-white p-2 rounded-l z-40"
      >
        {isSidebarVisible ? '→' : '←'}
      </button>

      {/* Main Content Area */}
      <div className={`flex-1 transition-all duration-300 overflow-auto`}>
        {studentForProfile ? (
          <div className="w-full h-full">
            <AdStudentProfile student={studentForProfile} />
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500 text-xl">Select a profile to view</p>
          </div>
        )}
      </div>

      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 right-0 h-full bg-white dark:bg-gray-800 shadow-md transition-transform transform ${
          isSidebarVisible ? 'translate-x-0' : 'translate-x-full'
        } w-full md:w-1/2 z-30 overflow-y-auto`}
      >
        <SearchComponent
          onSearch={(term) => console.log(`Searching for: ${term}`)}
          onFilter={(filters) => console.log('Applying filters:', filters)}
          selectedStudents={selectedStudents}
        />

        {/* Students List */}
        <div className="mt-4 p-4">
          <ul>
            {students.map((student) => (
              <li
                key={student.id}
                className="border p-2 rounded-lg mb-2 bg-white dark:bg-gray-700"
                style={{ padding: '8px', marginBottom: '8px' }}
              >
                {/* Student Card Content */}
                <div className="flex items-center">
                  {/* Checkbox */}
                  <input
                    type="checkbox"
                    checked={selectedStudents.includes(student.id)}
                    onChange={(e) => handleCheckboxChange(student.id, e.target.checked)}
                    className="mr-2 rounded-sm"
                  />
                  {/* Clickable area for student details */}
                  <div
                    className="flex items-center flex-1 cursor-pointer"
                    onClick={() => handleStudentClick(student.id)}
                  >
                    <div className="flex-shrink-0 mr-2">
                      <img
                        src="https://via.placeholder.com/40"
                        alt="Profile"
                        className="w-8 h-8 rounded-full"
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="text-base m-0">{student.name}</h4>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResultsComponent;
