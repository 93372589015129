// AssignUsersModal.js
import React, { useState, useEffect } from 'react';
import { Modal } from 'flowbite-react';
import { db } from '../../firebase'; // Use your Firebase configuration
import firebase from 'firebase/compat/app'; // Import firebase for Firestore FieldValue

const AssignUsersModal = ({ show, onClose, groupId }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch all relevant users (exclude students) from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await db.collection('users').where('role', 'in', ['advisor', 'admin', 'super_admin']).get();
        const usersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      } catch (err) {
        setError('Error fetching users');
        console.error('Error fetching users:', err);
      }
    };

    fetchUsers();
  }, []);

  // Handle user selection using native select
  const handleUserSelection = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedUsers(selectedOptions);
  };

  // Handle assigning users to the group
  const handleAssignUsers = async () => {
    setLoading(true);
    try {
      const batch = db.batch();
      const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

      // Update each selected user's document to add this group
      selectedUsers.forEach((userId) => {
        const userRef = db.collection('users').doc(userId);
        batch.update(userRef, { groups: arrayUnion(groupId) });
      });

      // Update the group's document to include the selected users
      const groupRef = db.collection('groups').doc(groupId);
      batch.update(groupRef, { members: arrayUnion(...selectedUsers) });

      // Commit the batch
      await batch.commit();

      // Close modal and reset state
      setSelectedUsers([]);
      onClose();
    } catch (err) {
      setError('Error assigning users to group');
      console.error('Error assigning users:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>Assign Users to Group</Modal.Header>
      <Modal.Body>
        {error && <p className="error-message text-red-500">{error}</p>}
        <div>
          <label htmlFor="userSelect" className="block mb-2">Select Users:</label>
          <select
            id="userSelect"
            multiple
            className="w-full p-2 border border-gray-300 rounded"
            value={selectedUsers}
            onChange={handleUserSelection}
          >
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.firstName} {user.lastName} ({user.role})
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={handleAssignUsers}
          disabled={loading}
          className={`text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? 'Assigning...' : 'Assign Users'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignUsersModal;
