import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CollapsibleClassSearchEditPage from './CollapsibleClassSearchEditPage';

const CourseSearch = ({ loading, filteredData, handleAddClass, onLoadMore }) => {
  const navigate = useNavigate();
  const scrollableDivRef = useRef(null);

  const handleAddCourse = () => {
    navigate('/add-course'); // Ensure this route is correctly set up
  };

  const handleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      const { scrollTop, scrollHeight, clientHeight } = div;
      // Check if the user has scrolled within 100px of the bottom
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        onLoadMore();
      }
    }
  };

  useEffect(() => {
    const div = scrollableDivRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
      return () => {
        div.removeEventListener('scroll', handleScroll);
      };
    }
  }, [onLoadMore]);

  return (
    <div className="p-4 bg-gray-100 rounded-lg w-full min-h-[600px]">
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-lg font-bold">Courses</h5>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded"
          onClick={handleAddCourse}
        >
          Add Course
        </button>
      </div>
      <div
        className="h-[550px] overflow-y-auto rounded"
        ref={scrollableDivRef}
      >
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
            >
              {/* SVG Path */}
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          filteredData.length > 0 ? (
            filteredData.map((classData, index) => (
              <CollapsibleClassSearchEditPage
                key={index}
                classData={classData}
                onAddClass={handleAddClass}
                isRec={false}
              />
            ))
          ) : (
            <p className="text-center text-gray-500">No courses found.</p>
          )
        )}
      </div>
    </div>
  );
};

export default CourseSearch;
